.reporting_see_old_version{
    color: #8A8EA5;
    font-size: 14px;
    font-weight: 500;
}
.reporting_see_old_version .fas{
    margin-right: 4px;
}

/* Header */
.reporting_header {
    font-size: 14px;
}

.reporting_header_title {
    font-size: 28px;
    font-weight: 500;
    position: relative;
}

.reporting_header_title .status_bullet {
    position: absolute;
    top: 50%;
    left: -16px;
    margin-top: -4px;
}

.reporting_header_date {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-1);
    margin-top: 2px;
}
.reporting_header_id {
    font-size: 12px;
    color: var(--grey-3);
    margin-top: 5px;
}
.reporting_header_id_link {
    
}

.reporting_header_date .fas {
    color: var(--grey-4);
    font-size: 10px;
    margin: 0 2px;
    vertical-align: 1px;
}

.reporting_header_desc {
    font-size: 13px;
    color: var(--grey-1);
    margin-top: 6px;
    max-width: 600px;
}

.reporting_header .urldebug {
    margin: 6px 0 0;
}

/* Tabs */
.reporting_tabs{
    margin-top: 24px;
}

/* Filters */
.reporting_filters {
    margin-top: 24px;
}

/* Body */
.reporting_body {
    margin-top: 20px;
    width: 100%;
}

.reporting_part {
    margin: 30px 0;
}

.reporting_part_title, .reporting_chart .highcharts-title {
    font-size: 20px;
    font-weight: 500;
    font-family: "Roboto", Arial, sans-serif;
}
.reporting_part_filters {
    margin-top: 10px;
}
.reporting_part_body {
    margin-top: 12px;
}

.reporting_link {
    font-weight: 500;
    color: #006fff;
    display: inline-block;
    padding: 2px;
}

/* Chart */

.reporting_chart {
    position: relative;
    margin-left: -10px;
    min-height: 350px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.reporting_indicators {
    text-align: center;
    margin-bottom: 16px;
    position: relative;
}

.reporting_indicators_label {
    font-size: 14px;
    margin-right: 8px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--grey-1);
}

.reporting_chart_info {
    position: relative;
    margin-bottom: 16px;
    background-color: #f6f7f8;
    padding: 10px 16px;
    border-radius: 6px;
    display: flex;
    align-items: baseline;
    color: #757b96;
}

.reporting_chart_info_content {
    flex: 1 1 auto;
}

.reporting_chart_info_label {
    font-weight: 600;
    flex: 0 0 auto;
    margin-right: 10px;
}

.reporting_chart_info p {
    margin: 4px 0;
}

/* Results */

.reporting_results {}

.btn_select_variation {
    color: #006fff;
    border-color: #006fff;
    background: none;
    line-height: 20px;
    min-height: 20px;
    padding: 8px 10px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    border: solid 1px #006fff;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
}

.btn_select_variation option {
    color: #6e7272;
}

.btn_select_variation:hover {
    background-color: #006fff11;
    outline: none;
}

.btn_select_variation:active {
    background-color: #006fff22;
    outline: none;
}

/* Status bullet */

.status_bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
}

.status_bullet.status-ongoing {
    background: #7DC974;
}

.status_bullet.status-stopped {
    background: #EA6C6C;
}

.status_bullet.status-planned {
    background: #FCCB27;
}

.status_bullet.status-past {
    background: #A6A6A8;
}

.status_bullet.status-testing {
    background: #6599EB;
}

/* Charts */
.reporting_chart .highcharts-axis-title {
    font-weight: 400;
}
.reporting_chart .highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0.4px;
    fill: rgb(61, 61, 61);
    stroke: rgb(61, 61, 61);
}

/* Grid */
.table_grid_reporting .table_head_row .table_col{
    font-size: 13px;
    line-height: 1.2;
    text-transform: none;
}
.table_grid_reporting .table_body_row{
    font-size: 14px;
}
.table_grid_reporting .table_body_row_span{
    background-color: var(--main-bg-color);
    border-top: solid 2px var(--main-border-color);
    border-bottom: solid 2px var(--main-border-color);
}

