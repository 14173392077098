.ReactTable .rt-thead .rt-th.-sort-asc{
    -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc{
    -webkit-box-shadow: none;
	-moz-box-shadow: none;
    box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc-new{
    -webkit-box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.6);
	-moz-box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.6);
	box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.6);
}

.ReactTable .rt-thead .rt-th.-sort-desc-new{
    -webkit-box-shadow: inset 0 3px 0 0 rgba(0,0,0,0.6);
	-moz-box-shadow: inset 0 3px 0 0 rgba(0,0,0,0.6);
	box-shadow: inset 0 3px 0 0 rgba(0,0,0,0.6);
}
