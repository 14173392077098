.msg {
    text-align: center;
    padding: 20px;
    font-weight: 700;
    margin: 10px 0;
    font-size: 16px;
    border-radius: 5px;
    color: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.msg i {
    font-size: 25px;
    margin-right: 20px;
}
.msg-success {
    background: #5cb85c;
}
.msg-error {
    background: #d76565;
}