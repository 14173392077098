/* Header */
.campaign_list_filter{
    margin-bottom: 10px;
    float: right;
    margin-left: 20px;
    min-height: 38px;
    padding: 2px 0;
}
.campaign_list_search{
    margin-bottom: 10px;
    float: left;
    margin-right: 20px;
}
.campaign_list_labels{
    margin-bottom: 10px;
    float: left;
}
.campaign_list_labels .campaign_label_list{
    margin-top: -4px !important;
}
.campaign_list_labels.m .campaign_label_list{
    margin-top: -6px !important;
}

/* Campaign table */
.table_grid_campaign{
    margin-top: 8px;
}
.table_grid_campaign .table_row{
    grid-template-columns: 2.5fr 1fr .6fr 1.1fr .9fr .5fr;
    font-size: 14px;
}
.table_grid_campaign .table_body_row{
    align-items: baseline;
}
.table_grid_campaign .table_body_row .table_col_name{
    align-self: flex-start;
}

/* Date */
.table_grid_campaign .campaign_name{
    font-size: 15px;
    font-weight: 500;
}
.table_grid_campaign .campaign_name a:hover{
    color: var(--turquoise);
}
.campaign_date{
    font-size: 14px;
    font-weight: 500;
    margin-top: 3px;
    color: var(--grey-1);
}
.campaign_date .fa-arrow-right{
    color: var(--grey-1);
    font-size: 10px;
    margin: 0 2px;
    vertical-align: 1px;
}

/* Description */
.table_grid_campaign .campaign_description{
    max-width: 450px;
    font-size: 13px;
    margin-top: 2px;
    color: var(--grey-2);
}

/* Actions */
.table_grid_campaign .campaign_float_actions{
    position: absolute;
    right: 0;
    top: -6px;
    visibility: hidden;
    opacity: 0;
    transition: all 200ms;
    white-space: nowrap;
    background: #fff;
}
.table_grid_campaign .table_row:hover .campaign_float_actions,
.table_grid_campaign .campaign_float_actions_visible .campaign_float_actions{
    visibility: visible;
    opacity: 1;
    z-index: 1;
}
.table_grid_campaign .table_row:hover .campaign_float_actions{
    background: #F7F8FA;
}
.table_grid_campaign .table_row:hover .campaign_float_actions{
    background: #F7F8FA;
}

.switch:hover{
    color: #006fff;
    cursor: pointer;
}
