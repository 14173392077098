.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.header h4{}
.tableContainerHeader {
    display: flex;
    align-items: flex-end;
}

.tableContainerHeader .actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}

a.export_btn{
    background: var(--turquoise);
    color: #FFF;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 10px;
}
a.export_btn:hover{
    background: var(--turquoise-dark);
}

.tableContainerHeader :global(.btt) {
    margin-left: 10px;
}

.tableContainer {
    margin: 0px 0;
}

.tableContainer :global(.ReactTable) {
    border: 0;
}

.tableContainer :global(.rt-table) {
    border: 1px solid rgba(0,0,0,0.1);
    overflow: hidden;
}

.tableContainer :global(.rt-th) {
    font-weight: bold;
}

/* Hide last header cell */
.tableContainer :global(.rt-th:last-child) {
    padding: 0;
}

.tableContainer :global(.pagination-bottom) {
    display: inline-flex;
    align-self: center;
    margin: 20px 0 0;
}

.tableContainer :global(.-pagination) {
    box-shadow: none;
    border: 0;
}

.tableContainer :global(.-pagination) :global(.-previous) :global(.-btn),
.tableContainer :global(.-pagination) :global(.-next) :global(.-btn) {
    overflow: hidden;
    height: 50px;
    width: 50px;
    color: transparent;
}

.tableContainer :global(.-pagination) :global(.-previous) :global(.-btn:hover),
.tableContainer :global(.-pagination) :global(.-next) :global(.-btn:hover) {
    color: transparent;
}

.tableContainer :global(.-pagination) :global(.-previous) :global(.-btn:not([disabled]):hover:before),
.tableContainer :global(.-pagination) :global(.-next) :global(.-btn:not([disabled]):hover:before) {
    color: #FFF;
}

.tableContainer :global(.-pagination) :global(.-btn:before) {
    font-family: "Font Awesome 5 Free";
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #444;
}

.tableContainer :global(.-pagination) :global(.-previous) :global(.-btn:before) {
    content: '\f104';
}

.tableContainer :global(.-pagination) :global(.-next) :global(.-btn:before) {
    content: '\f105';
}

.tableContainer :global(.-pagination) :global(.-pageJump) :global(input) { width: 50px; }