.content {
    margin: auto;
    max-width: 1200px;
}

.intro {
    font-size: 13px;
    width: 795px;
}

.title {
    font-size: 30px;
}

.actionButtons {
    position: absolute;
    top: 50px;
    right: 30px;
    display: flex;
    font-size: 15px;
}

.actionButtons>div {
    width: 180px;
}

.actionButtons>div input {
    width: 100%;
    font-size: 14px;
    border: 1px solid #9E9E9E;
    border-radius: 5px 0 0 5px;
}

.actionButtons>div div {
    width: 100%;
}

.actionButtons>button {
    background: #f6f7f8;
    color: #9e9e9e;
    margin-left: -1px;
    border: 1px solid #9E9E9E;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    min-width: 44px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.actionButtons>button:hover {
    background: #006fff;
    color: #FFF;
}

.inAppMenu {
    position: absolute;
    right: 240px;
    top: 5px;
}