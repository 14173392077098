.templatedisplay_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 2px solid black;
    padding: 15px;
    width: 100%;
}

.templatedisplay_container img {
    width:50%;
    height: auto;
    margin: 0 auto;
}

.previewDisplay {
    position: absolute;
    top: 0;
    right:0;
}

.saveStyleDisplay {
    position: absolute;
    bottom: 0;
    left:0;
}

.popinBackground {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(60, 60, 60, 0.64);
}

.popinDisplay {
    position: fixed;
    width: 500px;
    height: 300px;
    background-color: white;
    margin: 0 auto;
    top: calc((100vh - 300px) / 2 );
    left: calc((100% - 500px) / 2 );
    padding: 15px;
}

.popinDisplay > span {
    position: absolute;
    top:5px;
    right: 5px;
    cursor: pointer;
}