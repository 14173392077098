.ulMatchingList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 12px 0;
}

.titleH2MatchingList {
    top: 599px;
    left: 517px;
    margin: 12px 0 !important;
    height: 15px;
    color: #293133;
    text-align: left;
    font-size: 17px !important;
    letter-spacing: 0px;
    font-weight: bold;
}

.liMatchingList {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 11px;
    border-radius: 3px;
    margin: 0 10px 10px 0;
}

.liMatchingList p {
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #EDEDED;
    background: #F6F7F8;
    color: #9E9E9E;
    padding: 5px 10px 2px;
    border-radius: 3px 0 0 3px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.matchingListPen {
    cursor: pointer;
    border: 1px solid #EDEDED;
    background: #F6F7F8;
    color: #9E9E9E;
    border-left: none;
    padding: 5px 3px;
    border-radius: 0 3px 3px 0;
    display: flex;
    align-items: center;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.liMatchingListSelect p {
    background: #006fff;
    color: #FFFFFF;
}

.liMatchingList p:hover {
    color: #006fff;
    border-color: #006fff;
}

.liMatchingListSelect p:hover, .liMatchingList.liMatchingListSelect p:hover {
    color: #FFF;
}

.matchingListPen:hover {
    color: #FFFFFF;
    background: #006fff;
}

.liMatchingListSelect .matchingListPen {}

.liAddAList {
    cursor: pointer;
    background: #F6F7F8;
    border-radius: 3px;
    color: #9E9E9E;
    padding: 0 10px;
    font-size: 14px;
    margin: 0 10px 10px 0;
    display: flex;
    align-items: center;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.liAddAList i {
    font-size: 12px;
}

.liAddAList:hover {
    background: #006fff;
    color: #FFFFFF;
}

.contentMatchingListEdition.hidden {
    transform: translateX(100%);
}

.titleMatchingList {
    top: 30px;
    left: 873px;
    width: 200px;
    height: 24px;
    text-align: left;
    font-size: 23px;
    letter-spacing: 0px;
    color: #707070;
}

.contentMatchingListEdition {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 0;
    background-color: #FFF;
    width: 540px;
    height: 100%;
    padding: 22px 25px 0 25px;
    color: #707070;
    transition: transform .3s ease-in-out;
    transform: translateX(0%);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.33);
    overflow-y: auto;
}

.introMatchingList {
    width: 90%;
    color: #9e9e9e;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #9E9E9E;
    margin: 10px auto;
}

.contentMatchingListEdition label {
    margin: 0;
    font-size: 15px;
    display: block;
}

.nameMatchingList {
    background: #FFFFFF;
    border: 1px solid #969696;
    border-radius: 5px;
    width: 100%;
}

.textareaMatchingList {
    width: 100%;
    height: 179px;
    background: #FFFFFF;
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    resize: none;
    padding: 10px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.contentMatchingListEdition textarea:focus {
    border-color: #006fff;
}

.textareaAddSkuList {
    top: 924px;
    left: 517px;
    width: 100%;
    height: 115px;
    background: #FFFFFF;
    border: 1px solid #969696;
    border-radius: 5px;
    resize: none;
}

.titleTextareaMatchingList {
    font-size: 15px;
    color: #707070;
    margin: 20px 0 0;
}

.matching_actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.matchingList_btn {
    border-radius: 5px;
    margin-left: 10px;
    padding: 7px 10px;
    border: 1px solid;
    top: 702px;
    left: 1020px;
    width: 150px;
    height: 39px;
}

.matching-list-type-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 8px auto 4px auto;
}

.matching-list-type-list h2 {
    font-weight: 500;
}

.categories-list-help {
    width: 100%;
    text-align: center;
}

.help-tooltip-categories {
    cursor: pointer;
    width: 100%;
    position: relative;
    top: 22px;
    text-align: right;
}