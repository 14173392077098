.group_line {
    display: flex;
    gap: 10px 14px;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
}

.selected_attr {
    display: inline-flex;
    align-items: center;
    padding: 2px 5px;
    line-height: 1.2;
    background-color: var(--turquoise-light);
    border: solid 1px var(--turquoise);
    border-radius: 5px;
    margin-left: 8px;
    color: var(--turquoise);
}
.selected_attr_label {
    font-weight: 500;
}
.selected_attr_icon {
    font-size: 10px;
    margin-left: 4px;
}