.panel_inner{
    /* Padding horizontal des sections */
    --section-padding-left: 30px;
}

.panel_inner.is_invisible {
    opacity: 0;
    pointer-events: none;
}

.panel_overlay {
    position: fixed;
    z-index: 10200;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .4);
}

.panel_inner {
    position: fixed;
    z-index: 10200;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500px;
    max-width: calc(100% - 20px);
    background: #fff;
    box-shadow: rgba(0, 0, 0, .2) 0 0 20px;
    outline: none;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;

    transition: padding 300ms, width 300ms;
}

.panel_inner.left {
    left: 0;
    right: auto;
}

.panel_overlay.anim_show {
    animation: panel_overlay_show 300ms ease-out;
}

.panel_overlay.anim_hide {
    animation: panel_overlay_hide 300ms ease-out;
}

.panel_inner.anim_show {
    animation: panel_right_show 300ms ease-out;
}

.panel_inner.anim_hide {
    animation: panel_right_hide 300ms ease-out;
}

.panel_inner.anim_show.left {
    animation: panel_left_show 300ms ease-out;
}

.panel_inner.anim_hide.left {
    animation: panel_left_hide 300ms ease-out;
}

@keyframes panel_overlay_show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes panel_overlay_hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes panel_right_show {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes panel_right_hide {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(50%);
    }
}

@keyframes panel_left_show {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes panel_left_hide {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-50%);
    }
}

/* Panel close */
.panel_close {
	display: flex;
	align-items: center;
	justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    overflow: hidden;
	background: none;
	box-shadow: none;
	outline: none;
    border: none;
	padding: 0;
}
.panel_close_left{
	composes: panel_close;
    margin-left: -12px;
    margin-right: 10px;
}
.panel_close_absolute {
	composes: panel_close;
	position: absolute;
	width: 48px;
	height: 48px;
	top: 5px;
	right: 3px;
}
.panel_close_inner {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	width: 32px;
	height: 32px;
	border-radius: 32px;
	transition: background 200ms;
}
.panel_close:hover .panel_close_inner,
.panel_close:focus .panel_close_inner {
	background-color: var(--main-bg-color);
}
.panel_close_img {
	flex: 0 1 auto;
}

/* Panel side */
.panel_side{
    position: fixed;
    top: 0;
    bottom: 0;
    background: #F5F6F8;
    animation: panel_side_left_show 300ms ease-out;
}
.panel_inner.left .panel_side{
    right: 0;
}
@keyframes panel_side_left_show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* HEADER */
.panel_header{
	-ms-flex: 0 0 auto;
	-webkit-flex: 0 0 auto;
	-webkit-box-flex: 0;
	-moz-box-flex: 0;
	flex: 0 0 auto;
	padding: 20px 30px;
	line-height: 1;
}
.panel_header.has_border {
	border-bottom: solid 1px var(--main-border-color);
}
.panel_header.color_primary {
    background-color: var(--turquoise-light);
    border-color: var(--turquoise-border);
}
.panel_header.color_white {
    background-color: var(--white);
    border-color: var(--main-border-color);
}


.panel_header.has_no_bottom_padding {
	padding-bottom: 0;
}
.panel_header.size_m {
	padding-top: 20px;
	padding-bottom: 20px;
}
.panel_header.size_s {
	padding-top: 14px;
	padding-bottom: 14px;
}
.panel_header.size_xs {
	padding-top: 10px;
	padding-bottom: 10px;
}

.panel_title{
	font-size: 20px;
	font-weight: 400;
	line-height: 1.3;
}
.panel_desc {
	margin-top: 6px;
	font-size: 14px;
	color: #757b96;
	line-height: 1.3;
}
.panel_intro {
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: 500;
	color: var(--grey-2);
}

/* BODY */
.panel_body_outer {
	-ms-flex: 1 1 auto;
	-webkit-flex: 1 1 auto;
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.panel_body_outer_grey {
	background: var(--main-bg-color);
}
.panel_body_outer_rel {
	position: relative;
}
.panel_body_inner {
	flex: 1 1 auto;
	padding: 0 30px;
	overflow-y: auto;
}
.panel_body_inner::before,
.panel_body_inner::after{
	content: "";
	display: block;
	margin-bottom: 24px;
}
.panel_body_inner_no_vertical_margin::before,
.panel_body_inner_no_vertical_margin::after{
	display: none;
}
.panel_body_inner_no_horizontal_margin {
    padding-left: 0;
    padding-right: 0;
}


/* SECTION */
.panel_section_outer {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.panel_section_outer.has_bottom_border {
	border-bottom: solid 1px var(--main-border-color);
}
.panel_section_outer.has_top_border {
	border-top: solid 1px var(--main-border-color);
}
.panel_section_outer_grey {
	background: var(--main-bg-color);
}
.panel_section_outer_rel {
	position: relative;
}
.panel_section_inner {
	flex: 1 1 auto;
	padding: 0 30px;
	overflow-y: auto;
}
.panel_section_inner::before,
.panel_section_inner::after{
	content: "";
	display: block;
	margin-bottom: 24px;
}
.panel_section_inner_no_margin::before,
.panel_section_inner_no_margin::after{
	display: none;
}

.panel_section_inner.size_s::before,
.panel_section_inner.size_s::after {
	margin-bottom: 14px;
}
.panel_section_inner.size_xs::before,
.panel_section_inner.size_xs::after {
	margin-bottom: 10px;
}
