.labels {
    font-size: 16px;
    margin-top: 30px;
}
.tooltip {
    font-size: 11px;
    font-style: italic;
}
.input {
    border-radius: 5px;
    border: 1px solid #969696;
    margin-top: 7px;
    width: 286px;
    height: 40px;
}
.actionButtons {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.actionButtons button {
    font-size: 16px;
}
.cancelButton {
    border: 1px solid #444444;
    border-radius: 5px;
    padding: 6px 25px;
    width: 135px;
}
.saveButton {
    background: #006fff;
    border-radius: 5px;
    color: white;
    padding: 7px 25px;
    width: 137px;
}
.deleteLabel {
    font-size: 11px;
    text-align: center;
    margin-top: 17px;
    cursor: pointer;
}