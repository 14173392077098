.files-attachment-container-item {
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 80%;
    margin: 8px auto;
    align-items: center;
    font-weight: 400;
    font-size: 17px;
    border: 1px solid #dedede;
    padding: 22px 13px;
    box-shadow: 0 1px 4px #00000024;
}

.files-attachment-title {
    display: flex;
    justify-content: space-between;
    width: 90%;
    text-align: center;
}

.files-attachment-title p {
    width: 250px;
    line-height: 27px;
}

.files-attachment-title:hover {
    color: #006fff;
    cursor: pointer;
}

.files-attachment-delete-item i:hover {
    color: tomato;
    cursor: pointer;
}

.files-attachment-container-item i {
    font-size: 24px;
}