.col_outer {
    align-self: flex-end;
    margin-bottom: 3px;
}

.col_inner {
    line-height: 1.1;
    text-transform: none;
    position: relative;
}
.col_inner_upper_case {
    text-transform: uppercase;
}
.col_inner_font_size_l {
    font-size: 15px;
}
.col_inner_font_size_m {
    font-size: 14px;
}
.col_inner_font_size_s {
    font-size: 13px;
}

.col_link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;
}
.col_link:hover,
.col_link:focus {
    color: var(--turquoise);
}

.col_link .col_text {
    flex: 0 1 auto;
    min-width: 0;
    text-wrap: balance;
}
.col_inner_has_tooltip .col_text {
    text-decoration-line: underline;
    text-decoration-style: dashed;
    text-decoration-color: var(--grey-6);
    text-underline-position: under;
}

.col_arrow {
    font-size: 12px;
}