.title {
	font-size: 14px;
	font-weight: 500;
	color: var(--grey-3);
	margin-bottom: 10px;
}

.table {
	display: flex;
	align-items: flex-end;
}
.table_col {
	flex: 0 0 auto;
}

.bar {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	border-bottom: solid 1px var(--grey-6);
	padding: 0 8px;
}
.bar_inner {
	--barTop: 100px;
	flex: 0 0 auto;
	width: 100%;
	background: linear-gradient(0deg, #7FDFF5 0%, #7DD34D var(--barTop));
	border-radius: 3px 3px 0 0;
	height: 0;
	transition: height 400ms;
}

.value {
	text-align: center;
	font-size: 14px;
	line-height: 1.2;
	margin-top: 7px;
	padding: 0 7px;
}
.label {
	text-align: center;
	font-size: 14px;
	line-height: 1.2;
	margin-top: 6px;
	font-weight: 500;
	color: var(--grey-2);
	padding: 0 7px;
}