/* List */
.list{
	display: inline-flex;
	align-items: center;
}

/* Item */
.item_common{
	margin: 0 5px;
	padding: 9px;
	display: flex;
	align-items: center;
	border-radius: 50px;
	transition: all 200ms;
	cursor: default;
}
.item{
	composes: item_common;
	color: var(--grey-4);
}
.item_active{
	composes: item_common;
	color: var(--turquoise);
	font-weight: 500;
}
.item_clickable {
	cursor: pointer;
}
.item_clickable:hover{
	background: var(--turquoise-light);
	color: var(--turquoise);
}

/* Index */
.item_index{
	flex: 0 0 auto;
	width: 22px;
	height: 22px;
    border-radius: 22px;
	margin-right: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
    font-weight: 600;
    line-height: 1;
    background: currentColor;
}
.item_index_inner{
	color: #fff;
}

/* Name */
.item_name{
	flex: 0 1 auto;
	font-size: 16px;
}

@media (max-width: 1360px) {
	.item_common{ 
		margin-left: 3px;
		margin-right: 3px;
		padding: 6px;
	}
}