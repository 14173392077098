/* Wrapper */
.wrapper_common {
    
}
.wrapper_close {
    composes: wrapper_common;
}
.wrapper_open {
    composes: wrapper_common;
}

/* Header */
.header {
	display: flex;
    align-items: center;
}
.header_btn {
	position: relative;
    flex: 0 0 auto;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 10px;
	padding-left: 24px;
    border-radius: 5px;
    transition: all 200ms;
}
.header_btn:hover,
.header_btn:focus {
    background-color: var(--main-border-color);
}
.header::after {
    content: "";
    flex: 1 1 auto;
    border-top: solid 1px var(--main-border-color);
    margin-left: 4px;
}

/* Arrow */
.arrow {
	position: absolute;
	left: 0px;
	top: 50%;
	margin-top: -7px;
	width: 20px;
	height: 14px;
	transition: all 100ms;
}
.arrow .fas {
	display: block;
}
.wrapper_open .arrow {
    transform: rotate(180deg);
}

/* Body */
.body {
    transition: all 200ms;
    margin-top: 8px;
    margin-bottom: 16px;
}
.wrapper_close .body {
    height: 0;
    margin-top: 0;
    overflow: hidden;
}