.wheel{
	display: inline-block;
	width: 24px;
	height: 24px;
	box-sizing: border-box;
	border-radius: 50%;
	border: 2px solid rgba(76,82,105,0.1);
	border-left-color: rgba(76,82,105,0.7);
	transform: translateZ(0);
	-webkit-animation: wheel_rotation 1s infinite linear;
	animation: wheel_rotation 1s infinite linear;
}

/* size */
.wheel.size_xxs{
	width: 12px;
	height: 12px;
}
.wheel.size_xs{
	width: 14px;
	height: 14px;
}
.wheel.size_s{
	width: 16px;
	height: 16px;
}
.wheel.size_m{
	width: 24px;
	height: 24px;
}
.wheel.size_l{
	width: 32px;
	height: 32px;
}
.wheel.size_xl{
	width: 48px;
	height: 48px;
	border-width: 3px;
}

/* color */
.wheel.white{
	border-color: rgba(255,255,255,.3);
	border-left-color: rgba(255,255,255,1);
}

/* full */
.wheel.full{
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.wheel.full.vertical_size_xs{
	margin-top: 16px;
	margin-bottom: 16px;
}
.wheel.full.vertical_size_s{
	margin-top: 32px;
	margin-bottom: 32px;
}
.wheel.full.vertical_size_m{
	margin-top: 64px;
	margin-bottom: 64px;
}
.wheel.full.vertical_size_l{
	margin-top: 96px;
	margin-bottom: 96px;
}
.wheel.full.vertical_size_xl{
	margin-top: 128px;
	margin-bottom: 128px;
}

/* Animation */
@-webkit-keyframes wheel_rotation{
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes wheel_rotation{
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
