.fieldRow {
    composes: label-top from global;
}

.fieldRow + .fieldRow {
    margin-top: 20px;
}

.fieldRow input {
    margin-top: 0;
}