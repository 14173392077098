.simplify_target_helper {
    font-size: 15px;
    color: #9E9E9E;
    margin-top: 10px;
}

.simplify_title_target_selection {
    margin-top: 2px;
    font-weight: bold;
}

.simplify_textarea_sku_list {
    width: 100%;
    height: 470px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    resize: none;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
}

.simplify_listOfSkuApi {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    height: 450px;
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    border: 1px solid #EDEDED;
    padding: 10px;
}

.simplify_editSkuList {
    flex-direction: column;
}

.simplify_editSkuList .symplify_itemSku {
    width: 100%;
    display: flex;
    position: relative;
}

.simplify_editSkuList p {
    position: relative;
    margin-left: 0;
    transition: all .2s ease-in-out;
}

.simplify_editSkuList span:hover {
    color: #D76666;
}

.simplify_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
}

.simplify_itemSku {
    position: relative;
    padding-right: 10px;
    width: 100%;
    height: 20px;
    margin-bottom: 10px;
    line-height: normal;
}

.simplify_itemSku span {
    color: #9E9E9E;
}

.symplify_btn {
    border-radius: 5px;
    margin-left: 10px;
    padding: 7px 10px;
    border: 1px solid;
}

.simplify_save {
    background: #006fff;
    border-radius: 5px;
    color: #FFF;
    margin-left: 10px;
    padding: 7px 10px;
    border: 1px solid;
}

.simplify_btn-cancelDelete {
    margin-left: 10px;
    padding: 7px 10px;
    background: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 5px;
    color: #9E9E9E;
}

.simplify_btn-cancelDelete:hover {
    background: #FFFFFF;
    border: 1px solid #7e7c7c;
    color: #7a7a7a;
}

.simplify_editSkuList .simplify_itemSku:hover p {
    margin-left: 25px;
}

.simplify_editSkuList .simplify_itemSku .deleteItem {
    position: absolute;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    font-size: 14px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.simplify_editSkuList .simplify_itemSku:hover .deleteItem {
    visibility: visible;
    opacity: 1;
}

.simplify_header_sku {
    display: flex;
    justify-content: space-between;
}

.simplify_header_sku .search_tag {
    width: 60%;
}