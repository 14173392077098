.container {
    composes: section from "../../WebAnalyticsContainer.module.css";

    min-height: 440px;
    overflow: hidden;
    width: 100%;
}

.titleContainer {
    padding: 12px 0px 11px;
    margin: 0px 19px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px #E1E2EB;
}

.title {
    text-align: left;
    font-size: 20px;
    color: #4C5269;
    font-weight: 500;
}
.fa-chevron-right:before {
    font-size: 14px;
    margin-left: 11px;
}
.title .prev {
    color: var(--turquoise);
    margin-right: 11px;
    cursor: pointer;
}
.title .prev .text{
    margin-right: 11px;
}

.cardContainer {
    padding: 10px 23px 0px 20px ;
}

.screenContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    padding: 6px 4px;
    color: #4C5269;
    cursor: pointer;
    position: relative;
}
.screenContainerDouble{
    composes: screenContainer;
    padding-top: 2px;
    padding-bottom: 4px;
    border-bottom: solid 1px var(--main-border-color);
}

.colDefault{
    position: relative;
}

.colScreen {
    composes: colDefault;
    flex: 1 1 auto;
    font-size: 14px;
}

.colScreen .number {
    color: #8A8EA5;
    margin-right: 5px;
}

.colScreen:last-of-type {
    padding-bottom: 0px;
}

.colView {
    composes: colDefault;
    flex: 0 0 auto;
    min-width: 40px;
    font-size: 14px;
    margin-left: 8px;
    text-align: right;
}
.colPercent {
    composes: colDefault;
    flex: 0 0 auto;
    min-width: 40px;
    font-size: 12px;
    margin-left: 8px;
    text-align: right;
}

.colDiff{
    composes: colDefault;
    flex: 0 0 auto;
    min-width: 60px;
    font-size: 14px;
    text-align: right;
    margin-left: 8px;
}

.colInnerTop{
    margin-bottom: 5px;
}
.colInnerBottom{
    margin-top: 5px;
}

.progress{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: var(--turquoise-light);
    pointer-events: none;
    border-radius: 3px;
}
.progressTop{
    composes: progress;
    top: 3px;
    bottom: calc(50% + 1px);
}
.progressBottom{
    composes: progress;
    top: calc(50% + 1px);
    bottom: 3px;
    background: #F6F4FC;
}

.viewMoreButton {
    padding: 12px 19px 26px;
    cursor: pointer;
    color: var(--turquoise);
    font-size: 14px;
    font-weight: 500;
}

.isSelected{
    color: var(--turquoise);
    font-weight: 500;
}

.loadingBarContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loadingBar {
    border-radius: 5px;
    height: 15px;
    width: 100%;
    background-color: grey
}

.play .loadingBar {
    background: linear-gradient(
        to right, 
        rgba(238,236,239,1) 0%,
        rgba(238,236,239,1) 45%,
        rgba(238,236,239,0) 50%,
        rgba(238,236,239,1) 55%,
        rgba(238,236,239,1) 100%
    );
    animation-duration: 1s;
    animation-name: load;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    background-size: 400% 100%;
    margin-bottom: .5em;
}

.loadingBar:last-of-type {
    margin-bottom: 10px;
}

.icon{
    display: inline-block;
    margin: 0 4px 0 -2px;
}

@keyframes load {
    from {
        background-position: 0% 50%
    }
    to { 
        background-position: 100% 50%
    }
}