.inspi_wrapper{
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: inherit;
	overflow: hidden;
}
.inspi_header {
	flex: 0 0 auto;
	border-bottom: solid 1px var(--main-border-color);
	padding: 0 16px 0 22px;
}
.inspi_body {
	flex: 1 1 auto;
	display: flex;
	align-items: stretch;
	overflow: hidden;
}
.inspi_sidebar {
	flex: 0 0 auto;
	width: 441px;
	border-right: solid 1px var(--main-border-color);
}
.inspi_sidebar_s {
	width: 300px;
}
.inspi_modules {
	flex: 1 1 auto;
	background-color: var(--main-bg-color);
}

/* Sidebar */
.inspi_sidebar_inner{
	padding: 22px;
}

/* Objective list */
.inspi_obj_list > li{
	display: block;
	padding: 4px 0;
	position: relative;
}
.inspi_obj_list > li + li::before{
	content: "";
	position: absolute;
	top: 0;
	left: 55px;
	right: 10px;
	border-top: solid 1px #F5F6F8;
}
.inspi_obj_list > li > a{
	color: var(--grey-0);
	padding: 8px 12px;
	border-radius: 6px;
	transition: color 200ms, background 200ms;
	cursor: pointer;
}
.inspi_obj_list > li > a:hover,
.inspi_obj_list > li > a:focus{
	background: #f5f6f8;
}
.inspi_obj_list > li > a.active{
	color: var(--turquoise);
	background: var(--turquoise-light);
}

.inspi_obj_item_icon{
	margin-right: 12px;
}
.inspi_obj_item_label{
	font-weight: 500;
	font-size: 14px;
}
.inspi_obj_item_arrow{
	margin-left: 12px;
	opacity: .8;
}

/* Format list */
.inspi_format_list {
	display: flex;
	flex-wrap: wrap;
	gap: 18px;
}
.inspi_format_list > li{
	flex: 0 1 auto;
	width: calc(50% - 9px);
}
.inspi_format_list > li > a{
	display: block;
	border-radius: 8px;
	border: solid 1px var(--main-border-color);
	background: #fff;
	transition: all 200ms;
	color: var(--grey-0);
}
.inspi_format_list > li > a.active{
	background-color: var(--turquoise-light);
	border-color: var(--turquoise);
	color: var(--turquoise);
}
.inspi_format_item_img {
	width: 100%;
}
.inspi_format_item_label {
	display: block;
	text-align: center;
	font-weight: 500;
	font-size: 15px;
	padding: 8px;
}



/* Modules */
.inspi_modules_inner{
	margin: 0 auto;
	max-width: 825px;
	padding: 46px 40px;
}

/* Header */
.inspi_modules_header_nb{
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 4px;
}
.inspi_modules_header_title{
	font-size: 24px;
	font-weight: 600;
}
.inspi_modules_header_desc{
	font-size: 16px;
	margin-top: 6px;
}

/* Item */
.inspi_item{
	margin-top: 20px;
	background: #fff;
	border-radius: 8px;
	border: solid 1px #E2E4EA;
	box-shadow: rgba(0,0,0,.04) 0 3px 6px;
	padding: 10px;
	cursor: default;
}
a.inspi_item[href]{
	cursor: pointer;
	transition: all 200ms;
}
a.inspi_item[href]:hover{
	border-color: #C1C5D0;
	box-shadow: rgba(0,0,0,.06) 0 3px 20px;
}
.inspi_item_thumb{
	position: relative;
	width: 250px;
	background: #F5F6F8;
	margin-right: 14px;
	border-radius: 4px;
	overflow: hidden;
}
.inspi_item_thumb img{
	display: block;
	width: 100%;
}
.inspi_item_thumb::after{
	content: "";
	position: absolute;
	border-radius: 4px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: rgba(0,0,0,.05) 0 0 1px 1px inset;
}

.inspi_item_content{
	padding: 10px;
}

.inspi_item_title{
	font-size: 18px;
	font-weight: bold;
	line-height: 1.25;
}
.inspi_item_format{
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	margin-top: 4px;
}
.inspi_item_obj{
	font-size: 12px;
	font-weight: 500;
	margin-top: 4px;
}
.inspi_item_desc{
	font-size: 13px;
	margin-top: 8px;
}

.inspi_item_tag{
	display: inline-block;
	margin-left: 20px;
	font-size: 11px;
	font-weight: 600;
	color: #EDA80E;
	border: solid 1px;
	border-radius: 30px;
	padding: 3px 10px;
	line-height: 14px;
	background: #FDF7E7;
}

.inspi_item_arrow{
	padding: 10px;
}
.inspi_item_arrow i{
	display: block;
}