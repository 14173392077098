.label {
	display: inline-block;
	background-color: #F2F3F8;
	color: #6b6b6d;
	font-size: 12px;
	border-radius: 30px;
	line-height: 16px;
	padding: 3px 4px;
	margin-right: 5px;
}
.label_success {
	composes: label;
	font-weight: 500;
	font-size: 14px;
	margin-top: 2px;
	margin-bottom: 2px;
	color: var(--color-success);
	background-color: var(--color-success-light);
}
.label_grey {
	background-color: var(--grey-4);
	color: var(--white);
}
.label_blue { background-color: #B8DFFB; color: #025EA0; }
.label_green { background-color: #CCEEC0; color: #3B891F; }
.label_red { background-color: #FFC5B7; color: #D53B16; }
.label_orange { background-color: #FDC893; color: #AF5701; }
.label_yellow { background-color: #FFF3AF; color: #A38A00; }
.label_purple { background-color: #E4C9F5; color: #560E81; }

.label_icon {

}
.label_text {
	display: inline-block;
	font-weight: 500;
	margin: 0 5px;
}