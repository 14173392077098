
.slide-selector {
    border: 1px solid white;
    background-color: thistle;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
}

.slide-selector-number {
    flex: 1 1 auto;
    padding: 10px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 26px;
}

.activeSlide {
    border: 1px solid rgb(240, 247, 255);
    background-color: #006fffe0;
}