
.web_analytics_container {
  background-color: red;
}
.analytics_body{
  
}
.analytics_body_main{
  
}
.analytics_body_side{
  position: relative;
}
.analytics_main_line{
  margin-bottom: 12px;
}

/* Insights */
.analytics_insight_group{
  display: flex;
  flex-wrap: wrap;
}
.analytics_insight{
  position: relative;
  min-width: 172px;
  min-height: 80px;
  flex: 0 0 auto;
  background-color: #FFF;
  border: 1px solid #E9EAF0;
  border-radius: 6px;
  padding: 14px 14px;
  margin-left: 12px;
  margin-bottom: 12px;
  line-height: 1.1;
}
.analytics_insight:first-child {
  margin-left: 0;
}
.analytics_insight.by_grey {
  background-color: #F7F8F9;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 74px;
}
.analytics_insight_unit{
  font-size: 12px;
  color: var(--grey-3);
}
.analytics_insight_tendance{
  font-size: 12px;
  margin-left: -6px;
  color: var(--grey-3);
}
.analytics_insight_tendance.l{
  font-size: 26px;
  font-weight: 500;
  margin-left: 0;
}
.analytics_insight_tendance.tendance_flat,
.analytics_body .tendance_flat{
  color: #DEA200;
}
.analytics_insight_tendance.tendance_up,
.analytics_body .tendance_up{
  color: #44A86B;
}
.analytics_insight_tendance.tendance_down,
.analytics_body .tendance_down{
  color: #C93636;
}


/* Range picker */
.analytics_range{
  display: inline-block;
  background: #fff;
  border-radius: 5px;
  border: solid 1px #E9EAF0;
  padding: 5px 12px;
  cursor: pointer;
  transition: all 200ms;
  margin-right: 12px;
}
.analytics_range:hover,
.analytics_range:focus{
  border: var(--input-border-hover);
  box-shadow: 0 0 3px rgba(0,0,0,.1);
}
.analytics_range_sup{
  font-size: 10px;
  color: var(--grey-2);
  text-transform: uppercase;
  line-height: 14px;
}
.analytics_range_value{
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-0);
  line-height: 20px;
}
.analytics_range_cal{
  color: var(--grey-2);
  margin-left: 20px;
}
.analytics_range_cal .fas{
  display: block;
}
.analytics_range_arrow{
  font-size: 10px;
  margin: 0 2px;
  vertical-align: 1px;
  color: var(--grey-4);
}
.analytics_range_remove{
  background: var(--input-border-color);
  margin: -5px -12px -5px 10px;
  padding: 4px;
  align-self: stretch;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

.analytics_range_drop_arrow{
    margin-left: 20px;
    margin-right: -6px;
    width: 16px;
    height: 16px;
    background: url(/Assets/common.svg) no-repeat;
}
.analytics_range_close{
  margin-left: 20px;
}
.analytics_range_close .fas{
  display: block;
}

.analytics_range.active{
  border-color: var(--turquoise);
  background: var(--turquoise);
}
.analytics_range.active *{
  color: #fff !important;
}
.analytics_range.active .analytics_range_drop_arrow{
  background-position: -48px 0;
}

/* Chart */
.analytics_empty_state{
  margin-top: 110px;
  margin-bottom: 110px;
}

/* Table */
.analytics_table_wrapper .react-bootstrap-table{
  font-size: 14px;
  color: var(--grey-0);
}
.analytics_table_wrapper .react-bootstrap-table .table>thead>tr>th,
.analytics_table_wrapper .react-bootstrap-table .table>thead>tr>td{
  font-weight: 500;
  border-bottom: 0;
}
.analytics_table_wrapper .react-bootstrap-table .table>tbody>tr>th,
.analytics_table_wrapper .react-bootstrap-table .table>tbody>tr>td{
  border-top: solid 1px var(--main-border-color);
}


/* Boards */
.table_grid_analytics .table_body_row{
  align-items: baseline;
  font-size: 14px;
  color: var(--grey-0);
}
.table_grid_analytics .table_body_total_row{
  font-weight: 500;
  background: var(--main-bg-color);
}
.table_grid_analytics .table_col:nth-child(n+2){
  justify-content: flex-end;
  text-align: right;
}

.table_grid_analytics_page .table_row{
  grid-template-columns: 3fr 1fr 1fr 1.9fr 1.3fr 1fr .8fr;
}
.table_grid_analytics_page2 .table_row{
  grid-template-columns: 3fr 1fr 1fr 1.9fr 1.3fr 1fr .8fr .8fr;
}
.table_grid_analytics_interaction_1 .table_row{
  grid-template-columns: 3fr 1.3fr 1.5fr 1.1fr 1fr 50px;
}
.table_grid_analytics_interaction_2 .table_row{
  grid-template-columns: 2.4fr 1.5fr 1.6fr 1.2fr 1fr;
}
.table_grid_analytics_ecom_perf .table_row{
  grid-template-columns: 3fr 1.8fr 1.3fr 1fr 1fr 1fr;
}
.table_grid_analytics_ecom_transaction .table_row{
  grid-template-columns: 3fr 1.6fr 1fr 1fr 1fr 1.2fr;
}
.table_grid_analytics_ecom_buy_behaviour .table_row{
  grid-template-columns: 2.4fr 1.6fr 1.6fr 1fr 1fr 1fr 1fr 1fr;
  align-items: flex-end;
}

.table_grid_analytics_ecom_buy_behaviour .table_head_row .table_col {
  font-size: 12px;
  text-transform: none;
}
.table_grid_analytics_ecom_buy_behaviour .table_head_row .table_col .table_sort {
  white-space: normal;
}
