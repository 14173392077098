.builder_sidebar .gjs-layers {
	
}

.builder_sidebar .gjs-layer-children {

}


.builder_sidebar .gjs-layer {
	background-color: transparent;
	font-weight: 400;
	color: var(--grey-0);
}
.builder_sidebar .gjs-layer.gjs-selected > .gjs-layer-title-c {
	background-color: transparent;
}

.builder_sidebar .gjs-layer .gjs-layer-title-c .gjs-layer-title {
	background-color: transparent;
	transition: background 200ms;
	color: var(--grey-0);
	font-size: 14px;
	font-weight: 400;
	letter-spacing: normal;
	line-height: normal;
	border-radius: 3px;
	border: none;
	padding: 3px 10px;
}

.builder_sidebar .gjs-layer.gjs-hovered > .gjs-layer-title-c .gjs-layer-title {
	background-color: var(--main-bg-color);
}
.builder_sidebar .gjs-layer.gjs-selected > .gjs-layer-title-c .gjs-layer-title {
	background-color: var(--turquoise-light);
	color: var(--turquoise);
	font-weight: 500;
}

.builder_sidebar .gjs-layer .gjs-layer-title-c .gjs-layer-title .gjs-layer-name {
	height: auto;
}

.builder_sidebar .gjs-layer .gjs-layer-title-c .gjs-layer-title-inn {
	flex: 1 1 auto;
	width: auto;
	margin-left: -32px;
}

.builder_sidebar .gjs-layer__icon {
	padding-left: 0;
	margin-left: 5px;
	max-width: none;
	max-height: none;
	width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;;
}
.builder_sidebar .gjs-layer__icon svg {
	width: 100%;
	height: auto;
}

.builder_sidebar .gjs-layer-count {
	line-height: 13px;
	display: none;
}
