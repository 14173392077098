.datePickerBtn {
    right: 30px;
    display: flex;
    font-size: 15px;
}

.datePickerBtn>div {
    width: 180px;
}

.datePickerBtn>div input {
    width: 100%;
    font-size: 14px;
    border: 1px solid #9E9E9E;
    border-radius: 5px 0 0 5px;
}

.datePickerBtn>div div {
    width: 100%;
}

.datePickerBtn>button {
    background: #f6f7f8;
    color: #9e9e9e;
    margin-left: -1px;
    border: 1px solid #9E9E9E;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    min-width: 44px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.datePickerBtn>button:hover {
    background: #006fff;
    color: #FFF;
}

.datePickerBtn {
    position: absolute;
    top: 50px;
    right: 20px;
}

.datePickerBtn button {
    font-size: 16px;
    margin-right: 10px;
}

.header-fromto {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 44px;
    margin-left: 20px;
}

.header-fromto input {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 8px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #9E9E9E;
    margin-right: 0;
    height: 100%;
    min-width: 190px;
}

.header-fromto button {
    background: #f6f7f8;
    color: #9e9e9e;
    margin-left: -1px;
    border: 1px solid #9E9E9E;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    min-width: 44px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.header-fromto button:hover {
    background: #006fff;
    color: #FFF;
}

.linkReact {}