.wrapper {
    display: inline-flex;
    align-items: center;
}
.wrapper_block {
    display: flex;
}
.gaugeOuter {
    flex: 0 0 auto;
    width: 80px;
    height: 12px;
    border-radius: 12px;
    background-color: var(--grey-7);
    overflow: hidden;
    padding: 2px;
}
.gaugeInner {
    background-color: green;
    width: 0%;
    max-width: 100%;
    min-width: 10%;
    height: 8px;
    border-radius: 8px;
    transition: width 400ms;
}
.value {
    flex: 0 0 auto;
    margin-left: 10px;
}

.color_1 { background-color: #D33F3A;}
.color_2 { background-color: #EF4729;}
.color_3 { background-color: #F76126;}
.color_4 { background-color: #FB8B26;}
.color_5 { background-color: #FBAB2A;}
.color_6 { background-color: #EBBB29;}
.color_7 { background-color: #C5BD36;}
.color_8 { background-color: #94B443;}
.color_9 { background-color: #52A552;}
.color_10 { background-color: #239760;}