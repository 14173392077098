.btn{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
	padding: 6px;
	font-size: 20px;
	cursor: pointer;
	border-radius: 40px;
	line-height: 1.25em;
	transition: all 200ms;
}
.btn_disabled,
.btn_disabled:hover{
	cursor: not-allowed;
	opacity: .4;
}
.btn_readonly:hover{
	cursor: default;
}

/* Theme */
.btn_theme_darker {
	color: var(--grey-2);
}
.btn_theme_dark {
	color: var(--grey-3);
}
.btn_theme_medium {
	/*color: var(--grey-4);*/
	color: rgba(20, 30, 57, .32);
}
.btn_theme_light {
	color: var(--grey-5);
}

/* Color */
.btn_color_primary:focus,
.btn_color_primary:hover{
	color: var(--turquoise);
	background-color: var(--turquoise-light);
}
.btn_color_primary.btn_active{
	color: var(--turquoise);
}

.btn_color_alert:focus,
.btn_color_alert:hover{
	color: var(--color-alert);
	background-color: var(--color-alert-light);
}
.btn_color_alert.btn_active{
	color: var(--color-alert);
}

.btn_color_success:focus,
.btn_color_success:hover{
	color: var(--color-success);
	background-color: var(--color-success-light);
}
.btn_color_success.btn_active{
	color: var(--color-success);
}

.btn_color_yellow:not(.btn_readonly):focus,
.btn_color_yellow:not(.btn_readonly):hover{
	color: #FFC400;
	background-color: #FCF4DA;
}
.btn_color_yellow.btn_active{
	color: #FFC400;
}

/* Size */
.btn_xxl{
	font-size: 20px;
	margin: 0 5px;
}
.btn_xl{
	font-size: 18px;
	margin: 0 3px;
}
.btn_l{
	font-size: 17px;
	margin: 0 3px;
}
.btn_m{
	font-size: 16px;
	margin: 0 1px;
}
.btn_s{
	font-size: 14px;
	margin: 0;
}
.btn_xs{
	font-size: 12px;
	margin: 0;
}

/* Bubble */
.bubble {
	position: absolute;
	z-index: 1;
	top: -2px;
	right: -2px;
	font-size: 11px;
	font-weight: 700;
	color: var(--white);
	background-color: var(--color-alert);
	line-height: 1.2;
	padding: 2px 2px 1px;
	border: solid 2px var(--white);
	border-radius: 5px;
	text-align: center;
	min-width: 18px;
}

/* Icon */
.icon {
	width: 1.25em;
	line-height: 1.25em;
	text-align: center;
}
.icon_crossed_out {
	position: relative;
}
.icon_crossed_out::after {
	content: "";
	width: 2px;
	height: calc(1.25em + 4px);
	background-color: currentColor;
	border-radius: 2px;
	position: absolute;
	left: 50%;
	transform: rotate(45deg);
}

