.header {
    display: flex;
    align-items: center;
}
.header_col_left {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
}
.header_col_center {
    margin: 0 30px;
    flex: 0 0 auto;
}
.header_col_right {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
}

.body {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.body_messages {
    flex: 0 0 auto;
    padding: 10px;
}
.body_main {
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    align-items: stretch;
}
.col_editor {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    display: flex;
    flex-direction: column;
}
.col_preview {
    position: relative;
    padding: 30px;
    flex: 0 0 auto;
    overflow-y: auto;
    width: 450px;
    background-color: #EFF1F4;
    border-left: solid 1px var(--main-border-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.editor_header {
    padding: 26px 30px 16px;
    flex: 0 0 auto;
}
.editor_body {
    padding: 26px 30px;
    flex: 1 1 auto;
}
.editor_header + .editor_body {
    padding-top: 0;
}

/* Appearance */
.appearance_line {
    margin: 8px 0;
}

