.add_kpi{
    
}
.add_kpi_group{
    margin-bottom: 20px;
}
.add_kpi_group_label{
    font-weight: 500;
    color: #5D637E;
    margin-bottom: 6px;
}
.add_kpi_group_list{
    
}
