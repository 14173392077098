@value ellips from "../../../Components/utils.module.css";

.wrapper {
    
}
.wrapper + .wrapper {
    margin-top: 20px;
}
.title {
    font-weight: 500;
}
.empty_text {
    color: var(--grey-2);
    margin-top: 8px;
    font-size: 14px;
}
.list {

}
.item {
    display: flex;
    align-items: center;
    margin: 16px 0;
}
.item_bullet {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: pink;
    margin-right: 18px;
}
.item_bullet_scheduled {
    composes: item_bullet;
    background-color: #FCCB27;
}
.item_bullet_ongoing {
    composes: item_bullet;
    background-color: var(--green);
}
.item_name {
    flex: 0 1 auto;
    composes: ellips;
}
.item_sep {
    flex: 1 1 auto;
    margin: 0 14px;
    border-top: dashed 1px var(--main-border-color);
}
.item_date_or_kpi {
    flex: 0 0 auto;
    color: var(--grey-2);
}
