.drop {
	transition: all 200ms;
}
.drop_normal,
.drop_accept,
.drop_reject {
	composes: drop;
}
.drop_accept {
	background-color: var(--color-success-light);
}
.drop_reject {
	background-color: var(--color-alert-light);
}

.btn_wrapper {
	margin-top: 10px;
}