/* .shepherd-button {
    background: #3288e6;
    border: 0;
    border-radius: 3px;
    color: hsla(0, 0%, 100%, .75);
    cursor: pointer;
    margin-right: .5rem;
    padding: .5rem 1.5rem;
    transition: all .5s ease
}

.shepherd-button:not(:disabled):hover {
    background: #196fcc;
    color: hsla(0, 0%, 100%, .75)
}

.shepherd-button.shepherd-button-secondary {
    background: #f1f2f3;
    color: rgba(0, 0, 0, .75)
}

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
    background: #d6d9db;
    color: rgba(0, 0, 0, .75)
}

.shepherd-button:disabled {
    cursor: not-allowed
} */

.shepherd-footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: flex-end;
    margin-top: 26px;
}

.shepherd-footer .shepherd-button:last-child {
    margin-right: 0
}

.shepherd-cancel-icon {
    background: transparent;
    border: none;
    color: hsla(0, 0%, 50.2%, .75);
    font-size: 2em;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    padding: 0;
    transition: color .5s ease
}

.shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
    color: hsla(0, 0%, 50.2%, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, .75)
}

.shepherd-title {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
}

.shepherd-header {
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: flex-end;
}

.shepherd-has-title .shepherd-content .shepherd-header {
    margin-bottom: 10px;
}

.shepherd-text {
    font-size: 15px;
    line-height: 1.3em;
    margin-bottom: 18px;
}

.shepherd-text p,
.shepherd-text ul {
    margin-top: 0;
    margin-bottom: 8px;
}

.shepherd-text p:last-child,
.shepherd-text ul:last-child {
    margin-bottom: 0
}

.shepherd-content {
    border-radius: 5px;
    outline: none;
    padding: 18px;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
}

.shepherd-element {
    background: #4257F0;
    color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .16);
    max-width: 400px;
    opacity: 0;
    outline: none;
    transition: opacity .3s, visibility .3s;
    visibility: hidden;
    width: 100%;
    z-index: 2000;
    margin: 15px!important;
}

.shepherd-enabled.shepherd-element {
    opacity: 1;
    visibility: visible
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden
}

.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
    box-sizing: border-box;
}

.shepherd-arrow,
.shepherd-arrow:before {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: -1;
}

.shepherd-arrow:before {
    content: "";
    transform: rotate(45deg);
    background: #4257F0;
}

.shepherd-element[data-popper-placement^=top]>.shepherd-arrow {
    bottom: -8px;
}

.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow {
    top: -8px
}

.shepherd-element[data-popper-placement^=left]>.shepherd-arrow {
    right: -8px
}

.shepherd-element[data-popper-placement^=right]>.shepherd-arrow {
    left: -8px
}

.shepherd-element.shepherd-centered>.shepherd-arrow {
    opacity: 0
}

.shepherd-element.shepherd-has-title[data-popper-placement^=top]>.shepherd-arrow:before {
    border-right: 1px solid #4257F0;
    border-bottom: 1px solid #4257F0;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
    pointer-events: none
}

.shepherd-modal-overlay-container {
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
    width: 100vw;
    z-index: 1999;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    height: 100vh;
    opacity: .26;
    transition: all .3s ease-out, height 0s 0s, opacity .3s 0s
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
    pointer-events: all;
    fill: #0B0E29;
}

.shepherd-step-highlight {
    padding: 5px
}

.btnUi.shepherd-button-hide{
    margin-right: auto;
    margin-left: -11px;
    font-weight: 500;
}
.btnUi.shepherd-button-back{
    color: #fff;
    border-color: currentColor;
    font-weight: 500;
}
.btnUi.shepherd-button-next{
    background: #fff;
    color: #4257F0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
}

.shepherd_resume{
    position: fixed;
    left: 16px;
    bottom: 16px;
    z-index: 30;
    animation: 600ms ease-in-out shepherd_resume;
}

@keyframes shepherd_resume {
    0%{
        margin-bottom: -100px;
    }
    70%{
        margin-bottom: 20px;
    }
    100%{
        margin-bottom: 0;
    }
}

/* Optional color */
.shepherd-element.shepherd-element-optional{
    background: #7549ED;
}
.shepherd-element.shepherd-element-optional .shepherd-arrow:before {
    background: #7549ED;
}
.shepherd-element.shepherd-element-optional .btnUi.shepherd-button-next{
    color: #7549ED;
}
.shepherd-element.shepherd-element-optional .shepherd-element.shepherd-has-title[data-popper-placement^=top]>.shepherd-arrow:before {
    border-right: 1px solid #7549ED;
    border-bottom: 1px solid #7549ED;
}

/* TODO color */
.shepherd-element.shepherd-element-todo{
    background: #d731a7ff;
}
.shepherd-element.shepherd-element-todo .shepherd-arrow:before {
    background: #d731a7ff;
}
.shepherd-element.shepherd-element-todo .btnUi.shepherd-button-next{
    color: #d731a7ff;
}
.shepherd-element.shepherd-element-todol .shepherd-element.shepherd-has-title[data-popper-placement^=top]>.shepherd-arrow:before {
    border-right: 1px solid #d731a7ff;
    border-bottom: 1px solid #d731a7ff;
}

/* Z-INDEX */
.shepherd-element-over-popin{
    z-index: 10001;
}
.shepherd-element-under-arrow{
    z-index: 4;
}
