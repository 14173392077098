.content {
    background: #FFFFFF;
    padding: 23px 30px;
    font-size: 13px;
    box-shadow: 0px 6px 49px 0px rgba(220, 220, 220, 0.9);
    border-radius: 15px;
}

.title {
    font-size: 20px;
    margin-top: 0;
}

.journey {
    display: flex;
    justify-content: space-between;
}

.column {
    text-align: center;
}

.header {
    color: #FFF;
    height: 38px;
    width: 137px;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    border: none;
}

.column:nth-of-type(1) .header {
    background: #006fff;
}

.column:nth-of-type(2) .header {
    background: #58BCC4;
}

.column:nth-of-type(3) .header {
    background: #4FD984;
}

.column:nth-of-type(4) .header {
    background: #DDBF44;
}

.column:nth-of-type(5) .header {
    background: #D76666;
}

.column:nth-of-type(6) .header {
    background: #317992;
}

.column:nth-of-type(7) .header {
    background: #215D7D;
}

.header:before {
    content: "";
    position: absolute;
    z-index: 9;
    top: 0;
    left: 100%;
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 16px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 18px solid #006fff;
}

.column:nth-of-type(1) .header:before {
    border-left: 18px solid #006fff;
}

.column:nth-of-type(2) .header:before {
    border-left: 18px solid #58BCC4;
}

.column:nth-of-type(3) .header:before {
    border-left: 18px solid #4FD984;
}

.column:nth-of-type(4) .header:before {
    border-left: 18px solid #DDBF44;
}

.column:nth-of-type(5) .header:before {
    border-left: 18px solid #D76666;
}

.column:nth-of-type(6) .header:before {
    border-left: 18px solid #317992;
}

.column:nth-of-type(7) .header:before {
    border-left: 18px solid #215d7d;
}

.header:after {
    content: "";
    position: absolute;
    z-index: 8;
    top: 0;
    left: -1px;
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 16px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 18px solid #FFF;
}

.column:nth-of-type(1) .header:after {
    /* display: none; */
}

.gaugebar {
    width: 137px;
}