.containerOutcome {
    position: relative;
    padding: 12px 14px;
    top: 100px;
    min-width: 180px;
    height: 250px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000001A;
    border-radius: 0px 15px 15px 0px;
    opacity: 1;
    z-index: -2;
}

.titleOutcome {
    margin-top: 0;
    font-weight: bold;
    font-size: 15px;
}

.matchingListSpan {
    display: block;
    margin-top: 8px;
    font-weight: bold;
}