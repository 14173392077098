.creation-feed-container {
    padding: 10px;
}

.creation-feed-container form {
    overflow: auto;
    max-height: 620px;
}

.creation-feed-container .input_select_container {
    width: 200px;
}

.creation-feed-container .input_select_container .select-component {
    height: 50px;
    border-radius: 7px
}

.creation-feed-container .input-custom-goupe {
    width: 550px;
    height: 86px;
    margin-bottom: 30px;
}

.creation-feed-container h3 {
    font-weight: bold;
    margin-left: 20px;
    font-size: 17px;
}

.creation-feed-custom-txt {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 25px;
}

.creation_feed_update_container {
    height: auto;
    max-height: 660px;
    overflow-y: auto;
    overflow-x: hidden;
}

.creation_feed_update_container h2 {
    font-size: 16px;
    margin-left: -2px;
    margin-bottom: 20px;
    font-weight: 500;
}

.creation_feed_update_container .input_select_container {
    flex-direction: row;
}

.creation_feed_form_container {
    width: 95%;
    border-bottom: 2px solid #EBEBEB;
    border-radius: 2px;
    margin: 0px auto 15px 15px;
    padding-bottom: 20px;
}

.creation_feed_form_container div .input_select_container {
    width: 400px;
    height: 50px;
    margin: 10px;
    justify-content: space-between;
}

.creation_feed_form_container div .select-component {
    min-width: 265px;
    border-radius: 8px;
}

.creation_feed_form_container label {
    font-size: 17px;
    font-weight: bold;
    margin: 10px 0px 0px 10px;
}

.creation-feed-container div {
    margin-bottom: 15px;
}

.creation_feed_update_container input {
    width: 70%;
    height: 55px;
}

.input-custom-goupe {
    height: 90px;
    margin-left: 20px;
}