.selectProductFeed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 5px;
    width: 100%;
}

.elementSelectProductFeed {
    width: 100%;
    margin-top: 10px;
}