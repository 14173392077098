.wrapper {
    width: 410px;
}
.section {
    padding: 10px 22px;
}
.header {
    composes: section;
    border-bottom: solid 1px var(--main-border-color);
    padding-bottom: 14px;
}
.title {
    font-size: 16px;
    font-weight: 500;
}
.scroll {
    overflow: auto;
    max-height: calc(95vh - 120px);
}

/* Notifs */
.notif_list {
    padding: 6px 10px 0;
}
.notif {
    position: relative;
    padding: 10px;
    margin: 4px 0;
    display: flex;
    align-items: flex-start;
    transition: background 200ms;
    border-radius: 5px;
}
.notif_unread,
.notif_read {
    composes: notif;
}
.notif_unread {
    background-color: var(--main-bg-color);
}

.notif_col_picto {
    flex: 0 0 auto;
    margin-right: 16px;
    margin-top: 2px;
}
.notif_col_content {
    flex: 1 1 auto;
    min-width: 0;
    align-self: center;
}
.notif_header {
    display: flex;
    align-items: center;
}
.notif_title {
    flex: 1 1 auto;
    font-weight: 500;
    font-size: 14px;
    color: var(--grey-0);
}
.notif_date {
    flex: 0 0 auto;
    margin-left: 10px;
    font-size: 12px;
    color: var(--grey-2);
}
.notif_body {
    margin-top: 2px;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
}
.notif_body_content {
    align-self: flex-start;
    flex: 1 1 auto;
    min-width: 0;
}
.notif_text {
    color: var(--grey-0);
    word-wrap: break-word;
}
.notif_actions {
    margin-top: 2px;
}
.notif_overlay_actions {
    flex: 0 0 auto;
    margin-left: 4px;
    margin-right: -6px;
    margin-bottom: -6px;
    opacity: 0;
    transition: opacity 200ms;
}
.notif:hover .notif_overlay_actions,
.notif:focus .notif_overlay_actions {
    opacity: 1;
}
