/* KPI List */
.article_list {
    display: flex;
    align-items: stretch;
    gap: 12px;
    margin-bottom: 12px;
}
.list_item {
    position: relative;
}
.list_item_s,
.list_item_m {
    composes: list_item;
}
.list_item_s {
    flex: 0 0 auto;
    width: 200px;
}
.list_item_m {
    flex: 0 0 auto;
    width: 300px;
}


/* KPI */
.kpi {

}
.kpi_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    color: var(--grey-3);
    margin-bottom: 4px;
}
.kpi_description {
    font-size: 12px;
    font-weight: normal;
}
.kpi_value {
    line-height: 1.1;
    margin-top: 8px;
    font-size: 26px;
    font-weight: 500;
    white-space: nowrap;
}
.kpi_size_s .kpi_value { font-size: 24px; }
.kpi_size_m .kpi_value { font-size: 26px; }
.kpi_rate {
    display: inline-block;
    border-radius: 30px;
    padding: 3px 8px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
    color: var(--grey-2);
}
.kpi_evolution_up {
    composes: kpi_rate;
    color: var(--color-success);
    background-color: var(--color-success-light);
}
.kpi_evolution_down {
    composes: kpi_rate;
    color: var(--color-alert);
    background-color: var(--color-alert-light);
}
