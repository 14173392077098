.section{
	composes: section from "../../WebAnalyticsContainer.module.css";
	padding:  12px 16px;
	min-height: 140px;
}
.sectionHeader {
	padding-left: 4px;
    margin-bottom: 10px;
    margin-top: 4px;
}
.sectionTitle {
	font-size: 16px;
	font-weight: 500;
	color: var(--grey-0);
}
