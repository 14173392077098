.simplify_title_create {
	margin-bottom: 0px;
}

.datePickerBOp {
	display: flex;
}

.datePickerBOp>div, .datePickerBOp>div>span {
	margin-right: 10px;
	display: inline-block;
}

.creationBusinessOp {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin: auto;
}

.label_list {
	display: flex;
}

.label_list ul {
	min-width: 300px;
}

.label_list ul li {
	background: #006fff;
	border-radius: 3px;
	padding: 7px 35px 7px 10px;
	color: #FFF;
	cursor: pointer;
	font-weight: 700;
	margin-bottom: 3px;
	position: relative;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.label_list li:hover {
	padding-left: 20px;
}

.label_list li:before {
	content: "\f021";
	position: absolute;
	font-family: IcoFont!important;
	right: 10px;
	transform-origin: 50% 50%;
	transform: scale(0);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.label_list li.label_active:before {
	transform: scale(1);
}

.lengthOfTitle {
	text-align: right;
	position: absolute;
	right: 0px;
	top: 77px;
}

textarea[readonly] {
	background-color: #F5F6F7;
	border-color: #C0C2D3 !important;
}

.label_list ul li.disabled {
	cursor: text;
	filter: grayscale(0.3);
	opacity: .6;
}