.ReactCrop {
	flex: 0 1 auto;
	max-width: 100%;
	max-height: 100%;
	display: flex;
	margin: auto;

	background-color: #CACCD8;
	background-image: linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%), linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%);
	background-size: 16px 16px;
	background-position: 0 0, 8px 8px;
}
.ReactCrop__child-wrapper {

}
.ReactCrop__child-wrapper img{
	max-width: 100%;
	max-height: 100%;
}
