
.simplify_title{
    font-size: 25px;
    font-weight: 500;
    padding: 6px 0 20px;
}
.simplify_title_2{
    font-size: 18px;
    font-weight: 400;
}
.simplify_list_fromto{
    height: 38px;
    min-height: 38px;
    margin-right: 12px;
}



.splanning {
    position: relative;
    background-color: var(--white);
}
.splanning::after{
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: solid 1px #E2E4EA;
    border-width: 0 1px 1px 1px;
}

.splanning_header {
    position: relative;
}
.splanning_body{
    position: relative;
    overflow: hidden;
}
.splanning_body_scroll{
    height: 100%;
}

/* Header */

.splanning_header {
    border-bottom: solid 1px #E2E4EA;
}

.splanning_header_row {
    position: relative;
    display: flex;
    border-top: solid 1px #E2E4EA;
}

.splanning_header_row .splanning_header_cell {
    flex: 1 1 100%;
    border-left: 1px solid #E2E4EA;
    text-align: center;
    padding: 6px 0;
    font-size: 13px;
    font-weight: 500;
    color: #757B96;
    text-transform: uppercase;
    line-height: 1;
}

.splanning_header_row.s .splanning_header_cell {
    padding: 5px 0;
    font-size: 12px;
    font-weight: 400;
    color: #8A8EA5;
}

.splanning_header_row .splanning_header_cell.is_today {
    background: #435BE7;
    border-color: #435BE7;
    color: #fff;
    font-weight: 700;
}
.splanning_header_row .splanning_header_cell.is_past {
    background: #F4F4F4;
}

/* Grid */
.splanning_grid {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}

.splanning_grid_col {
    flex: 1 1 100%;
    border-left: 1px solid #F2F2F5;
}
.splanning_grid_col.is_today {
    background: #F6F7FE;
}

/* Operations */
.splanning_operations_list {
    padding-bottom: 10px;
}

.splanning_operation {
    position: relative;
    height: 34px;
}

.splanning_operation_inner {
    position: absolute;
    left: 0;
    top: 10px;
    height: 24px;
    width: 40px;
    cursor: pointer;
    border-radius: 26px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    color: #000;
}
.splanning_operation_inner.ongoing {
    background-color: #B9EDB3;
}
.splanning_operation_inner.stopped {
    background-color: #FAC4C4;
}
.splanning_operation_inner.planned {
    background-color: #FAE8A8;
}
.splanning_operation_inner.past {
    background-color: #ECECEF;
}
.splanning_operation_inner.testing {
    background-color: #ADCBFC;
    background-image: repeating-linear-gradient(
        -45deg,
        rgba(255,255,255,.0),
        rgba(255,255,255,.0) 3px,
        rgba(255,255,255,.2) 3px,
        rgba(255,255,255,.2) 6px
    );
}

.splanning_operation_inner.is_trunc_left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.splanning_operation_inner.is_trunc_right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.splanning_operation_name {
    flex: 0 1 auto;
    font-size: 14px;
    line-height: 34px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.splanning_operation_status {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 8px;
}
