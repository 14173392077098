.containerNav {
    margin-bottom: 49px;
}

.container {
    display: flex;
    flex-direction: row;
    font-weight: normal;
    position: relative; 
}

.label {
    text-align: left;
    height: 43px;
    margin: 0 42px 0 0;
    font-size: 16px;
    color: #4C5269;
}

.labelContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.indicator {
    height: 10px;
    background-color: var(--turquoise);
}

.activeLabel {
    color: var(--turquoise);
}

.separator {
    display: block;
    height: 1px;
    background-color: var(--main-border-color);
}

.datePicker {
    top: 46px;
    position: absolute;
}