/* LIST */
.format_param_list{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 14px 0;
    padding: 0 15px;
}

/* GROUP */
.format_param_group{
    flex: 1 1 100%;
	width: 100%;
    border-top: solid 1px var(--main-border-color);
    border-bottom: solid 1px var(--main-border-color);
    padding: 6px 0;
    transition: padding 300ms ease-in-out, background 300ms ease-in-out;
}
.format_param_list .format_param_group{
	margin-left: -15px;
    margin-right: -15px;
}
.format_param_group.folded{
    padding: 0;
}
.format_param_group:first-child{
    border-top: none;
}
.format_param_group.no_border {
	border-top: none;
	border-bottom: none;
}
.format_param_group + .format_param_group{
    margin-top: -1px;
}
.format_param + .format_param_group,
.format_param_break + .format_param_group{
    margin-top: 14px;
}
.format_param_group.has_hover:hover,
.format_param_group.has_hover:focus {
	background-color: var(--main-bg-color);
}


/* GROUP NAME */
.format_param_group_name{
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-0);
    padding: 17px 22px;
    line-height: 18px;
}
.format_param_group.clickable .format_param_group_name{
    transition: background 200ms;
    cursor: pointer;
}
.format_param_group_name_tooltip {
    display: inline-block;
    vertical-align: baseline;
    margin: -8px 0;
}

/* GROUP LIST */
.format_param_group .format_param_list{
    margin-top: 0;
    transition: all 300ms ease-in-out;
}
.format_param_group.folded .format_param_list{
    height: 0;
    margin: 0;
    overflow: hidden;
}
.format_param_list.no_margin {
	margin: 0;
}

/* GROUP ARROW */
.format_param_group_arrow{
    color: #CBCDD5;
    font-size: 14px;
    margin-left: 16px;
    margin-right: -2px;
    transition: all 200ms;
}
.format_param_group_name:hover .format_param_group_arrow{
    color: inherit;
}
.format_param_group.folded .format_param_group_fold_arrow{
    transform: rotateZ(180deg);
}

/* GROUP SWITCH */
.format_param_group_name .cb_label{
    margin: 0;
    vertical-align: top;
}

/* GROUP ICON */
.format_param_group_icon{
    margin-right: 8px;
    color: var(--turquoise);
}

/* GROUP VALUE */
.format_param_group_value{
    font-size: 12px;
    color: var(--grey-2);
    font-weight: normal;
    margin-left: 10px;
    text-align: right;
}

/* ITEM */
.format_param{
    flex: 1 1 100%;
	width: calc(100% - 14px);
    margin: 10px 7px;
}
.format_param.w_l{
    flex: 0 1 calc(66.66% - 14px);
    width: calc(66.66% - 14px);
}
.format_param.w_m{
    flex: 0 1 calc(50% - 14px);
    width: calc(50% - 14px);
}
.format_param.w_s{
    flex: 0 1 calc(33.33% - 14px);
    width: calc(33.33% - 14px);
}
.format_param .form_block_label{
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-0);
    line-height: 1.3;
}
.format_param .form_block{
    margin-bottom: 0;
}
.format_param .form_block + .form_block{
    margin-top: 10px;
}
.format_param .form_block_description,
.format_param .custom_input_sub_desc{
    font-size: 13px;
    color: var(--grey-2);
}
.format_param_break{
    flex: 1 1 100%;
}

/* Label */
.format_param_label_device{
    font-size: 10px;
    color: var(--grey-3);
    margin-left: 3px;
}


/* Tiny mce */
.format_param .tox-tinymce{
    border-color: var(--main-border-color);
}
.format_param .tox .tox-toolbar{
    background: none;
    border-bottom: solid 1px var(--main-border-color);
}
.format_param .tox .tox-tbtn{
    width: 28px;
    height: 28px;
}
.format_param .tox .tox-tbtn--select{
    width: auto;
}
.format_param .tox .tox-tbtn svg{
    fill: var(--grey-1);
}
.format_param .tox .tox-edit-area__iframe{
    background: var(--input-bg);
}

/* CodeMirror */
.format_param .CodeMirror.CodeMirror-empty {
	color: var(--grey-3);
}

/* Tabs */
.format_param_list .tabs{
    font-size: 14px;
    color: var(--grey-0);
}

/* Breadcrumb */
.format_param_breadcrumb{
    font-size: 14px;
    color: var(--grey-0);
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: solid 1px var(--main-border-color);
}
.format_param_breadcrumb > li{
    display: inline-block;
    vertical-align: top;
}
.format_param_breadcrumb > li + li::before{
    content: "\f054";
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 14px;
    line-height: 14px;
    margin: 0 8px;
    font-size: 10px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-align: center;
    color: #ACAFC0;
}
.format_param_breadcrumb a{
	display: inline-block;
	vertical-align: middle;
    padding: 3px 5px;
    margin: 0 -5px;
    cursor: pointer;
    border-radius: 3px;
    transition: background 200ms;
}
.format_param_breadcrumb a.active{
    color: #006fff;
}
.format_param_breadcrumb a:hover,
.format_param_breadcrumb a:focus{
    background: #F5F6F8;
}

/* SEPARATOR */
.format_param_sep{
    border-top: solid 1px var(--main-border-color);
}

/* Thumbnail */
.format_param_thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    overflow: hidden;
	position: relative;
}
.format_param_thumbnail img,
.format_param_thumbnail video{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.format_param_thumbnail img{
	background-color: #CACCD8;
	background-image: linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%), linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%);
	background-size: 16px 16px;
	background-position: 0 0, 8px 8px;
}
.format_param_thumbnail video{
	background-color: var(--grey-0);
}
.format_param_thumbnail_video_icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: #fff;
	text-shadow: rgba(0,0,0,.3) 0 0 5px, rgba(0,0,0,.2) 0 0 12px;
	font-size: 16px;
}