.bodyContainer {
  background-color: #F5F6F8;
  overflow: auto;
}

.pageTitle{
  font-size: 25px;
  font-weight: 500;
  vertical-align: middle;
}

.mainSearchBar{
  display: flex;
}
.mainSearchBarInput{
  flex: 1 1 auto;
  width: 210px;
}
.mainSearchBarBtn{
  flex: 0 0 auto;
  margin-left: 8px;
}

.section{
  position: relative;
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  margin-bottom: 12px;
  background: #fff;
}


.headerContainer {
  background-color:#FFFFFF;
  margin-bottom: 17px;
  padding-bottom: 16px;
  border-bottom: solid 1px #E9EAEF;
}
.containerInsight {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.containerInsightSmall {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 2rem;
}

.insightHeaderContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -5px;
}
.bigContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.insightViewContainer {
  display: flex;
  flex-direction: column;
  width: 615px;
}
.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
}
.cardContainerColumns {
  composes: cardContainer;
  margin-left: -12px;
}

.chipsCardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


/* Breadcrumb */
.breadcrumb{
  display: flex;
  margin-top: 22px;
  margin-bottom: 12px;
  margin-left: 4px;
  color: var(--grey-0);
}
.breadcrumbItem{
  list-style: none;
  font-size: 18px;
  font-weight: 500;
}
.breadcrumbItem + .breadcrumbItem::before{
  content: "\f054";
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 14px;
  line-height: 14px;
  margin: 0 8px;
  font-size: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-align: center;
  color: var(--grey-4);
}
.breadcrumbLink{
  color: var(--turquoise);
  cursor: pointer;
}
.breadcrumbLink:hover{
  text-decoration: underline;
}
