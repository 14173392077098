.text {
	display: inline-block;
	cursor: pointer;
}
.tag {
    display: inline-block;
    vertical-align: baseline;
    background-color: var(--grey-9);
	border: solid 1px var(--grey-7);
    color: var(--grey-2);
    border-radius: 5px;
    line-height: 1.23;
    padding: 4px 6px;
    cursor: pointer;
    transition: background 200ms;
    margin-right: 8px;
    margin-top: 1px;
    margin-bottom: 1px;
}

.icon {
	margin-left: 2px;
}

.tooltip {
    position: fixed;
    z-index: 10000;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-success);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 30px;
    box-shadow: rgba(0,0,0,.1) 0 0 10px;

    animation: tooltip_show 150ms ease-out;
}
.tooltipHide {
    animation: tooltip_hide 150ms ease-out forwards;
}
@keyframes tooltip_show {
    0% {
        transform: translateX(-50%) translateY(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
}
@keyframes tooltip_hide {
    0% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(40px);
        opacity: 0;
    }
}