@value ellips from "../../../Components/utils.module.css";

.title {
    font-weight: 500;
}
.list {
    position: relative;
}
.list_has_before_shadow::before,
.list_has_after_shadow::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 16px;
    pointer-events: none;
}
.list_has_before_shadow::before {
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.list_has_after_shadow::after {
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.track {
    display: flex;
    overflow: auto;
    gap: 16px;
    margin-top: 12px;

    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.item_empty {
    flex: 0 0 auto;
    width: 320px;
}
.item {
    scroll-snap-align: start;
    flex: 0 0 auto;
    width: 320px;
    display: flex;
    align-items: center;
    border: solid 1px var(--main-border-color);
    background-color: var(--main-bg-color);
    border-radius: 8px;
    padding: 12px;
}
.item_col_img {
    flex: 0 0 auto;
    margin-right: 12px;
    width: 71px;
    height: 42px;
    position: relative;
}
.item_col_info {
    flex: 1 1 auto;
    min-width: 0;
}
.item_col_nb {
    flex: 0 0 auto;
    margin-left: 12px;
}


.item_col_img::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: solid 1px var(--black);
    border-radius: 4px;
    opacity: .06;
}
.item_img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}
.item_format {
    composes: ellips;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--grey-2);
}
.item_name {
    composes: ellips;
    margin-top: 2px;
}
.item_nb {
    font-size: 20px;
    font-weight: 500;
    background-color: var(--white);
    border: solid 1px var(--main-border-color);
    border-radius: 8px;
    padding: 3px 8px;
    min-width: 34px;
    text-align: center;
}
