:root{
	--btn-color-primary: var(--turquoise);
	--btn-color-secondary: var(--grey-1);
	--btn-color-alert: #F04F5B;
	--btn-color-warning: #F7A100;
	--btn-color-success: #43B55E;

	--btn-color-primary-hover: #0068EF;
	--btn-color-secondary-hover: #575A68;
	--btn-color-alert-hover: #E14A55;
	--btn-color-warning-hover: #E89700;
	--btn-color-success-hover: #3FAA58;

	--btn-color-primary-light: var(--turquoise-light);
	--btn-color-secondary-light: #E9EAF0;
	--btn-color-alert-light: #FDE2E4;
	--btn-color-warning-light: #FEEDCF;
	--btn-color-success-light: #E6F5E9;
}

.btnUi {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	line-height: 20px;
	min-height: 20px;
	max-width: 100%;
	padding: 8px 10px;
	border: solid 1px transparent;
	font-family: "Roboto", sans-serif;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	border-radius: 6px;
	cursor: pointer;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all 100ms ease-in-out;
	-moz-transition: all 100ms ease-in-out;
	-ms-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
}

.btnUi.w_full{
	display: flex;
	width: 100%;
}
.btnUi.h_full{
	height: 100%;
}

.btnUi.btn_strong{
	font-weight: 600;
}
.btnUi.btn_light{
	font-weight: 400;
}

.btnUi.btn_disabled,
.btnUi.btn_disabled:hover{
	cursor: not-allowed;
}

.btn_group,
.btn_group_s,
.btn_group_l,
.btn_group_xl,
.btn_wrapper{
	display: inline-block;
}

.btnUi + .btnUi,
.btn_switch + .btnUi{
	margin-left: 8px;
}

.btn_group .btnUi:not(:first-child),
.btn_group .btn_switch:not(:first-child),
.btn_group .btn_wrapper:not(:first-child),
.btn_group button:not(:first-child){
	margin-left: 8px;
}
.btn_group_s .btnUi:not(:first-child),
.btn_group_s .btn_switch:not(:first-child),
.btn_group_s .btn_wrapper:not(:first-child),
.btn_group_s button:not(:first-child){
	margin-left: 6px;
}
.btn_group_l .btnUi:not(:first-child),
.btn_group_l .btn_switch:not(:first-child),
.btn_group_l .btn_wrapper:not(:first-child),
.btn_group_l button:not(:first-child){
	margin-left: 12px;
}
.btn_group_xl .btnUi:not(:first-child),
.btn_group_xl .btn_switch:not(:first-child),
.btn_group_xl .btn_wrapper:not(:first-child),
.btn_group_xl button:not(:first-child){
	margin-left: 16px;
}

.btn_switch {
	display: inline-flex;
	vertical-align: middle;
}
.btn_switch .btnUi {
	margin-left: 0 !important;
}
.btn_switch > .btnUi:not(:first-child),
.btn_switch > *:not(:first-child) .btnUi {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.btn_switch > *:not(:first-child){
	margin-left: -1px !important;
}
.btn_switch > .btnUi:not(:last-child),
.btn_switch > *:not(:last-child) .btnUi {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.btn_switch .btnUi.active{
	position: relative;
	z-index: 1;
}
.btn_switch .btnUi .btn_txt{
	min-width: unset !important;
}
.btn_switch.w_full{
	width: 100%;
}
.btn_switch.w_full .btnUi{
	width: 100%;
	flex: 1 1 auto;
	white-space: nowrap;
}
.btnUi.do_input_hug {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	margin-left: -1px;
	position: relative;
}

.btn_txt {
	margin: 0 4px;
	flex: 0 1 auto;
}
.btnUi:not(.has_icon) .btn_txt{
	min-width: 44px;
}

.btnUi.btn_no_min_width .btn_txt{
	min-width: unset;
}

/* Loading */
.btn_first_icon,
.btn_last_icon,
.btn_txt {
	transition: opacity 200ms;
}
.btn_loading .btn_first_icon,
.btn_loading .btn_last_icon,
.btn_loading .btn_txt {
	visibility: hidden;
	opacity: 0;
}
.btn_loading .btn_spinner {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Icon */
.btnUi .fas,
.btnUi .fa {
	margin: 0 4px;
	flex: 0 0 auto;
	font-size: 16px;
	line-height: 20px;
}
.btnUi.btn_s .fas,
.btnUi.btn_s .fa {
	font-size: 14px;
	margin: 0 2px;
}
.btnUi .fa-sm{
	font-size: 14px;
	margin: 0 2px;
}

/* Rounded */
.btn_rounded{
	border-radius: 100px;
}

/* Size */
.btn_xxs {
	padding: 3px 8px;
	font-size: 13px;
}

.btn_xs {
	padding: 5px 8px;
	font-size: 13px;
}

.btn_s {
	padding: 6px 10px;
	font-size: 14px;
}

.btn_m {
	padding: 8px 10px;
}

.btn_l {
	padding: 12px 14px;
}

.btn_xl {
	padding: 16px 20px;
	font-size: 16px;
}

.btn_xxl {
	padding: 18px 28px;
	font-size: 18px;
}

.btn_w_l {
	padding-left: 14px;
	padding-right: 14px;
}
.btn_w_s {
	padding-left: 10px;
	padding-right: 10px;
}
.btn_w_xs {
	padding-left: 8px;
	padding-right: 8px;
}
.btn_w_xxs {
	padding-left: 6px;
	padding-right: 6px;
}

/* Sub btn */
.btn_sub{
	padding-left: 2px;
	padding-right: 2px;
}
.btn_sub .fas,
.btn_sub .fa{
	font-size: 12px;
	margin: 0 2px;
}

/* Font size */
.btn_fs_l{
	font-size: 16px;
}
.btn_fs_m{
	font-size: 15px;
}
.btn_fs_s{
	font-size: 14px;
}

/* Text align */
.btn_align_left{
	text-align: left;
}





/*****************/
/* Default style */
/*****************/

.btn_primary{
	color: #fff;
	background: var(--btn-color-primary);
}
.btn_primary:hover,
.btn_primary:focus{
	background: var(--btn-color-primary-hover);
}

.btn_secondary {
	color: #fff;
	background: var(--btn-color-secondary);
}
.btn_secondary:hover,
.btn_secondary:focus {
	background: var(--btn-color-secondary-hover);
}

.btn_alert {
	color: #fff;
	background: var(--btn-color-alert);
}
.btn_alert:hover,
.btn_alert:focus {
	background: var(--btn-color-alert-hover);
}

.btn_warning{
	color: #fff;
	background: var(--btn-color-warning);
}
.btn_warning:hover,
.btn_warning:focus{
	background: var(--btn-color-warning-hover);
}

.btn_success {
	color: #fff;
	background: var(--btn-color-success);
}
.btn_success:hover,
.btn_success:focus{
	background: var(--btn-color-success-hover);
}

.btn_primary.btn_disabled,
.btn_secondary.btn_disabled,
.btn_alert.btn_disabled,
.btn_warning.btn_disabled,
.btn_success.btn_disabled,
.btn_primary.btn_disabled:hover,
.btn_alert.btn_disabled:hover,
.btn_warning.btn_disabled:hover,
.btn_success.btn_disabled:hover{
	background: #E2E3EA;
}




/*****************/
/* Reverse style */
/*****************/

.btn_reverse_primary{
	color: var(--turquoise);
	background: var(--turquoise-light);
}
.btn_reverse_primary:hover,
.btn_reverse_primary:focus{
	border-color: currentColor;
}

.btn_reverse_secondary {
	color: var(--btn-color-secondary);
	background: var(--btn-color-secondary-light);
}
.btn_reverse_secondary:hover,
.btn_reverse_secondary:focus {
	border-color: currentColor;
}

.btn_reverse_alert {
	color: var(--btn-color-alert);
	background:  var(--btn-color-alert-light);
}
.btn_reverse_alert:hover,
.btn_reverse_alert:focus {
	border-color: currentColor;
}

.btn_reverse_warning{
	color: var(--btn-color-warning);
	background: var(--btn-color-warning-light);
}
.btn_reverse_warning:hover,
.btn_reverse_warning:focus{
	border-color: currentColor;
}

.btn_reverse_success {
	color: var(--btn-color-success);
	background: var(--btn-color-success-light);
}
.btn_reverse_success:hover,
.btn_reverse_success:focus{
	border-color: currentColor;
}

.btn_reverse_primary.btn_disabled,
.btn_reverse_secondary.btn_disabled,
.btn_reverse_alert.btn_disabled,
.btn_reverse_warning.btn_disabled,
.btn_reverse_success.btn_disabled,
.btn_reverse_primary.btn_disabled:hover,
.btn_reverse_secondary.btn_disabled:hover,
.btn_reverse_alert.btn_disabled:hover,
.btn_reverse_warning.btn_disabled:hover,
.btn_reverse_success.btn_disabled:hover{
	background: #F7F8FA;
	color: #CAD0DB;
	border-color: transparent;
}


/*****************/
/* Outline style */
/*****************/

.btn_outline_primary{
	color: var(--btn-color-primary);
	border-color: currentColor;
	background: none;
}
.btn_outline_primary:hover,
.btn_outline_primary:focus{
	background-color: var(--btn-color-primary-light);
}

.btn_outline_secondary {
	color: var(--btn-color-secondary);
	border-color: #CAD0DB;
	background: #fff;
}
.btn_outline_secondary:hover,
.btn_outline_secondary:focus{
	background: #F7F7FA;
}
.btn_outline_secondary.active,
.btn_outline_secondary.active:hover{
	color: var(--btn-color-primary);
	border-color: currentColor;
	background-color: var(--btn-color-primary-light);
}

.btn_outline_alert {
	color: var(--btn-color-alert);
	border-color: currentColor;
	background: none;
}
.btn_outline_alert:hover,
.btn_outline_alert:focus{
	background-color: var(--btn-color-alert-light);
}

.btn_outline_warning{
	color: var(--btn-color-warning);
	border-color: currentColor;
	background: none;
}
.btn_outline_warning:hover,
.btn_outline_warning:focus{
	background-color: var(--btn-color-warning-light);
}

.btn_outline_success {
	color: var(--btn-color-success);
	border-color: currentColor;
	background: none;
}
.btn_outline_success:hover,
.btn_outline_success:focus{
	background-color: var(--btn-color-success-light);
}

.btn_outline_primary.btn_disabled,
.btn_outline_secondary.btn_disabled,
.btn_outline_alert.btn_disabled,
.btn_outline_warning.btn_disabled,
.btn_outline_success.btn_disabled,
.btn_outline_primary.btn_disabled:hover,
.btn_outline_secondary.btn_disabled:hover,
.btn_outline_alert.btn_disabled:hover,
.btn_outline_warning.btn_disabled:hover,
.btn_outline_success.btn_disabled:hover{
	background: none;
	color: #DFE3EA;
	border-color: currentColor;
}


/*****************/
/* Reverse outline style */
/*****************/

.btn_reverse_outline_primary{
	color: var(--btn-color-primary);
	background: var(--btn-color-primary-light);
	border-color: currentColor;
}
.btn_reverse_outline_primary:hover,
.btn_reverse_outline_primary:focus{
	border-color: var(--btn-color-primary-hover);
	background-color: var(--white);
}

.btn_reverse_outline_secondary {
	color: var(--btn-color-secondary);
	background: var(--btn-color-secondary-light);
	border-color: #CAD0DB;
}
.btn_reverse_outline_secondary:hover,
.btn_reverse_outline_secondary:focus {
	border-color: #afb6c3;
	background-color: var(--white);
}

.btn_reverse_outline_alert {
	color: var(--btn-color-alert);
	background:  var(--btn-color-alert-light);
	border-color: currentColor;
}
.btn_reverse_outline_alert:hover,
.btn_reverse_outline_alert:focus {
	border-color: var(--btn-color-alert-hover);;
	background-color: var(--white);
}

.btn_reverse_outline_warning{
	color: var(--btn-color-warning);
	background: var(--btn-color-warning-light);
	border-color: currentColor;
}
.btn_reverse_outline_warning:hover,
.btn_reverse_outline_warning:focus{
	border-color: var(--btn-color-warning-hover);
	background-color: var(--white);
}

.btn_reverse_outline_success {
	color: var(--btn-color-success);
	background: var(--btn-color-success-light);
	border-color: currentColor;
}
.btn_reverse_outline_success:hover,
.btn_reverse_outline_success:focus{
	border-color: var(--btn-color-success-hover);
	background-color: var(--white);
}

.btn_reverse_outline_primary.btn_disabled,
.btn_reverse_outline_secondary.btn_disabled,
.btn_reverse_outline_alert.btn_disabled,
.btn_reverse_outline_warning.btn_disabled,
.btn_reverse_outline_success.btn_disabled,
.btn_reverse_outline_primary.btn_disabled:hover,
.btn_reverse_outline_secondary.btn_disabled:hover,
.btn_reverse_outline_alert.btn_disabled:hover,
.btn_reverse_outline_warning.btn_disabled:hover,
.btn_reverse_outline_success.btn_disabled:hover{
	background: #F7F8FA;
	color: #CAD0DB;
	border-color: currentColor;
}


/*****************/
/* Ghost style */
/*****************/

.btn_ghost_primary{
	color: var(--btn-color-primary);
	background: none;
}
.btn_ghost_primary:hover{
	background-color: var(--btn-color-primary-light);
}

.btn_ghost_secondary {
	color: var(--btn-color-secondary);
	background: none;
}
.btn_ghost_secondary:hover,
.btn_ghost_secondary:focus{
	background: #F7F7FA;
}

.btn_ghost_grey {
	color: var(--grey-3);
	background: none;
}
.btn_ghost_grey:hover,
.btn_ghost_grey:focus{
	background: #F7F7FA;
}

.btn_ghost_alert {
	color: var(--btn-color-alert);
	background: none;
}
.btn_ghost_alert:hover,
.btn_ghost_alert:focus{
	background-color: var(--btn-color-alert-light);
}

.btn_ghost_warning{
	color: var(--btn-color-warning);
	background: none;
}
.btn_ghost_warning:hover,
.btn_ghost_warning:focus{
	background-color: var(--btn-color-warning-light);
}

.btn_ghost_success {
	color: var(--btn-color-success);
	background: none;
}
.btn_ghost_success:hover,
.btn_ghost_success:focus{
	background-color: var(--btn-color-success-light);
}

.btn_ghost_primary.btn_disabled,
.btn_ghost_secondary.btn_disabled,
.btn_ghost_grey.btn_disabled,
.btn_ghost_alert.btn_disabled,
.btn_ghost_warning.btn_disabled,
.btn_ghost_success.btn_disabled,
.btn_ghost_primary.btn_disabled:hover,
.btn_ghost_secondary.btn_disabled:hover,
.btn_ghost_alert.btn_disabled:hover,
.btn_ghost_warning.btn_disabled:hover,
.btn_ghost_success.btn_disabled:hover{
	background: none;
	color: #CAD0DB;
}



.btn_ghost_black {
	color: var(--grey-0);
	background: none;
	border-color: transparent;
}
.btn_ghost_black:hover{
	background-color: #CAD0DB11;
}
.btn_ghost_black:active{
	background-color: #CAD0DB22;
}
.btn_ghost_black.btn_disabled{
	color: #5D637E44;
}

.btn_ghost_black.btn_reset_margin{
	left: -10px;
}



/**********************/
/* Ghost+outline style */
/**********************/

.btn_ghost_outline_primary{
	color: var(--btn-color-primary);
	background: none;
}
.btn_ghost_outline_primary:hover,
.btn_ghost_outline_primary:focus{
	background-color: var(--btn-color-primary-light);
	border-color: currentColor;
}

.btn_ghost_outline_secondary {
	color: var(--btn-color-secondary);
	background: none;
}
.btn_ghost_outline_secondary:hover,
.btn_ghost_outline_secondary:focus{
	background: #F7F7FA;
	border-color: currentColor;
	border-color: #CAD0DB;
}

.btn_ghost_outline_alert {
	color: var(--btn-color-alert);
	background: none;
}
.btn_ghost_outline_alert:hover,
.btn_ghost_outline_alert:focus{
	background-color: var(--btn-color-alert-light);
	border-color: currentColor;
}

.btn_ghost_outline_warning{
	color: var(--btn-color-warning);
	background: none;
}
.btn_ghost_outline_warning:hover,
.btn_ghost_outline_warning:focus{
	background-color: var(--btn-color-warning-light);
	border-color: currentColor;
}

.btn_ghost_outline_success {
	color: var(--btn-color-success);
	background: none;
}
.btn_ghost_outline_success:hover,
.btn_ghost_outline_success:focus{
	background-color: var(--btn-color-success-light);
	border-color: currentColor;
}

.btn_ghost_outline_primary.btn_disabled,
.btn_ghost_outline_secondary.btn_disabled,
.btn_ghost_outline_alert.btn_disabled,
.btn_ghost_outline_warning.btn_disabled,
.btn_ghost_outline_success.btn_disabled,
.btn_ghost_outline_primary.btn_disabled:hover,
.btn_ghost_outline_secondary.btn_disabled:hover,
.btn_ghost_outline_alert.btn_disabled:hover,
.btn_ghost_outline_warning.btn_disabled:hover,
.btn_ghost_outline_success.btn_disabled:hover{
	background: none;
	color: #CAD0DB;
	border-color: transparent;
}


/*****************/
/* Link style */
/*****************/

.btn_link_primary,
.btn_link_secondary,
.btn_link_grey,
.btn_link_alert,
.btn_link_warning,
.btn_link_success{
	padding: 0;
	background: none;
}
.btn_link_primary .btn_txt,
.btn_link_secondary .btn_txt,
.btn_link_grey .btn_txt,
.btn_link_alert .btn_txt,
.btn_link_warning .btn_txt,
.btn_link_success .btn_txt{
	min-width: 0 !important;
	margin: 0 !important;
}
.btn_link_primary:hover .btn_txt,
.btn_link_secondary:hover .btn_txt,
.btn_link_grey:hover .btn_txt,
.btn_link_alert:hover .btn_txt,
.btn_link_warning:hover .btn_txt,
.btn_link_success:hover .btn_txt,
.btn_link_primary:focus .btn_txt,
.btn_link_secondary:focus .btn_txt,
.btn_link_grey:focus .btn_txt,
.btn_link_alert:focus .btn_txt,
.btn_link_warning:focus .btn_txt,
.btn_link_success:focus .btn_txt{
	text-decoration: underline;
}
.btn_link_primary .fas,
.btn_link_secondary .fas,
.btn_link_grey .fas,
.btn_link_alert .fas,
.btn_link_warning .fas,
.btn_link_success .fas,
.btn_link_primary .fa,
.btn_link_secondary .fa,
.btn_link_grey .fa,
.btn_link_alert .fa,
.btn_link_warning .fa,
.btn_link_success .fa{
	font-size: inherit !important;
	margin: 0 !important;
}
.btn_link_primary .btn_first_icon,
.btn_link_secondary .btn_first_icon,
.btn_link_grey .btn_first_icon,
.btn_link_alert .btn_first_icon,
.btn_link_warning .btn_first_icon,
.btn_link_success .btn_first_icon{
	margin-right: 4px !important;
}
.btn_link_primary .btn_last_icon,
.btn_link_secondary .btn_last_icon,
.btn_link_grey .btn_last_icon,
.btn_link_alert .btn_last_icon,
.btn_link_warning .btn_last_icon,
.btn_link_success .btn_last_icon{
	margin-left: 4px !important;
}

.btn_link_primary{
	color: var(--btn-color-primary);
}

.btn_link_secondary {
	color: var(--btn-color-secondary);
}

.btn_link_grey {
	color: var(--grey-3);
}

.btn_link_alert {
	color: var(--btn-color-alert);
}

.btn_link_warning{
	color: var(--btn-color-warning);
}

.btn_link_success {
	color: var(--btn-color-success);
}

.btn_link_primary.btn_disabled,
.btn_link_secondary.btn_disabled,
.btn_link_grey.btn_disabled,
.btn_link_alert.btn_disabled,
.btn_link_warning.btn_disabled,
.btn_link_success.btn_disabled,
.btn_link_primary.btn_disabled:hover,
.btn_link_secondary.btn_disabled:hover,
.btn_link_grey.btn_disabled:hover,
.btn_link_alert.btn_disabled:hover,
.btn_link_warning.btn_disabled:hover,
.btn_link_success.btn_disabled:hover{
	background: none;
	color: #CAD0DB;
}


/*****************/
/*     EXTRA     */
/*****************/


/* ARROW */
.btnUi.has_arrow{
	text-align: left;
}


/* TAB BTN */
.btnUi.tab_btn {
	width: 22px;
	height: 22px;
	padding: 0;
	border-radius: 22px;
	margin-left: 10px;
}
.btnUi.tab_btn .fa,
.btnUi.tab_btn .fas{
	font-size: 11px;
}



/* ICON BUTTON */
.icon_btn{
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
	padding: 4px;
	font-size: 20px;
	color: var(--grey-3);
	cursor: pointer;
	margin: 0 4px;
	transition: all 200ms;
	border-radius: 40px;
}
.icon_btn:focus,
.icon_btn:hover{
	color: var(--turquoise);
}
a:hover .icon_btn,
a:focus .icon_btn{
	color: inherit;
}
.icon_btn_color{
	color: var(--turquoise);	
}
.icon_btn_light{
	color: var(--grey-4);
}
.icon_btn_white{
	color: #fff;	
}

.icon_btn_black{
	color: #000;
	opacity: .65;
}
.icon_btn_black:hover,
.icon_btn_black:focus{
	color: #000;
	opacity: 1;
}

.icon_btn.m{
	font-size: 17px;
}
.icon_btn.s{
	font-size: 16px;
	margin: 0 2px;
}
.icon_btn.xs{
	font-size: 14px;
	margin: 0 2px;
}
.icon_btn.xs{
	font-size: 14px;
	margin: 0 2px;
}
.icon_btn.xxs{
	font-size: 12px;
	margin: 0 1px;
}

/* SVG */
.btnUi svg.btn_svg_stroke g {
	stroke: currentColor;
}
