.modal-overlay-businessOp {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-businessOp {
    position: fixed;
    z-index: 10000;
    left: 0;
    right: 0;
    width: 100%;
}

.modal-body-businessOp {
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 32px);
    max-height: calc(90% - 32px);
    max-width: 80%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    border: 5px solid #fff;
    border-radius: 4px;
}

.modal-body-businessOp a {
    position: absolute;
    display: block;
    color: black;
    text-decoration: none;
    line-height: 36px;
    font-size: 30px;
    font-weight: lighter;
    background: #fff;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    text-align: center;
}

.modal-close-businessOp {
	z-index: 9999;
	position: fixed;
	top: 0;
	right: 0;
	cursor: pointer;
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease;
	text-transform: uppercase;
	opacity: .8;
	width: 50px;
	height: 50px;
}
.modal-close-businessOp:before,
.modal-close-businessOp:after {
	content: "";
	top: 50%;
	left: 50%;
	position: absolute;
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease;
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	-moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	-o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
	border-radius: 3px;
	background: #FFF;
}
.modal-close-businessOp:before {
	width: 32px;
	height: 3px;
}
.modal-close-businessOp:after {
	width: 3px;
	height: 32px;
}
.modal-close-businessOp:hover {
	opacity: 1;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.modal-nav {
    position: fixed;
    top: calc(50% - 32px);
    height: 50px;
    line-height: 40px;
    font-size: 60px;
    z-index: 1000;
    color: white;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
.modal-next-businessOp {
    right: 10px;
    border-radius: 5px 0 0 5px;
}

.modal-prev-businessOp {
    left: 10px;
}

.modal-info-businessOp{
    position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background: #FFF;
	width: 80%;
	text-align: center;
	border-radius: 5px 5px 0 0;
	padding: 10px 0;
}
.modal-info-businessOp p{
    font-weight: 600;
}