.account_nav_tooling {
    top: 70px;
    right: 20px;
    padding: 0px 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 42px;
}

.account_nav_tooling ul {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.account_nav_tooling ul li {
    display: flex;
    justify-content: space-around;
    padding: 0px 10px;
    margin-right: 10px;
    background: #FFF;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #9E9E9E;
    cursor: pointer;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.account_nav_tooling_last-item {
    margin-left: auto;
}
.account_nav_tooling_last-item a{
    display: inline-block;
    color: #006fff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    padding: 4px;
    margin-top: -6px;
}

.account_nav_tooling ul li i {
    font-size: 18px;
    margin-top: 11px;
}

.account_nav_tooling ul li p {
    margin-left: 5px;
}

.account_nav_tooling ul li:hover {
    border-color: #006fff;
}

.account_nav_tooling ul li.active {
    border-color: #006fff;
    color: #006fff;
}

.container-account-tooling {
    margin-top: 15px;
}

.tooling_message_empty {
    margin-top: 30%;
    text-align: center;
}

.config_form_container h2 {
    margin-top: 20px;
    font-weight: bold;
}

.information_error_message {
    color: crimson;
}

.select-provider {
    width: 422px;
    height: 40px;
    border: 1px solid #9e9e9e;
    background: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    padding-left: 5px;
}

.information_not_good {
    border: 1px solid red !important;
}

.config_container_send {
    width: 50%;
    margin: 7px auto;
}

.edit_form-config:hover {
    color: #006fff;
    cursor: pointer;
}

.config_header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 80px;
}

.config_header div:nth-child(1) {
    width: 40%;
    text-align: left;
}

.config_header h1 {
    margin: 0;
}

.config_footer {
    display: flex;
    justify-content: space-evenly;
    margin: 5px auto;
}

.config_input_copy {
    margin: auto;
}