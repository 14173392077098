.simplify_target_container {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.simplify_target_main_container {
    display: flex;
    height: 730px;
}

.simplify_target_container h1 {
    margin-bottom: 0px;
}

.simplify_target_type_selection {
    width: 100%;
    height: 700px;
    overflow: auto;
    margin: 10px auto auto 30px;
}

.target_next_btn {
    align-self: flex-end;
}