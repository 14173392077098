.wrapper {
    height: 100%;
}
.content {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 7px;
    overflow: hidden;
    height: 100%;
}
.titleRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
}
.titleRow>.title {
    margin: 14px 24px;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0;
    color: #444444;
    display: flex;
    align-items: center;
}
.nameEditor {
    margin: 10px 20px;
    padding: 0px;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0;
    color: #444444;
}
.edit_name {
    font-size: 10px;
    width: 25px;
    height: 25px;
    padding: 0;
    margin-left: 5px;
    color: #9E9E9E;
    transition: all .2s ease-in-out;
}
.edit_name:hover {
    color: #006fff;
}
.weight {
    margin: 0px;
    padding: 0px;
    border-left: 1px solid #E4E4E4;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.weight>ul {
    margin: 0px;
    height: 100%;
}
.weight>ul>li {
    padding: 0px 5px;
    font-size: 10px;
    letter-spacing: 0;
    height: 33.333%;
    display: flex;
    align-items: center;
}
.weightSelected {
    color: #FFFFFF;
}
.weight>ul>li:nth-of-type(1).weightSelected {
    background: #d76666;
}
.weight>ul>li:nth-of-type(2).weightSelected {
    background: #f0ad4e;
}
.weight>ul>li:nth-of-type(3).weightSelected {
    background: #5cb85c;
}
.signalsList {
    margin: 24px 7px 24px 45px;
}
.signalsListItem {
    display: grid;
    flex-wrap: nowrap;
    grid-template-columns: auto 23px;
    margin-bottom: 10px;
    position: relative;
    padding-right: 9px;
}
.signalsListItem .union_line {
    position: absolute;
    height: calc(100% + 12px);
    background: #D6D6D6;
    width: 2px;
    left: -23px;
    top: 17px;
}
.signalsListItem .union_line:before,
.signalsListItem .union_line:after {
    position: absolute;
    content: "";
    width: 17px;
    height: 2px;
    background: #D6D6D6;
}
.signalsListItem .union_line:before {
    top: 0;
}
.signalsListItem .union_line:after {
    bottom: 0;
}
.signalsListItem .union_inner {
    position: absolute;
    left: -35px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    color: #D6D6D6;
    top: calc(50% + 14px);
    background: #f9f9f9;
}
.andOperator {
    display: flex;
    align-items: center;
}
.signalItem {
    vertical-align: middle;
}
.removeButton {
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-size: 23px;
    color: #E5E5E5;
    transition: all .2s ease-in-out;
}
.removeButton:hover {
    color: #d76666;
}
.addNewSelectWrapper {}
.addNewSelectWrapper {
    display: inline-block;
    position: relative;
}
.addNewSelectWrapper:before,
.addNewSelectWrapper:after {
    content: "";
    position: absolute;
    top: 50%;;
    z-index: 11;
    display: block;
    background: #444;
    height: 2px;
    width: 8px;
    border-radius: 2px;
}
.addNewSelectWrapper:before {
    right: 11px;
    transform: translateY(-50%) rotate(45deg);
}
.addNewSelectWrapper:after {
    right: 7px;
    transform: translateY(-50%) rotate(-45deg);
}
.addNewSelect {
    width: 250px;
    padding: 5px;
    font-size: 14px;
    color: #444444;
    border: none;
    border-radius: 5px;
    opacity: 1;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
}
.bottomButtonsRow {
    display: flex;
    justify-content: center;
    font-size: 11px;
    color: #b6b6b6;
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: -1;
    transition: all .2s ease-in-out;
}
.bottomButtonsRow > span {
    cursor: pointer;
    margin: 10px 12px;
    transition: all .2s ease-in-out;
}
.bottomButtonDelete:hover{
    color: #d76666;
}
.bottomButtonDuplicate:hover{
    color: #5cb85c;
}
.profileStats {
    margin-top: 8px;
}