/* LIST PAGE */
.orchestration_list{
	margin-left: -24px;
	margin-bottom: -24px;
}
.orchestration_list_item{
	margin: 0 0 24px 24px;
	flex: 0 0 auto;
	width: calc(33.33% - 24px);
	border: solid 1px var(--main-border-color);
	background-color: var(--white);
	border-radius: 8px;
	overflow: hidden;
	height: auto !important;
	box-shadow: rgba(0,0,0,.04) 0 2px 4px;
}

.orchestration_list_item_head{
	padding: 12px 16px;
	cursor: pointer;
	transition: all 200ms;
}
.orchestration_list_item_head:hover{
	background: #f5f6f8;
}

.orchestration_list_item_head .icon_btn{
	margin-right: -8px;
}

.orchestration_list_item_body{
	padding: 6px 16px;
}
.orchestration_list_item_footer{
	text-align: right;
	padding: 4px 16px 16px;
}

.orchestration_list_item_name{
	font-size: 16px;
	font-weight: 500;
}

.orchestration_picto{
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 32px;
	background: #006fff;
	color: #fff;
	font-size: 16px;
	text-align: center;
	margin-right: 12px;
}

.orchestration_list_item_footer button{
	font-size: 15px;
}
.orchestration_list_item_footer button .btn_txt{
	min-width: 0 !important;
}

/* GROUP */
.orchestration_group_settings{
	width: 285px;
	margin-right: 22px;
}
.orchestration_group_settings_frame{
	border: solid 1px var(--main-border-color);
	border-radius: 4px;
	background-color: var(--white);
	padding: 16px;
	margin-top: 10px;
	font-size: 14px;
}
.orchestration_group_settings_label{
	font-weight: 500;
	font-size: 14px;
	padding-bottom: 8px;
	border-bottom: solid 1px #E9EAEF;
	margin-bottom: 16px;
}
.orchestration_group_settings_line + .orchestration_group_settings_line{
	margin-top: 12px;
}

.orchestration_group_title{
	line-height: 32px;
	margin-right: 12px;
}
.orchestration_group_desc{
	max-width: 600px;
	margin-top: 2px;
}
.orchestration_group_link{
	display: inline-block;
	color: #006fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 13px;
	cursor: pointer;
}

/* Grid */
.orchestration_grid{
	margin-top: 20px;
}
.orchestration_grid_row{
    display: grid;
    grid-template-columns: 5.5fr 3.2fr 100px;
    border-bottom: solid 1px #eee;
    font-size: 15px;
    align-items: stretch;
}
.orchestration_grid_col{
    padding: 12px;
    display: flex;
    align-items: center;
}
.orchestration_grid_col:first-child{
    padding-left: 4px;
}
.orchestration_grid_col:last-child{
    padding-right: 4px;
}
.orchestration_grid_col_right{
	justify-content: flex-end;
}

/* Header */
.orchestration_grid_head_row{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    color: #5D637E;
}
.orchestration_grid_head_row .orchestration_grid_col{
    padding-top: 8px;
    padding-bottom: 8px;
}
.orchestration_grid_sort{
    cursor: pointer;
}
.orchestration_grid_sort:hover{
    color: #006fff;
}
.orchestration_grid_sort .fas{
    font-size: 12px;
    margin-left: 3px;
}

/* Body */
.orchestration_grid_body_row:hover,
.orchestration_grid_body_row[isdragging=y]{
    background: #F7F8FA;
}

/* Grid content */
.orchestration_grid_name{
    font-weight: 500;
    margin-left: 8px;
}
.orchestration_grid_email{
    font-size: 13px;
    margin-top: 2px;
}
