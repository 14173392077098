.labels {
    font-size: 16px;
    margin-top: 30px;
}
.tooltip {
    font-size: 11px;
    font-style: italic;
}
.input {
    border-radius: 5px;
    border: 1px solid #969696;
    width: 286px;
    height: 40px;
}
.typeSelection {
    font-size: 16px;
    border: 1px solid #707070;
    display: inline-block;
    padding: 6px 10px;
    margin-top: 10px;
    cursor: pointer;
    background: #FFF;
    transition: all .3s ease-in-out;
}
.typeSelection.numericSelected {
    color: #FFF;
    background: #006fff;
}
.actionButtons {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.actionButtons button {
    width: 48%;
    font-size: 16px;
}
.cancelButton {
    border: 1px solid #9E9E9E;
    color: #9E9E9E;
    border-radius: 5px;
    padding: 6px 25px;
    background: #FFF;
    transition: all .3s ease-in-out;
}
.cancelButton:hover {
    background: #f6f7f8;
}
.saveButton {
    background: var(--turquoise);
    border-radius: 5px;
    color: #FFF;
    padding: 7px 25px;
    transition: all .3s ease-in-out;
}
.saveButton:hover {
    background: var(--turquoise-dark);
}
.deleteLabel {
    font-size: 11px;
    text-align: center;
    margin-top: 17px;
    cursor: pointer;
    color: #9E9E9E;
    transition: all .3s ease-in-out;
}
.deleteLabel:hover {
    color: #293133;
}