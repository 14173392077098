.section_header {
    margin-bottom: 12px;
}
.section_part {
    margin-bottom: 12px;
}
.section_body {
    margin-bottom: 36px;
}

.date {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-2);
    display: block;
}
.date:first-letter {
    text-transform: uppercase;
}

/* Sumup */
.sumup_list {
    display: flex;
    align-items: center;
    gap: 12px;
}
.sumup_item {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding-right: 20px;
    max-width: 20%;
}
.sumup_item_large {
    composes: sumup_item;
    max-width: 22%;
}
.sumup_value {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.5;
    min-width: 50px;
    padding: 3px 8px;
    text-align: center;
    background-color: var(--main-bg-color);
    border-radius: 10px;
    margin-right: 10px;
}
.sumup_label {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-3);
}
.sumup_label span {
    display: block;
}

/* Campaigns */
.campaigns {
    display: flex;
    align-items: stretch;
    margin-top: 12px;
    gap: 12px;
}
.campaigns_col {
    flex: 1 1 50%;
    min-width: 0;
}
.campaigns_list {

}
.campaigns_item {

}

/* Bespokes  */
.bespokes {
    margin-top: 12px;
}

/* KPI List */
.list_wrapper {
    background: var(--white);
    border: solid 1px var(--main-border-color);
    border-radius: 6px;
    padding: 8px 0;
}
.list {
    display: flex;
    align-items: center;
    gap: 1%;
}
.list_sep {
    flex: 0 0 auto;
    height: 60px;
    border-left: solid 1px var(--main-border-color);
}
.list_sep:last-child {
    display: none;
}
.item {
    position: relative;
	padding: 11px 22px;
    /*text-align: center;*/
}
/* .item::before,
.item::after {
    content: "";
	display: block;
    margin-top: 16px;
} */

.item_s,
.item_m {
    composes: item;
}
.item_s {
    flex: 0 0 auto;
    width: calc(22% - 1px);
}
.item_m {
    flex: 0 0 auto;
    width: calc(28% - 1px);
}

/* Item content */
.item_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    color: var(--grey-3);
    margin-bottom: 4px;
}
.item_value {
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
}
.item_evolution {
    display: inline-block;
    border-radius: 30px;
    padding: 3px 8px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
}
.item_evolution_up {
    composes: item_evolution;
    color: var(--color-success);
    background-color: var(--color-success-light);
}

.item_evolution_down {
    composes: item_evolution;
    color: var(--color-alert);
    background-color: var(--color-alert-light);
}

/* Pie wrapper */
.pie_wrapper_outer {
    display: flex;
}
.pie_wrapper_inner {
    flex: 0 1 auto;
}

/* Tabs */
.tabs_border {
    border-bottom: solid 1px var(--main-border-color);
    margin-bottom: 26px;
}
.tabs {
    display: flex;
    gap: 50px;
}
.tab_common {
    padding-bottom: 18px;
    cursor: pointer;
    position: relative;
    min-width: 80px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.tab_common::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 3px;
    border-radius: 3px 3px 0 0;
    transition: background 200ms;
}
.tabs .item_value {
    font-size: 26px;
}
@media (max-width: 1439px) {
    .tabs {
        gap: 40px;
    }
    .tabs .item_title {
        font-size: 13px;
    }
    .tab_common {
        padding-bottom: 14px;
    }
}
@media (max-width: 1367px) {
    .tabs {
        gap: 35px;
    }
    .tabs .item_value {
        font-size: 24px;
    }
}

.tab {
    composes: tab_common;
}
.tab:hover::after,
.tab:focus::after {
    background-color: var(--main-border-color);
}

.tab_active {
    composes: tab_common;
}
.tab_active::after {
    background-color: var(--turquoise);
}
