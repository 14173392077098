.builder_sidebar .hide_trait {
	display: none;
}
.builder_sidebar .gjs-trt-traits {
	padding: 4px 16px;
	color: var(--grey-0);
	font-size: 14px;
}
.builder_sidebar .gjs-trt-trait {
	display: block;
	margin: 16px 0;
    padding: 0 6px;
	font-weight: normal;
}
.builder_sidebar .gjs-trt-trait .gjs-label-wrp {
	width: auto;
	min-width: auto;
}
.builder_sidebar .gjs-trt-trait .gjs-label {
	width: auto;
	min-width: auto;
	font-size: 13px;
    font-weight: 500;
    color: var(--grey-0);
    margin: 0 0 4px;
}

.builder_sidebar .gjs-trt-trait .gjs-field {
	width: auto;
}
.builder_sidebar .gjs-trt-trait .gjs-field.gjs-field-text {
	width: 100%;
}


/* Checkbox */
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox .gjs-label-wrp {
	flex: 1 1 auto;
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox .gjs-field-wrp {
	flex: 0 0 auto;
	margin-right: 8px;
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox .gjs-label {
	margin: 0;
	font-weight: 400;
	font-size: 14px;
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox .gjs-field-checkbox {
	display: block;
	height: auto;
	padding: 2px;
	margin: 0;
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox .gjs-chk-icon {
	all: revert;

	box-sizing: border-box;
	width: 16px;
	height: 16px;
	border: solid 2px var(--grey-4);
	border-radius: 3px;
	transition: all 200ms;

	flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox .gjs-field-checkbox:hover input:not(:checked) + .gjs-chk-icon {
	border-color: var(--grey-3);
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox input:checked + .gjs-chk-icon {
	background-color: var(--turquoise);
	border-color: var(--turquoise);
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox .gjs-field-checkbox:hover input:checked + .gjs-chk-icon {
	background-color: var(--turquoise-dark);
	border-color: var(--turquoise-dark);
}
.builder_sidebar .gjs-trt-trait.gjs-trt-trait--checkbox input:checked + .gjs-chk-icon::before {
    content: "";
    width: 9px;
    height: 5px;
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: #fff;
    transform: translateY(-1px) rotateZ(-45deg);
    transform-origin: center;
}