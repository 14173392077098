.tenant-select-container {
    display: flex;
    width: 95%;
    margin: auto;
    align-items: center;
    justify-content: space-between;
}

.tenant-select-container-tenant-select {
    display: flex;
    align-items: center;
}

.tenant-select-container-tenant-select select {
    border-radius: 6px;
}