.loaderOp {
    height: 100vh;
    z-index: 1000;
}

.simplify_creation_wrapper{
    padding: 30px 0 0 180px;
}

.simplify_subtitles {
    display: flex;
    margin: 10px auto;
}

.simplify_subtitles p {
    text-align: left;
    padding: 5px;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
    border-bottom: 1px solid #C3C3C3;
}

.simplify_video_full{
    width: 100%;
}