/* Sectors */
.builder_sidebar .gjs-sm-sectors {
	padding-top: 12px;
}
.builder_sidebar .gjs-sm-sector {
	border-bottom: solid 1px var(--main-border-color);
}
.builder_sidebar .gjs-sm-sector-title {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	font-size: 15px;
	font-weight: 500;
	color: var(--grey-0);
	padding: 14px 22px;
	line-height: 18px;
	letter-spacing: normal;
	background-color: white;
	border: none;
}
.builder_sidebar .gjs-sm-sector-title .gjs-sm-sector-label {
	margin-left: 0;
}

/* Property */
.builder_sidebar .gjs-sm-properties {
	padding: 2px 16px 12px;
}
.builder_sidebar .gjs-sm-property {
	margin: 8px 0;
	padding: 0 6px;
}
.builder_sidebar .gjs-sm-label {
	font-size: 13px;
	font-weight: 500;
	color: var(--grey-0);
	margin: 0 0 4px;
}

/* Composite */
.builder_sidebar .gjs-sm-field.gjs-sm-composite {
	background-color: var(--main-bg-color);
	border-color: var(--main-border-color);
	border-radius: 3px;
}
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-sm-properties {
	padding: 6px;
}
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-sm-property {
	margin: 4px 0;
	padding: 0 4px;
}
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-sm-label {
	font-size: 13px;
	margin-bottom: 2px;
}

/* Field */
.builder_sidebar .gjs-field {
	background-color: transparent;
	border-radius: 0;
}
.builder_sidebar .gjs-field.gjs-field-integer,
.builder_sidebar .gjs-field.gjs-field-number,
.builder_sidebar .gjs-field.gjs-field-text,
.builder_sidebar .gjs-field.gjs-select,
.builder_sidebar .gjs-field.gjs-field-select,
.builder_sidebar .gjs-field.gjs-field-color{
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	background: var(--input-bg);
	border: var(--input-border);
	border-radius: var(--input-radius);
	transition: all 200ms ease-in-out;
	width: 200px;
	max-width: 100%;
	margin: 0;
	font-size: 14px;
	font-weight: inherit;
	line-height: 20px;
	color: var(--grey-0);
	outline: none;
	padding: 0;
	text-align: left;
}
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-field.gjs-field-integer,
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-field.gjs-field-number,
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-field.gjs-field-text,
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-field.gjs-select,
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-field.gjs-field-select,
.builder_sidebar .gjs-sm-field.gjs-sm-composite .gjs-field.gjs-field-color {
	background-color: var(--white);
}
.builder_sidebar .gjs-field.gjs-field-integer:not(.disabled):hover,
.builder_sidebar .gjs-field.gjs-field-number:not(.disabled):hover,
.builder_sidebar .gjs-field.gjs-field-text:not(.disabled):hover,
.builder_sidebar .gjs-field.gjs-select:not(.disabled):hover,
.builder_sidebar .gjs-field.gjs-field-select:not(.disabled):hover,
.builder_sidebar .gjs-field.gjs-field-color:not(.disabled):hover{
	border: var(--input-border-hover);
}
.builder_sidebar .gjs-field.gjs-field-integer:not(.disabled):focus-within,
.builder_sidebar .gjs-field.gjs-field-number:not(.disabled):focus-within,
.builder_sidebar .gjs-field.gjs-field-text:not(.disabled):focus-within,
.builder_sidebar .gjs-field.gjs-select:not(.disabled):focus-within,
.builder_sidebar .gjs-field.gjs-field-select:not(.disabled):focus-within,
.builder_sidebar .gjs-field.gjs-field-color:not(.disabled):focus-within{
	border: var(--input-border-focus);
	box-shadow: 0 0 0 1px var(--turquoise), 0 0 0 3px var(--turquoise-light);
	background-color: #fff;
}


.builder_sidebar .gjs-field,
.builder_sidebar .gjs-field input:-webkit-autofill {
	transition: all 200ms ease-in-out;
}
.builder_sidebar .gjs-field input[type="text"],
.builder_sidebar .gjs-field input[type="password"],
.builder_sidebar .gjs-field input[type="number"],
.builder_sidebar .gjs-field input[type="email"],
.builder_sidebar .gjs-field input[type="tel"],
.builder_sidebar .gjs-field select {
	border: none !important;
	background: transparent !important;
	box-shadow: none !important;

	flex: 1 1 auto;
	padding: 0;
	border-radius: var(--input-radius);
	color: inherit;
	line-height: inherit;
	outline: none;
	min-width: 0;
	padding: 8px 10px;
	font-size: inherit;
	width: 100%;
}


/* Select */
.builder_sidebar .gjs-field.gjs-select .gjs-sm-input-holder,
.builder_sidebar .gjs-field.gjs-select #gjs-sm-input-holder {
	flex: 1 1 auto;
}
.builder_sidebar .gjs-field select {
	padding: 8px 24px 8px 8px;
}
.builder_sidebar .gjs-field .gjs-sel-arrow .gjs-d-s-arrow{
	right: 9px;
	border-top: 5px solid var(--grey-2);
	border-left: 4px solid rgba(0,0,0,0);
    border-right: 4px solid rgba(0,0,0,0);
}

/* Color field */
.builder_sidebar .gjs-field.gjs-field-color {
	display: inline-flex;
	align-items: center;
}
.builder_sidebar .gjs-field.gjs-field-color .gjs-input-holder{
	flex: 1 1 auto;
	order: 2;
}
.builder_sidebar .gjs-field.gjs-field-color .gjs-field-colorp{
	position: relative;
	order: 1;
	flex: 0 0 auto;
	right: auto;
	width: 26px;
	height: 26px;
	margin-left: 5px;
	border-radius: 0;
	border: none;
	padding: 0;
}
.builder_sidebar .gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-color-picker {
	box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
}
.builder_sidebar .gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-colorp-c,
.builder_sidebar .gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-color-picker,
.builder_sidebar .gjs-field.gjs-field-color .gjs-field-colorp .gjs-checker-bg {
	border-radius: 3px;
}

/* Unit */
.builder_sidebar .gjs-field .gjs-field-units {
	flex: 0 0 auto;
	position: relative;
	right: auto;
	margin-left: 2px;
	margin-right: 2px;
}
.builder_sidebar .gjs-field .gjs-field-units .gjs-input-unit {
	font-size: 12px;
	padding-left: 3px;
	padding-right: 3px;
	cursor: pointer;
	border-radius: 0;
	transition: all 200ms;
	border-radius: var(--input-radius);
	text-align: center;
}
.builder_sidebar .gjs-field .gjs-field-units .gjs-input-unit:hover,
.builder_sidebar .gjs-field .gjs-field-units .gjs-input-unit:focus {
	background-color: var(--grey-7) !important;
}

/* Integer */
.builder_sidebar .gjs-field.gjs-field-integer {
	padding-right: 18px;
}
.builder_sidebar .gjs-field .gjs-field-arrows {
	top: 0;
	bottom: 0;
	right: 0;
	height: auto;
	display: flex;
	flex-direction: column;
	width: 18px;
	cursor: ns-resize;
	transition: all 200ms;
	border-radius: var(--input-radius);
}
.builder_sidebar .gjs-field .gjs-field-arrows:hover,
.builder_sidebar .gjs-field .gjs-field-arrows:active {
	background-color: var(--grey-7) !important;
}
.builder_sidebar .gjs-field .gjs-field-arrows .gjs-field-arrow-u,
.builder_sidebar .gjs-field .gjs-field-arrows .gjs-field-arrow-d {
	position: static;
	flex: 1 1 50%;
	width: 100%;
	height: auto;
	border: none !important;
	display: flex;
	cursor: inherit;
}
.builder_sidebar .gjs-field .gjs-field-arrows .gjs-field-arrow-u::before,
.builder_sidebar .gjs-field .gjs-field-arrows .gjs-field-arrow-d::before {
	content: "";
	width: 0;
	height: 0;
	margin: 0 0 0 3px;
	white-space: nowrap;
	border: 4px solid;
	border-right-color: transparent;
	border-left-color: transparent;
	color: var(--grey-2);
}
.builder_sidebar .gjs-field .gjs-field-arrows .gjs-field-arrow-u::before {
	border-top-width: 0;
	align-self: flex-end;
	margin-bottom: 2px;
}
.builder_sidebar .gjs-field .gjs-field-arrows .gjs-field-arrow-d::before {
	border-bottom-width: 0; 
	align-self: flex-start;
	margin-top: 2px;
}

/* Sides order */
.builder_sidebar .gjs-sm-property.gjs-sm-property__border-top-left-radius {
	order: 1;
}
.builder_sidebar .gjs-sm-property.gjs-sm-property__border-top-right-radius {
	order: 2;
}
.builder_sidebar .gjs-sm-property.gjs-sm-property__border-bottom-left-radius {
	order: 3;
}
.builder_sidebar .gjs-sm-property.gjs-sm-property__border-bottom-right-radius {
	order: 4;
}

/* Radio */
.builder_sidebar .gjs-field.gjs-field-radio {

}
.builder_sidebar .gjs-field .gjs-radio-items {

}
.builder_sidebar .gjs-field .gjs-radio-item {
	border: none;
	margin-left: -1px;
	background: none !important;
}
.builder_sidebar .gjs-field .gjs-radio-item:first-child {
	margin-left: 0;
}
.builder_sidebar .gjs-field .gjs-radio-item-label {
	padding: 8px 10px;
	line-height: 20px;
	min-height: 20px;
	max-width: 100%;
	font-size: 14px;
	font-weight: 400;
	border: solid 1px transparent;
	color: var(--btn-color-secondary);
	border-color: #CAD0DB;
	margin: 0;
	text-transform: capitalize;
	transition: all 100ms ease-in-out;
}
.builder_sidebar .gjs-field .gjs-radio-item:first-child .gjs-radio-item-label {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
.builder_sidebar .gjs-field .gjs-radio-item:last-child .gjs-radio-item-label {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}
.builder_sidebar .gjs-field .gjs-radio-item-label:hover {
	background: #F7F7FA;
}
.builder_sidebar .gjs-field input:checked + .gjs-radio-item-label {
	background: var(--turquoise-light);
	color: var(--turquoise);
	border-color: currentColor;
	font-weight: 500;
	position: relative;
}
