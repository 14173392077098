@value ellips from "../../../Components/utils.module.css";

/* CPG infos */
.header {
	margin-bottom: 6px;
}
.desc {
	font-size: 15px;
	color: var(--grey-2);
	margin-top: 3px;
}
.label_list {
	margin-top: 10px;
}

/* Breadcrumb */
.breadcrumb{
	display: inline-flex;
	margin-top: 10px;
	margin-bottom: 20px;
	color: var(--grey-2);
	padding-bottom: 6px;
	border-bottom: solid 2px var(--grey-7);
}
.breadcrumb_item{
	list-style: none;
	font-size: 15px;
	font-weight: 500;
}
.breadcrumb_item + .breadcrumb_item::before{
	content: "\f054";
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 14px;
	line-height: 14px;
	margin: 0 8px;
	font-size: 10px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	text-align: center;
	color: var(--grey-4);
}
.breadcrumb_link{
	color: var(--turquoise);
	cursor: pointer;
}
.breadcrumb_link:hover{
	text-decoration: underline;
}

/* Sumup */
.sumup_list {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	margin-top: -20px;
	margin-bottom: -20px;
}
.sumup_item {
	--pad: 26px;
	padding-right: var(--pad);
	margin-top: 16px;
	margin-bottom: 16px;
}
.sumup_item:not(:first-child) {
	padding-left: var(--pad);
	border-left: dashed 1px var(--main-border-color);
}
.sumup_item:last-child {
	padding-right: 0;
}
.sumup_label {
	font-size: 13px;
	color: var(--grey-2);
	line-height: 1;
}
.sumup_value {
	font-size: 20px;
	font-weight: 500;
	line-height: 1;
	margin-top: 10px;
}
.sumup_value_success {
	composes: sumup_value;
	color: #43B55E;
}
.sumup_value_alert {
	composes: sumup_value;
	color: #F04F5B;
}
.sumup_status_bullet {
	vertical-align: middle;
    margin-right: 10px;
}
@media (max-width: 1300px) {
	.sumup_item {
		--pad: 22px;
	}
}
@media (max-width: 1250px) {
	.sumup_item {
		--pad: 20px;
	}
}
@media (max-width: 1200px) {
	.sumup_item {
		--pad: 16px;
	}
}

/* Table */
.table .table_head_row,
.table .table_body_row {
    grid-template-columns: 2.1fr .7fr .8fr 1fr 1fr 1.6fr;
}
.table .table_head_row {
	font-size: 12px;
	line-height: 1.23;
	align-items: flex-end;
}
.table .table_body_row {
	font-size: 15px;
}
.table_col:nth-child(2),
.table_col:nth-child(3),
.table_col:nth-child(4),
.table_col:nth-child(5){
	justify-content: flex-end;
    text-align: right;
}
.table_col:nth-child(5) {
	padding-right: 30px;
}

/* Row */
.table_row_checkbox {
	flex: 0 0 auto;
	margin-right: 10px;
}
.table_row_name {
	composes: ellips;
	font-weight: 500;
}
.table_row_desc {
	font-size: 13px;
	color: var(--grey-2);
	font-weight: 400;
}
.table_row_name,
.table_row_desc {
	text-overflow: ellipsis;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}

.table_body_row.variation_best {
	color: #43B55E;
	font-weight: 500;
}


/* Plan */
.plan {
	width: 100%;
	height: 35px;
	background-color: #F7F8FA;
	box-shadow: var(--white) 0 0 0 2px;
	border-radius: 3px;
	position: relative;
	--dark-color: var(--grey-4);
	--light-color: var(--grey-7);
}
.table_body_row.variation_better .plan {
	--dark-color: #006fff;
	--light-color: #D6E6FB;
}
.table_body_row.variation_worse .plan {
	--dark-color: #F04F5B;
	--light-color: #FFD0D3;
}
.table_body_row.variation_best .plan {
	--dark-color: #43B55E;
	--light-color: #DFF0E5;
}
.plan_range {
	position: absolute;
	width: 22%;
	left: 10%;
	top: 0;
	bottom: 0;
	border-style: solid;
	border-width: 0 2px;
	background-color: var(--light-color);
	border-color: var(--dark-color);
}
.plan_mean_value {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	background-color: var(--dark-color);
	font-size: 13px;
	line-height: 1;
	font-weight: 500;
	color: var(--white);
	padding: 2px 4px;
	border-radius: 2px;
	cursor: default;
}

.plan_tooltip {
	display: flex;
	align-items: center;
	gap: 24px;
}
.plan_tooltip_item {
	font-size: 13px;
}
.plan_tooltip_label {
	font-weight: 500;
}
.plan_tooltip_value {
	margin-top: 1px;
}
