.root {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: stretch;
}

/* Preview */
.preview {
	flex: 1 1 auto;
	background: var(--main-bg-color);
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 10px;
	overflow: auto;
	position: relative;
}
.preview.preview_no_scroll {
	overflow: hidden;
}
.preview_inner {
	background-color: #CACCD8;
	background-image: linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%), linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%);
	background-size: 16px 16px;
	background-position: 0 0, 8px 8px;
	margin: auto;
}
.img {
	
}

/* Panel */
.panel {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	width: 340px;
	padding:  22px 30px;
	border-left: solid 1px var(--main-border-color);
}
.panel_body {
	flex: 1 1 auto;
}
.panel_footer {
	flex: 0 0 auto;
	margin-top: 20px;
}
