/*.bannersListWrapper {
	margin-left: -16px;
	margin-top: -16px;
}

.containerSourceImage {
	display: flex;
	flex-wrap: wrap;
	align-items: start;
}

.bannersList {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: start;
}

.bannersListItem {
	flex: 0 1 auto;
	margin: 16px 0 0 16px;
	position: relative;
}*/

/* Content */

/*.bannersListItemName, .bannersListItemInfo {
	position: relative;
	height: 1.4em;
	font-size: 12px;
}

.bannersListItemName {
	margin-top: 4px;
}

.bannersListItemInfo {
	color: #909090;
}

.bannersListItemFloatText {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.bannersListItemAction {
	margin-left: 4px;
	font-size: 13px;
	color: inherit;
	cursor: pointer;
	opacity: 0;
	transition: 100ms;
}

.bannersListItem:hover .bannersListItemAction {
	opacity: 1;
}

.bannersListItemAction:hover {
	color: #444;
}

.bannersListItemInfoSep {
	color: #aaa;
	margin: 0 5px;
}

.bannersListItemImg {
	position: relative;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
}

.bannersListItemImg img {
	display: block;
	width: auto;
	height: auto;
	max-width: 300px;
	max-height: 150px;
}

.bannersListItemImg button {
	align-items: center;
}

.bannersListItemIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 36px;
	height: 36px;
	line-height: 36px;
	border-radius: 36px;
	text-align: center;
	margin: -18px 0 0 -18px;
	background: rgba(0, 0, 0, .5);
	color: #FFF;
}

.bannersListItemIcon.bannersListItemIconWarn {
	background: #FFE762;
	color: #A38A00;
}*/

/* Selection */

/*.bannersListItem.has_selection:not(.disabled) {
	cursor: pointer;
}

.bannersListItem.has_selection .bannersListItemImg::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: solid 4px transparent;
	transition: border 100ms;
}

.bannersListItem.has_selection:not(.disabled):hover .bannersListItemImg::after {
	border-color: rgba(0, 0, 0, .15);
}

.bannersListItem.has_selection.selected .bannersListItemImg::after {
	border-color: rgb(92, 184, 92);
}*/

/* Preview */

/*.bannersListItem.has_preview .bannersListItemImg {
	cursor: pointer;
}

.bannersListItem.has_preview .bannersListItemImg::before {
	content: "\f002";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	background: rgba(0, 0, 0, .6);
	color: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
}

.bannersListItem.has_preview .bannersListItemImg:hover::before {
	opacity: 1;
	visibility: visible;
}*/

/* Smaller */

/*.bannersListWrapperSmall {
	margin-left: -14px;
	margin-top: -14px;
}

.bannersListWrapperSmall .bannersListItem {
	margin: 14px 0 0 14px;
	min-width: 160px;
}

.bannersListWrapperSmall .bannersListItemImg img {
	max-height: 90px;
	max-width: 180px;
}*/

/* Disabled */

/*.bannersListItem,
.bannersListItem .bannersListItemInfo,
.bannersListItem .bannersListItemInfoSep {
	transition: color 200ms;
}

.bannersListItem.disabled,
.bannersListItem.disabled .bannersListItemInfo,
.bannersListItem.disabled .bannersListItemInfoSep {
	color: #ddd;
}

.bannersListItem img {
	transition: all 200ms;
}

.bannersListItem.disabled img {
	filter: grayscale(0.2);
	opacity: 0.3;
}

#cld-media-editor-iframe {
	display: block;
}*/