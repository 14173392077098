/* Ce code est appelé dans Analytics */
/* TODO : il faut le déplacer, il n'a plus d'utilité ici */
.collapse_btn {
    cursor: pointer;
    padding: 16px 16px;
    margin: -16px -16px;
    border-radius: 6px;
    transition: all 200ms;
}
.outer_size_s .collapse_btn {
    padding: 12px 16px;
    margin: -12px -16px;
}
.collapse_btn:hover,
.collapse_btn:focus {
    background: #F8F9FB;
}
