.outer {
    --marginTop: 40px;
    --marginBottom: 40px;
    --marginLeft: 40px;
    --marginRight: 40px;
}
.outer_has_vertical_margin::before,
.outer_has_vertical_margin::after {
    content: "";
    display: block;
}
.outer_has_vertical_margin::before{
    margin-top: var(--marginTop);
}
.outer_has_vertical_margin::after {
    margin-top: var(--marginBottom);
}
.outer_has_horizontal_margin {
    padding-left: var(--marginLeft);
    padding-right: var(--marginRight);
}
.inner {
    margin: 0 auto;
}
.inner_width_xs {
    max-width: 580px;
}
.inner_width_s {
    max-width: 800px;
}
.inner_width_m {
    max-width: 1200px;
}
.inner_width_l {
    max-width: 1400px;
}
.inner_width_full {
    max-width: 0;
}