/* Editor */
.patch_editor{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
}
.patch_editor_head{
    padding: 18px 22px 0;
}
.patch_editor_tabs a{
    display: block;
    width: 160px;
    background-color: #101012;
    padding: 10px 14px 11px;
    border-top: 2px solid #4A4B50;
    font-size: 13px;
    font-weight: 500;
    color: #CACCD8;
    margin-right: 5px;
    cursor: pointer;
}
.patch_editor_tabs a.is_active{
    color: #fff;
    background: #202124;
}
.patch_editor_tabs a.is_active.is_html{
    border-color: #ff3c41;
}
.patch_editor_tabs a.is_active.is_css{
    border-color: #3E95CF;
}
.patch_editor_tabs a.is_active.is_js{
    border-color: #F7DA13;
}

.patch_editor_body{
    position: relative;
    overflow: hidden;
}
.patch_editor_body > section{
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto!important;
    height: auto!important;
}
