/* Row */
.row {
    display: flex;
    align-items: center;
}
.row:not(:first-child) {
    margin-top: 13px;
}

/* Cols */
.col_label {
    flex: 0 0 auto;
    margin-right: 10px;
}
.col_field {
    flex: 1 1 auto;
}

/* Col width */
.col_label.width_s {
    width: 70px;
}
.col_label.width_m {
    width: 100px;
}
.col_label.width_l {
    width: 130px;
}
.col_label.width_xl {
    width: 160px;
}

/* Col align */
.col_label.align_middle {
    align-self: center;
}
.col_label.align_top {
    align-self: flex-start;
    padding-top: 6px;
}

/* Content */
.label {
    font-size: 15px;
    font-weight: 500;
    color: var(--grey-0);
    margin: 0;
    width: auto;
    cursor: default;
}
