.contentDescriptionTag {
	font-size: 12px;
	color: #9E9E9E;
}

.modalBody {
	height: auto;
	max-height: 560px;
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-top: 30px;
	background: #FFFFFF;
	box-shadow: 0px 0px 20px #0000001A;
	border-radius: 15px;
	padding: 20px;
	font-family: "Vision", "Roboto", Arial, sans-serif;
}

.modalBody h2 {
	margin: 0 0 20px;
	font-size: 16px;
}

.search_tag {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}

.search_tag i {
	color: #9E9E9E;
}

input[type="text"].searchBarTag, input[type="text"].new_tag_name {
	width: 100%;
	border: none;
	font-size: 14px;
}

input[type="text"].new_tag_name {
	border: 1px solid #EDEDED;
}

input[type="text"].searchBarTag::-webkit-input-placeholder, input[type="text"].new_tag_name::-webkit-input-placeholder, .textAreaSkuAddNew::-webkit-input-placeholder, .textAreaSku::-webkit-input-placeholder {
	font-style: italic;
	color: #9E9E9E;
	font-size: 14px;
}

.listOfSkuWithCount {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	align-content: flex-start;
}

.listOfSkuWithCount .itemSku {
	cursor: pointer;
	transition: all .2s ease-in-out;
}

.listOfSkuWithCount .itemSku:hover {
	color: #006fff;
}

.listOfSkuApi {
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	height: 270px;
	overflow: auto;
	align-items: flex-start;
	justify-content: flex-start;
	align-content: flex-start;
	border: 1px solid #EDEDED;
	padding: 10px;
}

.editSkuList {
	flex-direction: column;
}

.editSkuList .itemSku {
	width: 100%;
	display: flex;
	position: relative;
}

.editSkuList p {
	margin-left: 0;
	transition: all .2s ease-in-out;
}

.editSkuList .itemSku:hover p {
	margin-left: 25px;
}

.editSkuList .itemSku span {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	font-size: 14px;
	top: 1px;
	left: -3px;
	cursor: pointer;
	transition: all .2s ease-in-out;
}

.editSkuList .itemSku:hover span {
	visibility: visible;
	opacity: 1;
}

.editSkuList span:hover {
	color: #D76666;
}

.tag_actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 5px;
}

.textAreaSku {
	height: 75px;
	width: 100%;
	padding: 10px;
	background: #FFFFFF;
	border: 1px solid #EDEDED;
	resize: none;
}

.textAreaSkuAddNew {
	width: 100%;
	height: 310px;
	background: #FFFFFF;
	border: 1px solid #EDEDED;
	resize: none;
	border-radius: 5px;
	margin-top: 10px;
	padding: 10px;
}

.itemSku {
	padding-right: 10px;
	width: 33%;
	margin-bottom: 10px;
}

.itemSku span {
	margin-left: 5px;
	color: #9E9E9E;
}

.tag_btn {
	border-radius: 5px;
	margin-left: 10px;
	padding: 7px 10px;
	border: 1px solid;
}

.buttonAdd {
	background: #006fff;
	color: #FFF;
}

.btn-deleteTag {
	background: #FFFFFF;
	border: 1px solid #9E9E9E;
	border-radius: 5px;
	color: #9E9E9E;
}

.btn-cancelDelete {
	background: #FFFFFF;
	border: 1px solid #9E9E9E;
	border-radius: 5px;
	color: #9E9E9E;
}

.btn-saveTag {
	background: #006fff;
	border-radius: 5px;
	color: #FFF;
}

.btn-clearList {
	background: rgb(219, 98, 17);
	border-radius: 5px;
	color: #FFF;
}

.btn-addList {
	background: rgb(15, 224, 95);
	border-radius: 5px;
	color: #FFF;
}

.advertTag {
	font-size: 11px;
	color: #9E9E9E;
	margin-top: 10px;
}

.edit_tag_name {
	font-weight: 700;
}

.edit_tag_total {
	color: #9E9E9E;
}

.noData {
	text-align: center;
	font-style: italic;
	margin: 10px 0 80px;
	color: #9E9E9E;
	width: 100%;
}