.reporting_array {
    table-layout: auto;
    border-collapse: separate !important;
    border-spacing: 0;
    width: 100%;
    margin-top: 14px;
    font-size: 15px;
    background-color: var(--white);
    border: 1px solid var(--main-border-color);
    border-radius: 4px;
}

.reporting_array+.reporting_array {
    margin-top: 24px;
}

.reporting_array thead th {
    background-color: #F5F6F8;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #8A8EA5;
    border-bottom: solid 1px var(--main-border-color);
}

.reporting_array thead th:first-child {
    border-top-left-radius: 4px;
}

.reporting_array th {
    padding: 7px 10px;
    vertical-align: middle;
}

.reporting_array td {
    padding: 9px 10px;
    vertical-align: middle;
}

.reporting_array th:not(:first-child), .reporting_array td:not(:first-child) {
    border-left: 1px solid var(--main-border-color);
}

.reporting_array tr+tr td {
    border-top: 1px solid #F5F6F8;
}

.reporting_array .reporting_col_label {
    width: 50%;
    text-align: left;
}

.reporting_array .reporting_col_value {
    width: 25%;
    text-align: center;
}

.reporting_array .reporting_col_value_compute div {
    display: flex;
    margin: auto;
    align-items: center;
}

.reporting_array .reporting_col_value_compute div p:nth-of-type(1) {
    flex: 1 1 auto;
}

.reporting_array .reporting_col_value_compute div p:nth-of-type(2) {
    flex: 0 0 auto;
    white-space: nowrap;
}

.reporting_array_loading {
    margin: auto;
    height: 250px;
    width: 100%;
}

.reporting_array .value {}

.reporting_array .rate {
    font-size: 13px;
    color: #5D637E;
}

.reporting_array .uplift {
    font-weight: 500;
}

.reporting_array .uplift_positive {
    color: #5CB85C;
}

.reporting_array .uplift_negative {
    color: #acafc0;
}

.label_tooltip_compute {
    color: #acafc0;
    font-size: 13px;
    margin-left: 2px;
}