/* TOOLBAR */
.format_toolbar{
	border-bottom: solid 1px var(--main-border-color);
	min-height: 55px;
	padding: 6px var(--main-h-padding);
}
.format_toolbar_left{
    flex: 1 1 50%;
}
.format_toolbar_center{
    margin: 0 30px;
    flex: 0 0 auto;
}
.format_toolbar_right{
    flex: 1 1 50%;
    text-align: right;
}
.format_toolbar_title{
	font-size: 16px;
	font-weight: 500;
	vertical-align: middle;
}
.format_toolbar_subtitle{
	font-size: 15px;
	color: var(--grey-2);
	vertical-align: middle;
}
.format_toolbar_title_arrow {
	font-size: 10px;
	margin: 0 3px;
	vertical-align: middle;
	color: var(--grey-2);
}
.format_toolbar_back{
	flex: 0 0 auto;
	width: 32px;
	height: 32px;
	border-radius: 32px;
	color: var(--turquoise);
	font-size: 18px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 200ms;
}
.format_toolbar_back:hover{
	background-color: var(--main-bg-color);
}

.format_toolbar_left .format_cmd:first-child{
    margin-left: -4px;
	margin-right: 10px;
}

/* CMD */
.format_cmd{
	display: inline-flex;
	vertical-align: middle;
	padding: 6px 7px;
	border-radius: 4px;
	align-items: center;
	transition: all 200ms;
	cursor: pointer;
	user-select: none;
}
.format_cmd,
.format_cmd_label{
	color: var(--grey-0);
	font-size: 13px;
	line-height: 16px;
	font-weight: 500;
}
.format_cmd_label{
	display: inline-block;
}
.format_cmd:hover,
.format_cmd:focus{
	background-color: var(--main-bg-color);
}
.format_cmd.active{
	background-color: #e9eaf0;
}
.format_cmd.disabled{
	cursor: default;
	color: var(--grey-6);
	background-color: transparent !important;
}
.format_cmd.main_color.active{
	background-color: var(--turquoise-light);
	color: var(--turquoise);
}
.format_cmd i{
	font-size: 12px;
	margin-left: -2px;
	line-height: 16px;
}
.format_cmd .format_cmd_icon_l {
	font-size: 14px;
}
.format_cmd .fas:not(:last-child){
	margin-right: 2px;
}
.format_cmd.color{
	cursor: default;
}
.format_cmd.color .fas{
	color: #FFC400;
}
.format_cmd + .format_cmd,
.btn_wrapper + .format_cmd{
	margin-left: 4px;
}
.format_cmd_group{
	margin-right: -7px;
}

.format_cmd_sep {
	display: inline-block;
	vertical-align: middle;
	width: 1px;
	height: 16px;
	background: var(--main-border-color);
	margin: 0 13px;
}

/* TABS */
.format_tabs{
	display: inline-flex;
}
.format_tabs > li > a{
	display: block;
	font-size: 14px;
	font-weight: 500;
	color: var(--grey-0);
	padding: 6px 13px;
	border-radius: 30px;
	transition: all 200ms;
	margin: 0 1px;
	user-select: none;
	cursor: pointer;
}
.format_tabs > li > a:hover{
	background: var(--main-bg-color);
}
.format_tabs > li > a.active{
	color: var(--turquoise);
	background-color: var(--turquoise-light);
}
.format_tabs > li > a.disabled,
.format_tabs > li > a.disabled:hover{
	color: var(--grey-4);
	cursor: default;
	background-color: transparent;
}
.format_tabs > li > a.active.disabled,
.format_tabs > li > a.active.disabled:hover{
	background-color: var(--grey-7);
}
.format_tabs .fas{
	margin-right: 6px;
	font-size: 13px;
}

/* EDITOR */
.format_editor{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

/* PREVIEW */
.format_preview{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all 300ms;
	background-color: var(--main-bg-color);
}
.format_preview.mobile{
	padding: 22px;
}
.format_mockup{
	position: relative;
	margin: auto;
	transition: all 300ms;
	overflow: auto;
	display: flex;
	justify-content: center;
	background: #D6D7E0;
}
.format_preview.desktop .format_mockup{
	flex: 1 1 auto;
	width: 100%;
	height: 100%;
	padding: 20px;
}
.format_preview.mobile .format_mockup{
	flex: 0 0 auto;
	width: 375px;
	height: 660px;
	border-radius: 26px;
	padding: 28px 7px;
	box-sizing: content-box;
}
.format_mockup_inner{
	all: initial;
	margin: auto;
}
iframe.format_mockup_inner{
	width: 100%;
	height: 100%;
	background-color: var(--main-bg-color);
}
.format_preview.desktop iframe.format_mockup_inner{
	border-radius: 8px;
}
.format_preview.mobile iframe.format_mockup_inner{
	border-radius: 16px;
}


/* Fix me : use generic version */
.format_preview_empty{
	flex: 0 0 auto;
	margin: auto;
	text-align: center;
	line-height: 1.3;
}
.format_preview_empty_img{
	margin-bottom: 34px;
	display: inline-block;
	max-width: 100%;
}
.format_preview_empty_title{
	font-size: 26px;
	font-weight: 500;
}
.format_preview_empty_text{
	font-size: 18px;
	color: var(--grey-1);
	margin-top: 8px;
}
.format_preview_empty_sub{
	font-size: 15px;
	color: var(--grey-1);
	margin-top: 22px;
}

/* LIVE BTN */
.format_preview_live_dropdown{
	width: 480px;
}
.format_preview_live_form{
	margin-top: 10px;
}

/* CSS */
.css_editor{
	position: relative;
	z-index: 2;
	height: 45vh;
	border-top: solid 1px var(--main-border-color);
	box-shadow: rgba(0,0,0,.08) 0 0 8px;
}
.css_editor_panel{
	position: relative;
}
.css_editor_panel + .css_editor_panel{
	border-left: solid 1px var(--main-border-color);
}
.css_editor_panel_header{
	line-height: 1.3;
	padding: 6px 22px;
	border-bottom: solid 1px var(--main-border-color);
	color: var(--grey-0);
	font-size: 13px;
	font-weight: 500;
}
.css_editor_panel_body{
	overflow: hidden;
}
.css_editor_close{
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	margin-top: -6px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: var(--grey-3);
	font-size: 14px;
	transition: color 200ms;
}
.css_editor_close:hover{
	color: var(--grey-1);
}

.code_mirror_full .react-codemirror2,
.code_mirror_full .CodeMirror{
	height: 100%;
	min-height: auto;
	border:  none;
}
.code_mirror_full .CodeMirror-scroll{
	box-sizing: border-box !important;
}
.code_mirror_full .CodeMirror-gutters{
	background-color: var(--main-bg-color);
	border-right-color: var(--main-border-color);
	color: var(--grey-3);
}
.code_mirror_full .CodeMirror.CodeMirror-empty{
	color: var(--grey-3);
}

/* AB Test */
.format_ab{
	width: 380px;
}
.format_ab_title{
	font-weight: 500;
	font-size: 16px;
}
.format_ab_sub_title{
	font-weight: 500;
	margin-bottom: 4px;
}
.format_ab_header{
	border-bottom: solid 1px var(--main-border-color);
	padding-bottom: 12px;
}
.format_ab_body{
	color: var(--grey-0);
}
.format_ab_variation{
	margin: 10px 0;
}
.format_ab_variation .icon_btn{
	margin-right: -15px;
}
.format_ab_footer {
	border-top: solid 1px var(--main-border-color);
	padding-top: 12px;
}
