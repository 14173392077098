.format_sidebar{
	position: relative;
    border-right: solid 1px var(--main-border-color);
	transition: all 300ms;
	background-color: var(--white);
}
.format_sidebar_inner {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
}
.format_sidebar,
.format_sidebar_inner {
	width: 460px;
}
@media (max-width: 1300px) {
    .format_sidebar,
	.format_sidebar_inner {
		width: 420px;
	}
}
.format_sidebar.format_sidebar_closed {
	width: 0;
    overflow: hidden;
    border-width: 0;
}

/* Sections */
.format_sidebar_section{
    position: relative;
    margin: var(--main-h-padding);
}

.format_sidebar_footer{
    border-top: solid 1px var(--main-border-color);
    margin-top: 0;
    padding-top: var(--main-h-padding);
}
.format_sidebar_btn_col {
    flex: 1 1 100%;
}
.format_sidebar_btn_col + .format_sidebar_btn_col {
    margin-left: 12px;
}

/* Items */
.format_sidebar_item{
    background: #F5F6F8;
    margin-bottom: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    min-height: 46px;
    cursor: pointer;
    transition: all 200ms;
}
.format_sidebar_item_illu{
    margin: 2px;
    border-radius: 4px;
    flex: 0 0 auto;
    overflow: hidden;
}
.format_sidebar_item_illu img{
    display: block;
    height: 56px;
}
.format_sidebar_item_body{
    padding: 10px 16px;
    flex: 1 1 auto;
    font-size: 14px;
    font-weight: 500;
}
.format_sidebar_item_type{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--grey-3);
}
.format_sidebar_item_name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.format_sidebar_item_action{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    margin-right: 4px;
    transition: all 200ms;
    color: var(--grey-3);
    font-size: 14px;
}
.format_sidebar_item_action.disabled {
    opacity: .3;
    cursor: default;
}
.format_sidebar_item_action:not(.disabled):hover,
.format_sidebar_item_action:not(.disabled):focus{
    background-color: var(--grey-5);
    color: var(--grey-1);
}

.format_sidebar_item:hover{
    background-color: #E9EAF0;
}
.format_sidebar_item.active{
    background-color: #142C76;
    color: #fff;
}
.format_sidebar_item.active .format_sidebar_item_type{
    color: #fff;
    opacity: .66;
}

.format_sidebar_item_group {
    margin-bottom: 22px;
}
.format_sidebar_item_group_label {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-2);
    text-transform: uppercase;
    margin-bottom: 8px;
}


/* Collection */
.format_collection_nav{
    flex: 1 1 100%;
    margin: 0 7px 10px 7px;

    display: flex;
    align-items: flex-start;
    background: #F5F6F8;
    border-radius: 8px;

    box-shadow: rgba(0,0,0,.025) 0 2px 4px inset;
}
.format_collection_nav_slides{
    flex: 1 1 auto;
    padding: 8px;
}
.format_collection_nav_add{
    flex: 0 0 auto;
    padding: 8px;
}
.format_collection_nav_slides .btnUi:not(.active){
    background: #fff;
}
.format_collection_header{
    flex: 1 1 100%;
    margin: 0 7px 10px 7px;
    padding: 8px 0;
    border-bottom: solid 1px var(--main-border-color);
    color: var(--grey-0);
}

/* Loading */
.format_loading_wrapper {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}
