.container-link-mail-account div a:hover {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: inherit;
    color: #006fff;
}

.user-search-no-data {
    text-align: center;
    margin: auto;
    background: #FFF;
    padding: 5px 10px;
    color: #444444;
    display: inline-block;
    border-radius: 2px;
    font-weight: normal;
    cursor: pointer;
}
