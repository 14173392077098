.builder_sidebar .gjs-field-range {
	align-self: center;
	margin-right: 10px;
}
.builder_sidebar .gjs-field-range input[type=range] {
	display: block;
	width: 100%;
	height: 27px;
	-webkit-appearance: none;
	cursor: pointer;
}

/*progress support*/
.builder_sidebar .gjs-field-range input[type=range] {
	--range: calc(var(--max) - var(--min));
	--ratio: calc((var(--value) - var(--min)) / var(--range));
	--sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}
.builder_sidebar .gjs-field-range input[type=range]:focus {
	outline: none;
}

/* THUMB */
/* webkit */
.builder_sidebar .gjs-field-range input[type=range]::-webkit-slider-thumb {
	box-sizing: border-box;
	width: 19px;
	height: 19px;
	border-radius: 19px;
	background: #fff;
	border: solid 4px var(--turquoise);
	margin-top: -7px;
	-webkit-appearance: none;
	transition: all 200ms;
}
.builder_sidebar .gjs-field-range input[type=range]::-webkit-slider-thumb:active {
	box-shadow: 0 0 10px rgba(0, 111, 255, .3);
	border-width: 3px;
}
/*mozilla*/
.builder_sidebar .gjs-field-range input[type=range]::-moz-range-thumb {
	box-sizing: border-box;
	width: 19px;
	height: 19px;
	border-radius: 19px;
	background: #fff;
	border: solid 4px var(--turquoise);
	transition: all 200ms;
}
.builder_sidebar .gjs-field-range input[type=range]::-moz-range-thumb:hover {
	border-width: 3px;
	box-shadow: 0 0 10px rgba(0, 111, 255, .3);
}


/* TRACK */
/* webkit */
.builder_sidebar .gjs-field-range input[type=range]::-webkit-slider-runnable-track {
	height: 5px;
	border-radius: 5px;
	background: #E9EAF0;
	border: none;
	box-shadow: none;
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
.builder_sidebar .gjs-field-range input[type=range]:hover::-webkit-slider-runnable-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
.builder_sidebar .gjs-field-range input[type=range]:active::-webkit-slider-runnable-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
.builder_sidebar .gjs-field-range input[type=range].reverse::-webkit-slider-runnable-track {
	background-position: right;
}

/*mozilla*/
.builder_sidebar .gjs-field-range input[type=range]::-moz-range-track {
	height: 5px;
	border-radius: 5px;
	background: #E9EAF0;
	border: none;
	box-shadow: none;
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
.builder_sidebar .gjs-field-range input[type=range]:hover::-moz-range-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
.builder_sidebar .gjs-field-range input[type=range]:active::-moz-range-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
.builder_sidebar .gjs-field-range input[type=range].reverse::-moz-range-track {
	background-position: right;
}
