.container {
    border: 1px solid var(--main-border-color);
    background: var(--white);
    border-radius: 6px;
    padding: 11px 26px 11px 16px;
    margin-right: 12px;
    margin-top: 15px;
}
.title {
    color: var(--grey-3);
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
}
.value {
    color: var(--black);
    font-size: 24px;
    font-weight: 500;
}
.percentage {
    border-radius: 30px;
    padding: 3px 8px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 11px;
}
.success {
    color: var(--color-success);
    background-color: var(--color-success-light);
}
.danger {
    color: var(--color-alert);
    background-color: var(--color-alert-light);
}