.modalBody {
    height: auto;
    max-height: 560px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px #0000001A;
    border-radius: 15px;
    padding: 20px;
    font-family: "Vision", "Roboto", Arial, sans-serif;
    min-width: 665px;
}

.modalBody h2 {
    margin: 0 0 20px;
    font-size: 16px;
}

.search_tag {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EDEDED;
}

.search_tag i {
    color: #9E9E9E;
}

input[type="text"].searchBarTag, input[type="text"].new_tag_name {
    width: 100%;
    border: none;
    font-size: 14px;
}

input[type="text"].new_tag_name {
    border: 1px solid #EDEDED;
}

input[type="text"].searchBarTag::-webkit-input-placeholder, input[type="text"].new_tag_name::-webkit-input-placeholder, .textAreaSkuAddNew::-webkit-input-placeholder, .textAreaSku::-webkit-input-placeholder {
    font-style: italic;
    color: #9E9E9E;
    font-size: 14px;
}

.listOfShelvingRule {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
}

.listOfShelvingRule .itemShelvingRule {
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.listOfShelvingRule .itemShelvingRule:hover {
    color: #006fff;
}

.itemShelvingRule {
    padding-right: 10px;
    width: 33%;
    margin-bottom: 10px;
}

.itemShelvingRule span {
    margin-left: 5px;
    color: #9E9E9E;
}

.tag_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
}

.tag_btn {
    border-radius: 5px;
    margin-left: 10px;
    padding: 7px 10px;
    border: 1px solid;
}

.buttonAdd {
    background: #006fff;
    color: #FFF;
}