.btn{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
	padding: 6px;
	font-size: 20px;
	border-radius: 40px;
	line-height: 1.25em;
	transition: all 200ms;
}

/* Theme */
.btn_theme_darker {
	color: var(--grey-2);
}
.btn_theme_dark {
	color: var(--grey-3);
}
.btn_theme_medium {
	color: var(--grey-4);
}
.btn_theme_light {
	color: var(--grey-5);
}

/* Size */
.btn_xxl{
	font-size: 20px;
	margin: 0 5px;
}
.btn_xl{
	font-size: 18px;
	margin: 0 3px;
}
.btn_l{
	font-size: 17px;
	margin: 0 3px;
}
.btn_m{
	font-size: 16px;
	margin: 0 1px;
}
.btn_s{
	font-size: 14px;
	margin: 0;
}
.btn_xs{
	font-size: 12px;
	margin: 0;
}

/* Icon */
.icon {
	width: 1.25em;
	line-height: 1.25em;
	text-align: center;
}

