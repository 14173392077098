.customizeTitleDesc {
	font-family: "Vision", "Roboto", Arial, sans-serif;
	font-size: 13px;
	color: #777;
	margin: -25px 0 30px;
}

.customizeContainer {
	display: flex;
}

.customizePartBanner {
	width: 45%;
	min-width: 500px;
}

.customizePartPreview {
	margin-left: 30px;
	width: 55%;
}

@media (min-width: 1400px) {
	.customizePartPreview {
		margin-left: 50px;
	}
}

/* Banner part */

.customizePortlet + .customizePortlet{
	margin-top: 20px;
}

.customizePortletHead {
	background: #F6F7F8;
	border-radius: 6px;
	padding: 6px 50px 6px 10px;
	font-size: 16px;
	position: relative;
	cursor: pointer;
	transition: background 100ms;
	padding-left: 24px;
}

.customizePortletHead:hover {
	background: #ECEFF2;
}

.customizePortlet.open .customizePortletHead {
	border-radius: 6px 6px 0 0;
}

.customizePortletHeadArrow {
	position: absolute;
	left: 0px;
	top: 50%;
	margin-top: -7px;
	width: 20px;
	height: 14px;
	color: #909090;
	transition: all 100ms;
}

.customizePortletHeadArrow .fas {
	display: block;
}

.customizePortlet.open .customizePortletHeadArrow {
	transform: rotate(180deg);
}

.customizePortletBody {
	border: solid 1px #F6F7F8;
	border-top-width: 0;
	padding: 5px;
	border-radius: 0 0 6px 6px;
	transition: all 200ms;
}
.customizePortlet:not(.open) .customizePortletBody {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom-width: 0;
    overflow: hidden;
}

.customizeBanners {
	overflow: auto;
	max-height: 88vh;
	scroll-behavior: smooth;
}

.customizeBannersEmpty {
	padding: 30px;
	color: #909090;
	text-align: center;
	font-size: 16px;
}

/* Preview part */

.customizeDevices {
	margin-bottom: 10px;
}

.customizeDevicesBtn {
	display: inline-block;
	cursor: pointer;
	border: solid 1px currentColor;
	border-radius: 5px;
	padding: 4px 14px;
	margin-right: 10px;
	font-size: 13px;
	line-height: 21px;
	color: #9E9E9E;
}

.customizeDevicesBtn.selected {
	color: #006fff;
}

.customizeDevicesBtn .fas {
	margin-right: 4px;
	vertical-align: middle;
}

/* Page Preview */

.customizePreview {
	display: inline-block;
	box-shadow: rgba(0, 0, 0, .1) 0 0 20px;
	border: solid 1px #ddd;
	overflow: auto;
	max-height: calc(100vh - 200px);
}

.customizePreviewMobile {
	max-width: 420px;
}

.customizePreviewInner {
	position: relative;
}

.customizePreviewImg {
	display: block;
	max-width: 100%;
	opacity: .7;
}

.customizeZone {
	position: absolute;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: rgba(103, 214, 215, .88);
	transition: all 100ms;
}

.customizeZone::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: solid 4px transparent;
	transition: all 100ms;
}

.customizeZone:hover::after {
	border-color: rgba(0, 0, 0, .15);
}

.customizeZone.selected {
	background: rgba(92, 184, 92, .88);
}

.customizeZone.selected::after {
	border-color: rgb(92, 184, 92);
}

.customizeZoneText {
	flex: 1 1 auto;
	padding: 10px;
	color: #fff;
	text-align: center;
	font-weight: 500;
	font-size: 26px;
}

.customizeZone img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.customizeZoneRemove {
	position: absolute;
	top: 4px;
	right: 4px;
	border-radius: 3px;
	z-index: 1;
	background: #fff;
	padding: 6px 4px;
	color: #9E9E9E;
}

.customizeZoneRemove:hover {
	background: #006fff;
	color: #fff;
}

.customizeZoneRemove .fas,
.customizeZoneEdit .fas {
	display: block;
}

.customizeZoneEdit {
	position: absolute;
	top: 4px;
	right: 40px;
	border-radius: 3px;
	z-index: 1;
	background: #fff;
	padding: 6px 4px;
	color: #9E9E9E;
	height: 27px;
}

.customizeZoneEdit:hover {
	background: #006fff;
	color: #fff;
}

.customizeZoneEdit.fas {
	display: block;
}

.drafMode {
	position: absolute;
	top: 48px;
	right: 350px;
	height: 40px;
	background: #ffc618eb;
	padding: 1px 10px;
	text-align: center;
	box-shadow: 1px 2px 3px 1px #46373769;
}

.drafMode h2 {
	font-weight: 500;
	font-size: 15px;
	margin: 12px auto;
}

.customize_switch_cta {
	display: flex;
	margin: auto;
	justify-content: space-evenly;
	width: 50%;
	height: 30px;
	line-height: 15px;
	font-weight: 400;
}

.customize_switch_cta label:hover {
	color: #006fff;
	cursor: pointer;
}

.isActiveLabel {
	color: #006fff;
}

.customize_cta_input_container p {
	font-weight: 400;
	margin-top: 5px;
}

.customize_cta_input_container input {
	width: 100%;
}

.customize_cta_checkbox {
	margin: 10px auto auto auto;
	width: 30%;
}

.customizeZoneSlider{
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255,255,255,.6);
	padding: 3px;
}
.customizeZoneSliderItems{
	margin: 0 4px;
}
.customizeZoneSliderItem{
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius: 40px;
	cursor: pointer;
	text-align: center;
	background: rgba(0,0,0,.5);
	color: #fff;
	font-weight: 600;
	font-weight: 13px;
	margin-right: 4px;
}
.customizeZoneSliderItem.active{
	background: #fff;
	color: #006fff;
}
.customizeZone.selected .customizeZoneSliderItem.active{
	color: #74B566;
}
.customizeZoneSliderAdd{

}
.customizeZoneSliderSettings{
	margin-left: auto;
	padding-left: 10px;
}



/* Carousel */
.customizeCarouselZone{
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0,0,0,.1);
	display: flex;
	align-items: center;
	overflow: hidden;
	user-select: none;
	cursor: grab;
}

.customizeCarouselZone .customizeZoneSliderAdd{
	flex: 0 0 auto;
	background: #fff;
	margin-right: 20px;
	margin-left: 0;
	width: 32px;
    text-align: center;
}

.customizeCarouselSlide{
	flex: 0 0 auto;
	position: relative;
	display: flex;
    align-items: center;
	margin-right: 8px;
	background: rgba(103, 214, 215, .88);
	cursor: pointer;
	overflow: hidden;
	max-width: calc(100% - 40px);
}
.customizeCarouselSlide::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: solid 4px transparent;
	transition: all 100ms;
}

.customizeCarouselSlide:hover::after {
	border-color: rgba(0, 0, 0, .15);
}
.customizeCarouselSlide.selected {
	background: rgba(92, 184, 92, .88);
}
.customizeCarouselSlide.selected::after {
	border-color: rgb(92, 184, 92);
}

.customizeCarouselSlide img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.customizeCarouselSlide .customizeZoneText{
	font-size: 13px;
}
