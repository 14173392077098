.wrapper {
    position: absolute;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    right: 20px;
    bottom: 20px;
}

.menu_open {
    text-align: right;
    background-color: pink;
}
.menu_btn {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    height: 48px;
    line-height: 48px;
    border-radius: 48px;
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    background: transparent linear-gradient(139deg, #006FFF 0%, #40A7F2 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0  0 10px rgba(0,0,0,.08);
    user-select: none;
    transition: all 200ms;
}
.menu_btn:hover,
.menu_btn:focus {
    height: 54px;
    padding: 0 26px;
    font-size: 15px;
}
.menu_btn:active {
    height: 50px;
    padding: 0 24px;
}

/* BTN */
.btn{
    height: 48px;
    width: 48px;
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 200ms;

    background: transparent linear-gradient(139deg, #006FFF 0%, #40A7F2 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0  0 10px rgba(0,0,0,.08);
}
.btn:hover,
.btn:focus {
    width: 56px;
    height: 56px;
    /* bottom: 16px;
    right: 16px; */
}
.btn:active {
    width: 50px;
    height: 50px;
    /* bottom: 18px;
    right: 18px; */
}

.btn_icon {
    width: 32px;
    height: 32px;
    background-image: url('/Assets/chatbox_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    transition: all 200ms;
}
.btn:hover .btn_icon,
.btn:focus .btn_icon {
    width: 34px;
    height: 34px;
    transform: rotateZ(-5deg);
}
.btn:active .btn_icon {
    width: 20px;
    height: 20px;
    opacity: 0;
}

/* Tooltip */
.tooltip {
    z-index: 10200;
    position: relative;
    background: var(--white);
    border: solid 1px var(--main-border-color);
    box-shadow: 0 3px 10px rgb(0, 0, 0, 0.04), 0 3px 30px rgb(0, 0, 0, 0.08);
    padding: 22px;
    border-radius: 20px;
    margin-bottom: 10px;
    -webkit-animation: help_menu_tooltip 1000ms;
	animation: help_menu_tooltip 1000ms;
    cursor: pointer;
    max-width: 400px;
}
.tooltip_head {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.tooltip_icon {
    flex: 0 0 auto;
    margin-right: 7px;
}
.tooltip_title {
    flex: 1 1 auto;
    font-size: 15px;
    font-weight: 600;
    font-family: 'IBM Plex Sans';
    color: #7D38DB;
}
.tooltip_close {
    flex: 0 0 auto;
    margin-left: 10px;
    margin-right: -8px;
    margin-top: -4px;
    margin-bottom: -4px;
}
.tooltip_body p {
    margin: .3em 0;
}

@keyframes help_menu_tooltip {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0);
    }
    
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
    
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
    
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }
    
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
