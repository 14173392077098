.campaign_card{
    width: 530px;
    padding: 10px 22px;
    font-size: 14px;
}
.campaign_card_name{
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px;
}
.campaign_card_name a:hover{
    color: var(--turquoise);
}

.campaign_card_desc{
    max-width: 450px;
    font-size: 13px;
    margin-top: 4px;
    color: var(--grey-2);
}

.campaign_card_date{
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    color: var(--grey-1);
}
.campaign_card_date .fa-arrow-right{
    color: var(--grey-1);
    font-size: 10px;
    margin: 0 2px;
    vertical-align: 1px;
}

.campaign_card_footer{
    margin-top: 20px;
    padding-top: 16px;
    border-top: solid 1px #E9EAF0;
    text-align: right;
}
