/* CONTENT */
.container{
    z-index: 10200;
    position: fixed;
    background: var(--white);
    border: solid 1px var(--main-border-color);
    box-shadow: 0 3px 10px rgb(0, 0, 0, 0.04), 0 3px 30px rgb(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    transition: all 400ms;
}
.container_minimized {
    composes: container;
    height: 800px;
    width: 650px;
    max-width: calc(100vw - 44px);
    max-height: calc(100vh - 100px);
    bottom: 88px;
    right: 22px;
    border-radius: 20px;
}
.container_maximized {
    composes: container;
    height: auto;
    left: 0;
    right: 0;
    width: 1000px;
    margin: auto;
    max-width: calc(100vw - 44px);
    top: 0;
    bottom: 0;
    border-width: 0 1px;
}
.overlay {
    position: fixed;
    z-index: 10199;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .4);
}

.header {
    flex: 0 0 auto;
    border-bottom: solid 1px var(--main-border-color);
    padding: 6px 10px;
}
.body {
    flex: 1 1 auto;
    position: relative;
    min-height: 0;
    display: flex;
    flex-direction: column;
}
.body_inner {
    flex: 1 1 auto;
    min-height: 0;
    padding: 22px;
}
.body_bottom_gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    height: 20px;
}
.footer {
    flex: 0 0 auto;
    padding: 22px;
}
.footer_info {
    font-size: 12px;
    color: var(--grey-3);
    line-height: 1.3;
    margin-bottom: 10px;
}

/* Field */
.field_wrapper {
    background-color: #FBFCFD;
    border: solid 1px var(--main-border-color);
    border-radius: 14px;
    cursor: text;
    transition: all 200ms;
}
.field_wrapper_focus {
    composes: field_wrapper;

    border: var(--input-border-focus);
	box-shadow: inset 0 0 0 1px var(--turquoise), 0 0 0 3px var(--turquoise-light);
	background-color: #fff;
}
.field_footer {
    padding: 6px 6px 6px 12px;
}


/* MESSAGES */
.conversation {
    
}

.message {
    display: flex;
    gap: 6px;
}
.message_margin_s {
    margin-bottom: 12px;
}
.message_margin_m {
    margin-bottom: 22px;
}
.message_side_left {
    flex-direction: row;
}
.message_side_right {
    flex-direction: row-reverse;
}
.message_body {
    flex: 0 1 auto;
    max-width: calc(100% - 60px);
}
.message_actions {
    flex: 0 0 auto;
    align-self: flex-end;
}

/* Bulles */
.bulle {
    line-height: 1.4;
    border-radius: 14px;
    box-shadow: 0 1px 5px rgba(0,0,0,.06);
    text-align: left;
    white-space: pre-wrap;
    display: block;
}
.bulle_clickable {
    cursor: pointer;
    transition: all 200ms;
}
.bulle_clickable:hover,
.bulle_clickable:focus {
    background-color: var(--white);
    border-color: var(--grey-6);
}
.bulle_bot {
    composes: bulle;
    background-color: var(--main-bg-color);
    color: var(--black);
    border: solid 1px var(--main-border-color);
}
.bulle_user {
    composes: bulle;
    background-color: var(--turquoise);
    color: var(--white);
}
.bulle_proposition {
    composes: bulle;
    background-color: var(--white);
    color: var(--turquoise);
    border: solid 1px var(--turquoise);
    cursor: pointer;
}
.bulle_size_m {
    font-size: 15px;
    padding: 11px 16px;
}
.bulle_size_s {
    font-size: 13px;
    padding: 6px 10px;
}

/* Propositions */
.proposition_list {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.proposition_list .bulle {
    flex: 0 0 auto;
    max-width: 100%;
}

/* Info */
.info {
    display: flex;
    align-items: center;
}
.info_icon {
    flex: 0 0 auto;
    font-size: 9px;
    margin: 0 6px 0 4px;
}
.info_icon_info,
.info_icon_warning {
    composes: info_icon;
}
.info_icon_info {
    color: var(--turquoise);
}
.info_icon_warning {
    color: var(--color-alert);
}
.info_text {
    flex: 1 1 auto;
    font-size: 12px;
    color: var(--grey-3);
    line-height: 1.3;
}

/* Ready module */
.ready {
    display: flex;
    align-items: center;
}
.ready_col_img {
    flex: 0 0 auto;
    margin-right: 20px;
    background: #F5F6F8;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}
.ready_col_img::after {
    content: "";
    position: absolute;
    border-radius: 6px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: rgba(0,0,0,.05) 0 0 1px 1px inset;
}
.ready_col_content {
    flex: 1 1 auto;
    max-width: 400px;
}
.ready_col_actions {
    flex: 0 0 auto;
    margin-left: 20px;
}
.ready_img {
    width: 120px;
}
.ready_title {
    font-weight: 500;
}
.ready_type {
    font-weight: 500;
    font-size: 13px;
    color: var(--grey-2);
    margin-top: 2px;
}
.ready_desc {
    font-size: 13px;
    color: var(--grey-2);
    margin-top: 4px;
}
.ready_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    background-color: var(--turquoise);
    color: var(--white);
}

/* Rule */
.rule_picto {
    margin-right: 12px;
}
.rule_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: var(--turquoise);
    color: var(--white);
    font-size: 13px;
    margin-left: 16px;
}



/* Message idea */
.message_idea_group {
    margin-top: 8px
}
.message_idea {
    display: inline-block;
    line-height: 1.4;
    font-size: 13px;
    padding: 5px 14px;
    border-radius: 14px;
    box-shadow: 0 1px 5px rgba(0,0,0,.06);
    margin: 4px 0;
    background-color: var(--white);
    color: var(--turquoise);
    border: solid 1px currentColor;
    cursor: pointer;
}

/* Fake pie */
.fake_pie {
    
}
.fake_pie_title {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-2);
    margin-bottom: 10px;
}
.fake_pie_img {
    display: block;
    max-width: 100%;
    pointer-events: none;
    user-select: none;
}

/* /// Charts  */
:root{
--height_wrap : 150px;
--width_wrap : 325px;
}
.bot_chart_wrap{
    width:var(--height_wrap);
    height:var(--width_wrap);
    margin-left:50px;
}

.bot_grid_chart{
    position:relative;
    padding:5px 0 5px 0;
    height:100%;
    width:100%;
    border-left:2px;
    background:repeating-linear-gradient(90deg,transparent,transparent 19.5%);
    transform: rotate(-90deg);
}
  .bot_bar {
    height:70px;
    margin:30px 0;    
    background-color:#47ff50;
    border-radius:0 3px 3px 0;
  }
  .bot_bar:hover{
    opacity:0.7;
  }
  
  .bot_bar::after{
    margin-left:100%;
    padding:10px;
    display:inline-block;
    white-space:nowrap;
  }
    
  .bot_bar::after{
    transform: translateY(-50%) rotate(45deg);
    display: block;
  }
  .bot_bar::before,&::after{
    transform:translateX(-0.2em) rotate(90deg);
  }
.bot_chart_data{
     display: flex;
 }
.bot_chart_data_element{
    height:70px;

    }