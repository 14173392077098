.content {
    background: #FFFFFF;
    padding: 23px 30px;
    font-size: 13px;
    box-shadow: 0px 6px 49px 0px rgba(220, 220, 220, 0.9);
    border-radius: 15px;
    margin-top: 40px;
}

.title {
    font-size: 20px;
    margin-top: 0;
}

.chartSurrounding {
    padding: 40px;
    border-radius: 5px 0px 5px 5px;
    border: 1px solid #f6f7f8;
    width: 100%;
}

.displayFlex {
    /* display: flex; */
}

.actionColumn {
    position: relative;
    top: -23px;
    right: -67%;
    justify-content: space-around;
    width: 400px;
    color: #E5E5E5;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1400px) {
    .actionColumn {
        top: -23px;
        right: -64%;
    }
}

@media screen and (max-width: 1300px) {
    .actionColumn {
        top: -23px;
        right: -60%;
    }
}

@media screen and (max-width: 1180px) {
    .actionColumn {
        top: -23px;
        right: -50%;
    }
}

.actionGroupBorder {}

.divAction {
    display: flex;
    flex-direction: row;
    top: 550px;
    left: 1255px;
    width: 40px;
    height: 40px;
    background: #006fff 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
}

.actionGroupDevice {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.mobile {
    top: 550px;
    left: 1351px;
    width: 40px;
    height: 40px;
    background: #F6F7F8 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    color: #9E9E9E;
    opacity: 1;
    padding: 10px;
}

.desktop {
    top: 550px;
    left: 1310px;
    width: 40px;
    height: 40px;
    background: #F6F7F8 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    color: #9E9E9E;
    opacity: 1;
    padding: 10px;
}

.mobile:hover {
    color: #006fff;
}

.desktop:hover {
    color: #006fff;
}

.absoluteValue {
    top: 550px;
    left: 1310px;
    width: 40px;
    height: 40px;
    background: #F6F7F8 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    color: #9E9E9E;
    opacity: 1;
    padding: 10px;
}

.absoluteValueSelected {
    top: 550px;
    left: 1351px;
    width: 40px;
    height: 40px;
    color: #FFFFFF;
    background: #006fff 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    opacity: 1;
    padding: 10px;
}

.absoluteValue:hover {
    color: #006fff;
}

.percentValue {
    top: 550px;
    left: 1351px;
    width: 40px;
    height: 40px;
    background: #F6F7F8 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    color: #9E9E9E;
    opacity: 1;
    padding: 10px;
}

.percentValue:hover {
    color: #006fff;
}

.actionSelectedPercent {
    top: 550px;
    left: 1351px;
    width: 40px;
    height: 40px;
    color: #FFFFFF;
    background: #006fff 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
    padding: 10px;
}

.actionGroup * {
    cursor: pointer;
}

.actionGroup>div {
    color: #9e9e9e;
    padding: 10px 7px;
    white-space: nowrap;
    background: #FFF;
    transition: all .3s ease-in-out;
}

.actionGroup>div:hover {
    color: #006fff;
}

.actionSelectedMobile {
    top: 550px;
    left: 1351px;
    width: 40px;
    height: 40px;
    color: #FFFFFF;
    background: #006fff 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    opacity: 1;
    padding: 10px;
}

.actionSelectedDesktop {
    top: 550px;
    left: 1310px;
    width: 40px;
    height: 40px;
    color: #FFFFFF;
    background: #006fff 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
    padding: 10px;
}

.addButton {
    top: 550px;
    left: 1512px;
    width: 131px;
    height: 40px;
    background: #006fff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFFFFF;
    opacity: 1;
    padding: 10px;
}

/* .actionGroup>div.actionSelected:hover, .actionGroup>div.actionSelected {
    background: #006fff;
    color: #FFF;
} */

/* .actionGroup>div strong {
    margin-right: 15px;
}

.actionGroup>div>i {
    margin-right: 15px;
} */