.list-mapping-tooltip {
    background-color: rgba(255, 255, 255, 0.95); /* Fond blanc légèrement opaque */
    border: 1px solid #ccc; /* Bordure grise */
    border-radius: 8px; /* Coins arrondis */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce */
    padding: 10px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    color: #333;
}

.list-mapping-header {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    color: #555;
}

.list-mapping-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.list-mapping-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.list-mapping-label {
    flex-basis: 40%;
    font-weight: bold;
}

.list-mapping-studied {
    flex-basis: 30%;
}

.list-mapping-compared {
    flex-basis: 30%;
    text-align: right;
    color: #666; /* Couleur plus douce pour les comparaisons */
}
