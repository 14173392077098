.productCard_list{
    margin-left: -16px;
    padding: 22px;
}
.productCard_pagin{
    padding: 16px 22px;
    border-top: solid 1px var(--main-border-color);
}
.productCard_container {
    width: 221px;
    margin-right: 16px;
    margin-bottom: 25px;
    cursor: pointer;
	position: relative;
}

.productCard_container .btn_wrapper{
	display: none;
}

.productCard_container:hover .btn_wrapper{
	display: block;
}

.productCard_container .btn_wrapper .btn_ghost_secondary {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 31px;
	height: 31px;
	border-radius: 50%;
	background-color: #F7F7FA;
}

.productCard_container .btn_wrapper .btnUi {
	font-size: 14px;
}
.productCard_image_wrapper {
    background-color: #FFFFFF;
    background-image: url(/Assets/product_collection_no_photo.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 221px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,.02);
}
.productCard_image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}