/* Base */
.item {
	position: relative;
	background-color: var(--grey-7);
	overflow: hidden;
}
/* .item + .item {
	margin-top: 10px;
} */

/* Groups */
.box {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.flex {
	display: flex;
	align-items: center;
	gap: 12px;
}
.flex .square,
.flex .round {
	flex: 0 0 auto;
}
.flex .box {
	flex: 1 1 auto;
}
.inline {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 12px;
}
.inline .box {
	flex: 0 0 auto;
	width: 160px;
}
.stack {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

/* Shape and size */
.bar {
	composes: item;
	border-radius: 5px;
}
.bar_height_l {
	height: 20px;
}
.bar_height_m {
	height: 10px;
}
.bar_height_s {
	height: 6px;
}
.bar_height_xs {
	height: 4px;
}
.bar_width_l {
	width: 66.66%;
}
.bar_width_m {
	width: 50%;
}
.bar_width_s {
	width: 33.33%;
}
.bar_width_xs {
	width: 16.66%;
}

/* Square */
.square {
	composes: item;
	border-radius: 20%;
}
.square_width_m {
	width: 32px;
	height: 32px;
}
.square_width_l {
	width: 48px;
	height: 48px;
}

/* Round */
.round {
	composes: item;
	border-radius: 100%;
}
.round_width_m {
	width: 32px;
	height: 32px;
}
.round_width_l {
	width: 48px;
	height: 48px;
}

/* Rect */
.rect {
	composes: item;
	border-radius: 10px;
}
.rect_width_s {
	width: 32px;
}
.rect_width_m {
	width: 64px;
}
.rect_width_l {
	width: 96px;
}
.rect_width_xl {
	width: 128px;
}
.rect_height_s {
	height: 32px;
}
.rect_height_m {
	height: 64px;
}
.rect_height_l {
	height: 96px;
}
.rect_height_xl {
	height: 128px;
}

/* Animation */
.item_inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transform: translateX(-100%);
	background: linear-gradient(
		90deg,
		transparent,
		rgba(0, 0, 0, 0.04),
		transparent
	);
	animation: item_inner_animate_wave 2s linear 0.5s infinite;
}
@keyframes item_inner_animate_wave {
	0% {
		transform: translateX(-100%);
	}
	50% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(100%);
	}
}
