.active {
    color: black;
    cursor: pointer;
}

.innactive {
    color: #848484;
}

.pageItem {
    cursor: pointer;
}

.containerBtn {
    display: flex;
    justify-content: space-between;
}