.reco_row {
    display: grid;
    grid-template-columns: 2fr 2fr 120px 48px;
    align-items: center;
}
.reco_head_row {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-2);
    text-transform: uppercase;
    white-space: nowrap;
}
.reco_body_row {
    padding: 8px 0;
}
.reco_col {
    
}
.reco_col + .reco_col {
    padding-left: 16px;
}

.reco_actions {
    padding-top: 8px;
}