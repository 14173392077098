.contentAccountParametersEdition {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 0;
    background-color: #FFF;
    width: 70%;
    height: 100%;
    padding: 70px 25px 0 25px;
    color: #707070;
    font-family: "Vision", "Roboto", Arial, sans-serif;
    transition: all .8s ease-in-out;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.33);
    overflow-y: auto;
}

.account_param_nav_tooling {
    top: 70px;
    right: 20px;
    width: 60%;
    padding: 0px 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 42px;
    margin: auto;
}

.account_param_nav_tooling ul {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.account_param_nav_tooling ul li {
    display: flex;
    justify-content: space-around;
    padding: 0px 10px;
    margin-right: 10px;
    background: #FFF;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #9E9E9E;
    cursor: pointer;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.account_param_nav_tooling_last-item {
    margin-left: auto;
}

.account_param_nav_tooling ul li i {
    font-size: 18px;
    margin-top: 11px;
}

.account_param_nav_tooling ul li p {
    margin-left: 5px;
}

.account_param_nav_tooling ul li:hover {
    border-color: #006fff;
}

.account_param_nav_tooling ul li.active {
    border-color: #006fff;
    color: #006fff;
}

.btn_close_modal_parameterson {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
}