
.Crop-Controls {
	margin-bottom: 10px;
}
.Crop-Controls > * {
	margin-bottom: 3px;
}
  
  img {
	max-width: 100%;
  }