.list {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
}
.item {
    padding: 0;
    margin: 0;
}
.chevron {
    padding: 0;
    color: var(--grey-5);
    font-size: 13px;
    margin: 0 10px;
}
