.header {
	position: relative;
    box-sizing: content-box;
    min-height: 44px;
    padding: 12px var(--main-h-padding);
    display: flex;
    align-items: center;
}
.header.has_border {
	border-bottom: solid 1px var(--main-border-color);
}

/* Columns */
.col_left{
    flex: 1 1 50%;
	display: flex;
	align-items: center;
}
.col_center{
    margin: 0 30px;
    flex: 0 0 auto;
}
.col_right{
    flex: 1 1 50%;
    text-align: right;
}

/* Back button */
.left_back{
	flex: 0 0 auto;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    border-radius: 32px;
    padding: 2px 6px 2px 4px;
    margin: 0 -6px 0 -4px;
    transition: all 200ms;
}
.left_back:hover,
.left_back:focus{
    background-color: var(--main-border-color);
}
.left_back .left_back_arrow,
.left_back .left_back_icon{
    vertical-align: middle;
}
.left_back .left_back_arrow{
    font-size: 13px;
    color: var(--grey-1);
}

/* Left content */
.left_content {
	flex: 1 1 auto;
	margin-left: 4px;
}
.left_title {
	font-size: 18px;
	font-weight: 500;
	color: var(--black);
	line-height: normal;
	margin-left: 8px;
}
