/* Selection header */
.report_generator_selection_header{
	background: #F5F6F8;
	border: solid 1px var(--main-border-color);
	border-width: 1px 0;
	margin-top: -1px;
	color: var(--grey-2);
	font-weight: 500;
	font-size: 13px;
	text-transform: uppercase;
}
.report_generator_selection_header > div{
	padding: 8px 30px 6px;
	line-height: 1.3;
	width: 45%;
}
.report_generator_selection_header > div:nth-child(2){
	border-left: solid 1px var(--main-border-color);
	width: 55%;
}

/* Selection body */
.report_generator_selection{
	overflow: hidden;
}
.report_generator_selection_part{
	width: 65%;
}
.report_generator_selection_part:nth-child(1){
	width: 35%;
	background-color: var(--main-bg-color);
}
.report_generator_selection_sep{
	border-left: solid 1px var(--main-border-color);
	width: 0;
}
.report_generator_selection_section_top {
	padding: 16px 30px;
	border-bottom: solid 1px var(--main-border-color);
}
.report_generator_selection_section_bottom {
	padding: 10px 30px 20px;
}
.report_generator_selection_title {
	text-transform: uppercase;
	color: var(--grey-2);
	font-size: 13px;
	font-weight: 500;
}

/* List */
.report_generator_list_col_kpi {
	width: 80px;
}
.report_generator_list_col_engagement {
	width: 160px;
}
.report_generator_list_btn {
	background-color: var(--main-bg-color);
	border: solid 1px var(--main-border-color);
	border-radius: 3px;
	font-size: 13px;
	font-weight: 500;
	color: var(--grey-2);
	padding: 2px 4px;
}
.report_generator_list_btn.has_error {
	color: var(--color-alert);
	border-color: currentColor;
	background-color: var(--color-alert-light);
}

/* Info */
.report_generator_info{
	overflow: visible !important;
}

/* KPI */
.report_kpi {
	border: solid 1px var(--main-border-color);
	padding: 5px 6px;
	line-height: 20px;
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 8px;
	border-radius: 6px;
	vertical-align: middle;
}
.report_kpi_label {
	display: inline-block;
	vertical-align: middle;
}
.report_kpi_icon {
	margin: -6px -6px -6px 0;
}
