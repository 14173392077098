.wrapper {
    flex: 0 0 auto;
    margin-right: 12px;
    width: 60px;
    height: 60px;
    background-color: var(--main-bg-color);
    background-image: url(/Assets/product_collection_no_photo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    border: solid 1px var(--main-border-color);
    border-radius: 4px;
    overflow: hidden;
}
.img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-size: 11px;
}