.selectOptionTags {
    width: 100%;
    outline: none;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.containerProuctTagsOnAccount {
    display: flex;
    flex-direction: column;
}