.title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 10px;
}

.section {
    margin-bottom: 30px;
}
.sectionTitle {
    font-size: 16px;
}

.sectionEmpty {
    border: solid 1px var(--main-border-color);
    box-shadow: rgba(0,0,0,.04) 0 2px 7px;
    background-color: var(--white);
    border-radius: 8px;
    padding: 18px;
}

.moduleGrid {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin-top: 12px;
}
.moduleCommon {
    display: flex;
    align-items: center;
    border: solid 1px var(--main-border-color);
    box-shadow: rgba(0,0,0,.04) 0 2px 7px;
    border-radius: 8px;
    padding: 18px;
    position: relative;
}
.module {
    composes: moduleCommon;
    background-color: var(--white);
}
.moduleClickable {
    composes: moduleCommon;
    background-color: var(--white);
    cursor: pointer;
    transition: all 200ms;
    top: 0;
}
.moduleClickable:hover,
.moduleClickable:focus {
    box-shadow: rgba(0,0,0,.08) 0 4px 10px;
    border-color: var(--grey-5);
}
.moduleIcon {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    margin-right: 18px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: var(--grey-8);
    color: var(--grey-3);
}
.moduleLabel {
    flex: 1 1 auto;
    white-space: pre-line;
}
.modulePermission {
    font-size: 12px;
    color: var(--grey-3);
}
.moduleArrow {
    color: var(--grey-4);
    margin-left: 14px;
}
.moduleBtnWrapper {
    margin-top: 3px;
    margin-left: -6px;
}

/* Icon color */
.moduleIcon_blue {
    background-color: #edeefd;
    color: #4e58f7;
}
.moduleIcon_yellow {
    background-color: #fff7cd;
    color: #b49600;
}
.moduleIcon_turquoise {
    background-color: #c9fff1;
    color: #009c77;
}
.moduleIcon_orange {
    background-color: #ffe4d1;
    color: #FE6600;
}
.moduleIcon_cyan {
    background-color: #e0f9ff;
    color: #01a5d5;
}
.moduleIcon_red {
    background-color: #ffe0df;
    color: #f95b5c;
}
.moduleIcon_pink {
    background-color: #ffe1f2;
    color: #f01f86;
}
.moduleIcon_purple {
    background-color: #f7e5ff;
    color: #a43bcf;
}
.moduleIcon_green {
    background-color: #e3ffe6;
    color: #009f10;
}