/* Intro */
.graphic_patch_intro{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.graphic_patch_intro_inner {
	text-align: center;
	padding: 40px 40px 80px;
}
.graphic_patch_hero_icon{
	font-size: 70px;
	color: #E2E4ED;
	margin-bottom: 20px;
}
.graphic_patch_hero_icon i{
	width: 100px;
}

.graphic_patch_title {
	font-weight: 500;
	font-size: 21px;
}
.graphic_patch_description {
	margin-top: 4px;
	font-size: 17px;
	color: var(--grey-3);
}

.graphic_patch_form{
	width: 550px;
	max-width: 100%;
	margin-top: 10px;
}

/* Outro */
.graphic_patch_outro_outer {
	min-height: 100%;
    padding: 40px 40px 80px;
    background-color: var(--main-bg-color);
}
.graphic_patch_outro_inner {
	max-width: 620px;
	margin: 0 auto;
}

.graphic_patch_outro_header{
	margin-bottom: 12px;
}

.graphic_patch_el_tag_list {
	white-space: normal;
	text-align: right;
	margin-bottom: -4px;
}
.graphic_patch_el_tag{
	display: inline-block;
	font-size: 13px;
	line-height: 16px;
	padding: 2px 5px;
	border-radius: 4px;
	margin-bottom: 4px;
	color: var(--turquoise);
	background-color: var(--turquoise-light);
}
.graphic_patch_el_tag + .graphic_patch_el_tag {
	margin-left: 6px;
}

.graphic_patch_picto_notif_wrapper {
	position: relative;
	display: block;
}
.graphic_patch_picto_notif {
	position: absolute;
	background-color: #FFC400;
	color: #FCF4DA;
	width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 24px;
	top: -10px;
	left: -10px;
	border: solid 2px var(--white);
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
}