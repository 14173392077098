.header-category-of-account-list {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede66;
    width: 90%;
    margin: 5px auto;
}

.product-list-category {
    width: 90%;
    margin: auto;
}

.cat-chevron {
    cursor: pointer;
}

.name-zone-chevron {
    font-weight: bold
}

.clipItem {
    cursor: pointer;
    font-size: 18px;
}

.header-category-of-account-list.active-category i {
    margin-right: 15px;
}

.copyToClip {
    color: #006fff;
}

.cat-item {
    display: flex;
    width: 100%;
    margin: 3px auto;
    justify-content: space-between;
}