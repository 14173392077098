.content {
    max-width: 1200px;
    margin: auto;
}

.profilContainer {
    border-radius: 15px;
    box-shadow: 0px 6px 49px 0px rgba(220, 220, 220, 0.9);
    overflow: hidden;
}

.intro {
    font-size: 13px;
    width: 795px;
}

.title {
    font-size: 30px;
}

.firstLine {
    font-weight: 700;
    margin-bottom: 20px;
}

.signalsShell {
    margin-bottom: 30px;
}

.inAppMenu {
    position: absolute;
    right: 240px;
    top: 5px;
}

.actionButtons {
    position: absolute;
    top: 50px;
    right: 20px;
}

.actionButtons button {
    font-size: 16px;
    margin-right: 10px;
}

.cancelButton {
    border: 1px solid #9E9E9E;
    color: #9E9E9E;
    background: #F6F7F8;
    padding: 6px 25px;
    transition: all .3s ease-in-out;
}

.cancelButton:hover {
    border: 1px solid #006fff;
    color: #006fff;
}

.saveButton {
    background: #006fff;
    color: #FFF;
    padding: 7px 25px;
}

.saveButtonDisable {
    background: #a6a8a8;
    color: #FFF;
    padding: 7px 25px;
    cursor: progress;
}