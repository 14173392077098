.dashboardTable {
    display: -ms-grid;
    display: grid;
    font-family: "IBM Plex Sans", "Roboto", Arial, sans-serif;
}

.dashboardTableOnsite {}

.dashboardTableOnsite .dashboardRow {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 2.1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 2.1fr 1fr 1fr 1fr 1fr;
    padding: 0;
    background: #FFF;
    transition: all .1s ease-in-out;
}

.campaignSubNav.subNav>button {
    padding: 0;
    color: #9E9E9E;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.campaignSubNav.subNav .campaignSubNavVisible {
    cursor: pointer;
    color: #F6F7F8;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.dashboardTableOnsite .dashboardRow:hover {
    background: #f6f7f8;
}

.dashboardTableOnsite .dashboardRow>div {
    padding: 10px;
}

.dashboardTableOnsite .dashboardRow:hover .campaignSubNav.subNav .campaignSubNavVisible {
    color: #9E9E9E;
}

.campaignSubNav.subNav .campaignSubNavVisible:hover, .dashboardTableOnsite .dashboardRow:hover .campaignSubNav.subNav .campaignSubNavVisible:hover, .campaignSubNav.subNav>button:hover {
    color: #006fff;
}

.dashboardTable .campaign-status .label {
    min-width: 60px;
    vertical-align: top;
}

.dashboardTable .campaign-test {
    display: inline-block;
}

.dashboardTable .campaign-test span {
    background: #F6F7F8;
    color: #9E9E9E;
    border: 1px solid #F6F7F8;
    font-size: 11px;
    font-weight: 500;
    margin-top: 5px;
    display: inline-block;
    text-align: center;
    transition: all .2s ease-in-out;
}

.dashboardTable .campaign-test span i {
    width: auto;
}

.dashboardTable .campaign-date {
    font-size: 14px;
}

.dashboardTable .campaign-date span {
    color: #b3b3b3;
    font-size: 14px;
}

.dashboardTable .campaign-description {
    color: #b3b3b3;
    font-size: 14px;
}

.dashboardTable>div {
    padding: 10px;
}

.dashboardTable .gridHead {
    cursor: pointer;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background: #F6F7F8;
    font-size: 12px;
    font-weight: 700;
    color: #293133;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
}

.dashboardTable .gridHead:hover {
    color: #006fff;
}

.dashboardTable .gridHead i {
    color: #006fff;
}

.dashboardTable .gridCampaign {
    border-top: 1px solid #EDEDED;
}

.dashboardTable .campaignTitle {
    font-weight: 600;
}

.urldebug {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 3px 0;
}

.urldebug div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #F6F7F8;
    color: #9E9E9E;
    font-size: 11px;
    border-radius: 3px;
    margin: 3px 8px 3px 0;
}

.urldebug div a {
    border: 1px solid #EDEDED;
    color: #9E9E9E;
    padding: 5px;
    border-radius: 3px 0 0 3px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    white-space: nowrap;
}

.urldebug div a i {
    font-size: 10px;
}

.urldebug i {}

.urldebug div button {
    border: 1px solid #EDEDED;
    border-left: none;
    padding: 5px 3px;
    border-radius: 0 3px 3px 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.urldebug div a:hover, .urldebug div button:hover {
    background: #006fff;
    color: #FFF;
}

.urldebug div button span {
    position: absolute;
    opacity: 0;
    z-index: -999999;
    left: -10000px;
}

.urldebug div button.copy_success {
    background: #5cb85c;
    color: #FFF;
}

.subNav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}



.subNav ul {
    position: absolute;
    z-index: 9;
    top: 100px;
    right: 0;
    display: -webkit-flex;
    display: flex;
    visibility: hidden;
    padding: 10px 9px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    opacity: 0;
    color: #FFFFFF;
    border-radius: 3px;
    background: #66d6d7;
}

.subNav ul:after {
    content: "";
    position: absolute;
    top: -6px;
    right: 3px;
    width: 0;
    height: 0;
    border-width: 0 6.5px 7px 6.5px;
    border-style: solid;
    border-color: transparent transparent #66d6d7 transparent;
}

.subNav li {
    font-size: 18px;
    position: relative;
    display: inline-block;
    padding: 0 8px;
    text-align: center;
    cursor: pointer;
}

.subNav li a {
    color: #FFFFFF;
}

.subNav li:hover a {
    color: #FFFFFF;
}

.subNav li span {
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    position: absolute;
    z-index: 200;
    top: 400%;
    left: -10px;
    display: inline-block;
    visibility: hidden;
    padding: 10px 20px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    white-space: nowrap;
    opacity: 0;
    border-radius: 5px;
    background: #444444;
}

.subNav li span:after {
    content: "";
    position: absolute;
    top: -7px;
    left: 20px;
    width: 0;
    height: 0;
    border-width: 0 6.5px 7px 6.5px;
    border-style: solid;
    border-color: transparent transparent #444444 transparent;
}

.subNav li:hover span {
    top: 200%;
    visibility: visible;
    opacity: 1;
}

.subNav.subNavOpen button i {
    color: #006fff;
}

.subNav.subNavOpen ul {
    top: 27px;
    visibility: visible;
    opacity: 1;
}

.CogwheelMenu {
    color: #9E9E9E;
    cursor: pointer;
}

.CogwheelMenu:hover {
    color: #006fff;
    cursor: pointer;
}

.NotFilterable:hover {
    cursor: text!important;
    color: #293133!important;
}

.containerBulletName {
    display: flex;
}

.containerBulletName a {
    margin-right: 10px;
}

.campaignDateUpdate {
    font-weight: 600;
}

.campaignId p {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}