/* Table */
.table .table_head_row,
.table .table_body_row {
	grid-template-columns: 2.2fr .8fr .6fr .7fr 140px 140px 50px;
}
.table .table_head_row {
	font-size: 12px;
	line-height: 1.23;
	align-items: flex-end;
}
.table .table_body_row {
	font-size: 15px;
}
.table_col:nth-child(3),
.table_col:nth-child(4),
.table_col:nth-child(5),
.table_col:nth-child(6),
.table_col:nth-child(7){
	justify-content: flex-end;
    text-align: right;
}

/* CPG infos */
.name {
	font-weight: 500;
	cursor: pointer;
}
.desc {
	font-size: 13px;
	color: var(--grey-2);
	margin-top: 3px;
}
.label_list {
	margin-top: 6px;
}
.status_bullet {
	margin-left: -16px;
	margin-right: 8px;
}
.picto {
	margin-right: 16px;
}

/* Values */
.value {

}
.value_wrapper {
	flex: 1 1 auto;
	min-width: 0;
}
.value_wrapper_disabled {
	composes: value_wrapper;
}
.value_desc {
	font-size: 13px;
	color: var(--grey-2);
	margin-top: 2px;

	text-overflow: ellipsis;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}
.value_disabled .value,
.value_disabled .value_desc {
	color: var(--grey-4);
}
.value_up .value{
	color: var(--color-success);
	font-weight: 500;
}
.value_down .value{
	color: var(--color-alert);
	font-weight: 500;
}

/* Card */
.card {
	min-width: 90px;
	max-width: 110px;
	padding:  10px 8px;
	border-radius: 10px;
	background: var(--main-bg-color);
	color: var(--black);
	text-align: center;
	box-shadow: var(--white) 0 0 0 2px;
}
.card_up {
	color: var(--color-success);
	background-color: var(--color-success-light);
}
.card_down {
	color: var(--color-alert);
	background-color: var(--color-alert-light);
}
.card_value {
	font-size: 19px;
	font-weight: 500;
}
.card_desc {
	font-size: 12px;
	text-overflow: ellipsis;
	display: block;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}