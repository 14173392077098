
.sidebar{
	width: 80px;
	height: 100%;
	background-color: var(--white);
}

/* Tabs */
.tabs {
    margin-bottom: 20px;
}
.tabs_small {
	composes: tabs;
}

/* Tab */
.tab_common {
	display: block;
	padding: 9px 6px;
}
.tabs_small .tab_common {
	padding: 7px 6px;
}
.tab,
.tab_selected,
.tab_disabled {
    composes: tab_common;
}

/* Picto */
.tab_picto {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	margin: auto;
	background-color: var(--main-bg-color);
	border-radius: 8px;
}
.tab_picto_round {
	composes: tab_picto;
	border-radius: 44px;
}
.tabs_small .tab_picto {
	width: 38px;
	height: 38px;
}
.tab .tab_picto {
	transition: background-color 200ms;
}
.tab:hover .tab_picto,
.tab:focus .tab_picto {
	background-color: var(--main-border-color);
}
.tab_selected .tab_picto {
    background-color: var(--turquoise);
}

/* Name */
.tab_name {
	display: block;
	margin-top: 5px;
	line-height: 13px;
	font-size: 11px;
	text-align: center;
	color: var(--grey-0);
}
