.imagetransformationsContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    min-height: 500px;
    overflow: auto;
}

.imagetransformationsContainer img {
    display: block;
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 250px;
    margin: 4px;
}

.imageTransformSelected {
    border: 3px solid rgb(42, 133, 156);
    cursor: default;
    background-color: rgba(0, 92, 228, 0.15);
    color: rgb(0, 0, 0);
    box-shadow: rgb(0 92 228) 0px 0px 0px 3px;
}