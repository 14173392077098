.anwser_list {
	margin: 4px 0 20px;
}
.anwser_row {
	display: flex;
    align-items: center;
	margin-bottom: 14px;
}
.anwser_col_nb {
	flex: 0 0 auto;
	font-weight: 500;
	color: var(--grey-3);
	margin-right: 10px;
}
.anwser_col_input {
	flex: 0 1 auto;
	width: 380px;
}
.anwser_col_select {
	flex: 0 1 auto;
	width: 300px;
}
.anwser_col_actions {
	flex: 0 0 auto;
	margin-left: 10px;
}
