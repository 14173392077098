.listPagesType {
	display: flex;
	flex-wrap: wrap;
	margin-left: -35px;
}

.elementPageType {
	flex: 0 1 auto;
	width: 310px;
	position: relative;
	margin-left: 35px;
	margin-bottom: 25px;
	cursor: pointer;
}

.elementPageTypeImg {
	height: 175px;
	border: solid 1px #ddd;
	border-radius: 4px;
	background: #eee;
	position: relative;
	overflow: hidden;
	transition: border 100ms;
}

.elementPageType:hover .elementPageTypeImg {
	border-color: #bbb;
}

.elementPageTypeImg::after {
	content: "";
	pointer-events: none;
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: solid 3px transparent;
	transition: border 100ms;
}

.elementPageType.selected .elementPageTypeImg, .elementPageType.selected .elementPageTypeImg::after {
	border-color: #5CB85C;
}

.elementPageTypeImg img {
	display: block;
	width: 100%;
}

.elementPageTypeTxt {
	margin-top: 4px;
	text-align: center;
}

.navPagesType {
	text-align: right;
	margin-top: 10px;
}

.elementPagesType-devices {
	position: absolute;
	top: 6px;
	right: 10px;
	font-size: 20px;
	display: flex;
	justify-content: space-between;
	color: #2f2f2f;
}