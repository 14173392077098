.cpg_informations_outer{
    padding: 40px;
}
.cpg_informations_inner {
    max-width: 630px;
    margin: 0 auto;
}


.cpg_ip{
    border: solid 1px var(--main-border-color);
    border-radius: 5px;
}
.cpg_ip_row{
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    column-gap: 10px;
    align-items: center;
    padding: 8px 16px;
}
.cpg_ip_row + .cpg_ip_row{
    border-top: solid 1px var(--main-border-color);
}
.cpg_ip_col{

}




.option_item {
    padding: 15px;
    border: 2px solid black;
    margin-top: 10px;
}

.option_header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(182, 182, 182);
}

.options_description {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(182, 182, 182);
}

.option_IP_item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(182, 182, 182);
}