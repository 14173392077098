.simplify_orch_inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 22px 0 0 22px;
}

.simplify_orch_header {
	padding-bottom: 16px;
	border-bottom: solid 1px var(--main-border-color);
}
.simplify_orch_header_left,
.simplify_orch_header_right{
	width: 50%;
}
.simplify_orch_header_center{
	margin: 0 30px;
}
.simplify_orch_title{
	font-weight: 500;
	font-size: 16px;
}

/* Sidebar */
.simplify_orch_sidebar{
	width: 375px;
	padding: var(--main-h-padding);
	padding-left: 0;
	border-right: solid 1px var(--main-border-color);
}

/* Preview */
.simplify_orch_preview{
	background: #F5F6F8;
	padding: var(--main-h-padding);
}
.simplify_orch_preview_inner{
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	margin: 0 auto;
	box-shadow: rgba(0,0,0,.04) 0 0 30px, rgba(0,0,0,.08) 0 0 3px;
}
.simplify_orch_preview_inner.mobile{
	width: 390px;
}







/* Selection header */
.simplify_orch_selection_header{
	background: #F5F6F8;
	border: solid 1px var(--main-border-color);
	border-width: 1px 0;
	margin-top: -1px;
	color: var(--grey-2);
	font-weight: 500;
	font-size: 13px;
	text-transform: uppercase;
}
.simplify_orch_selection_header > div{
	padding: 8px 30px 6px;
	line-height: 1.3;
	width: 45%;
}
.simplify_orch_selection_header > div:nth-child(2){
	border-left: solid 1px var(--main-border-color);
	width: 55%;
}

/* Selection body */
.simplify_orch_selection{
	overflow: hidden;
}
.simplify_orch_selection_part{
	padding: 20px 30px;
	width: 55%;
}
.simplify_orch_selection_part:nth-child(1){
	width: 45%;
}
.simplify_orch_selection_sep{
	border-left: solid 1px var(--main-border-color);
	width: 0;
}

.simplify_orch_list{
	margin: 0 -12px;
	color: var(--grey-0);
}
.simplify_orch_list_item{
	padding: 12px;
	border-radius: 6px;
	border-bottom: solid 1px var(--main-border-color);
	transition: background 200ms;
}
.simplify_orch_list_item[isdragging=y]{
	background: #F5F6F8;
	border-radius: 4px;
}
.simplify_orch_list_item.s{
	padding: 8px 12px;
}
.simplify_orch_list_item.clickable{
	cursor: pointer;
}
.simplify_orch_list_item.clickable:hover{
	background: #F5F6F8;
}
.simplify_orch_list_item_name{
	font-weight: 500;
}
.simplify_orch_list_item_desc{
	font-size: 13px;
	color: var(--grey-2);
	margin-bottom: 3px;
}

.simplify_orch_list_item_arrow{
	color: var(--grey-2);
}

/* Info */
.simplify_orch_info{
	overflow: visible !important;
}