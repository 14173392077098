.media_gallery{
    height: 600px;
    position: relative;
}
.media_gallery_list{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

/* Item */
.media_gallery_item{
    position: relative;
    flex: 0 0 auto;
    width: calc(20% - 16px);
    border: solid 1px var(--main-border-color);
    border-radius: 5px;
}
.media_gallery_img_wrapper{
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.media_gallery_img{
    max-width: 100%;
    max-height: 100%;
    background-color: #f5f5f5;
    background-image: linear-gradient(45deg, #e5e5e5 25%, transparent 25%, transparent 75%, #e5e5e5 75%), linear-gradient(45deg, #e5e5e5 25%, transparent 25%, transparent 75%, #e5e5e5 75%);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
}
.media_gallery_item_info{
    padding: 8px;
}
.media_gallery_item_name{
    font-size: 13px;
    font-weight: 500;
    color: var(--grey-0);
}
.media_gallery_item_action{
    margin-left: 2px;
    margin-right: -4px;
}

/* Message */
.media_gallery_created_picto{
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-success);
    color: #fff;
    font-size: 19px;
    margin-right: 20px;
}

.media_gallery_created_title{
    font-size: 18px;
    color: var(--color-success);
    font-weight: 500;
}

.media_gallery_created_desc{
    font-size: 13px;
    margin-top: 2px;
    color: var(--grey-2);
}

/* Message */
.media_gallery_message {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 8px 20px;
    font-weight: 500;
    border-radius: 5px;
    color: #FFF;
    text-align: center;
}
.media_gallery_message.anim_show{
    animation: media_gallery_message 200ms;
}
.media_gallery_message.anim_hide {
    animation: media_gallery_message_r 200ms;
}
.media_gallery_message.is_success {
    background: var(--color-success);
}
.media_gallery_message.is_error {
    background: var(--color-alert);
}

@keyframes media_gallery_message {
  0% {
    opacity: 0;
    transform: translate(-50%, -40px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes media_gallery_message_r {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -40px);
  }
}