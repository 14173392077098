/* LIST */
.list{
	margin: 0;
	padding: 0;
}

/* ITEM */
.item_wrapper {
	display: flex;
}
.item_indent {
	flex: 0 0 auto;
	width: 30px;
	height: 34px;
	background: url('/Assets/submenu-indent.svg') no-repeat;
}
.item_wrapper:last-child .item_indent {
	background-position: -30px 0;
}
.item{
	flex: 1 1 auto;
	min-width: 0;
	position: relative;
	cursor: pointer;
	transition: all 200ms;
	user-select: none;
    border-radius: 6px;
	color: var(--grey-2);
}
.item_default:not(.disabled):hover,
.item_default:not(.disabled):focus{
	background: #f5f6f8;
}
.item_default:not(.disabled):active{
	background: #F0F1F5;
}
.item_selected{
	background: var(--turquoise-light);
	box-shadow: #d9eaff inset 0 0 0 1px;
	color: var(--turquoise);
}
.item.disabled {
	color: var(--grey-5);
	cursor: default;
}
.item_icon{
	width: 30px;
	padding-left: 10px;
	font-size: 20px;
	display: flex;
	align-items: center;
}
.item_icon i{
	display: block;
}
.item_text{
	font-size: 14px;
	transition: opacity 300ms;
	padding-left: 10px;
	padding-right: 10px;
}
.item_level_1 .item_text{
	font-weight: 500;
	line-height: 42px;
}
.item_level_2 .item_text{
	font-weight: 400;
	line-height: 34px;
}
.item.selected::before{
	background: var(--turquoise);
}
.item_arrow {
	flex: 0 0 auto;
	margin-left: 8px;
	margin-right: 8px;
	color: var(--grey-3);
	font-size: 12px;
	transition: transform 300ms ease-out;
}
.item_arrow_open {
	composes: item_arrow;
	transform: rotateZ(180deg);
}

.item_skeleton {
	flex: 1 1 auto;
	align-self: center;
	padding-left: 10px;
	margin-top: 3px;
}

/* HEADER */
.header{
	min-height: 56px;
	padding: 7px 0;
	overflow: hidden;
	background: #fff;
}
.header_picto{
	width: 40px;
	height: 40px;
	border-radius: 40px;
	margin: 0 10px 0 11px;
	cursor: pointer;
	transition: all 200ms;
	padding: 0;
}
.header_picto:hover,
.header_picto:focus{
	background: #f5f6f8;
}
.header_picto i{
	display: block;
	margin: 8px;
}
.header_logo{
	cursor: pointer;
}
.header_logo img{
	display: block;
}

/* BODY */
.body{
	overflow-x: hidden;
}

/* FOOTER */
.footer {
	flex: 0 0 auto;
	margin-top: 8px;
	margin-bottom: 8px;
}

/* Switch */
.switch_btn {
	display: block;
	text-align: center;
	cursor: pointer;
	transition: background-color 200ms;
	padding: 9px 6px;
}
.switch_btn:focus,
.switch_btn:hover {
	background-color: var(--main-bg-color);
}
.switch_btn_text {
	display: block;
	margin-top: 5px;
	line-height: 13px;
	font-size: 11px;
	text-align: center;
	color: var(--grey-0);
}
