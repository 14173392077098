.filters_line {
    margin-bottom: 12px;
}
.grid :global(.table_row) {
    font-size: 14px;
}
.grid_cell_tooltip_table {
    text-align: left;
}
.grid_cell_tooltip_table :global(td){
    vertical-align: middle;
}
.grid_cell_tooltip_table :global(td:not(:first-child)){
    padding-left: 10px;
}

.article {
    margin-bottom: 12px;
}