/* Format list */
.format_list {
	display: flex;
	gap: 12px;
}
.format_item{
	flex: 0 1 25%;
}
.format_item_link {
	display: block;
	border-radius: 8px;
	border: solid 1px var(--main-border-color);
	background: #fff;
	transition: all 200ms;
	color: var(--grey-0);
}
.format_item_link_active{
	composes: format_item_link;
	background-color: var(--turquoise-light);
	border-color: var(--turquoise);
	color: var(--turquoise);
}
.format_item_img {
	width: 100%;
}
.format_item_label {
	display: block;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	padding: 6px 8px;
}

.format_inpage_wrapper {
	border-top: solid 1px var(--main-border-color);
}