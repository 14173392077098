.normal_product:hover{
	background-color:#f7f7fa
}
.normal_Product_actions{
	position: absolute;
    /* right: 0; */
    top: 4px;
    visibility: hidden;
    opacity: 0;
    transition: all 200ms;
    white-space: nowrap;
    background: #f7f7fa
}
.table_body_row:hover .normal_product_table_actions .normal_Product_actions {
    visibility: visible;
    opacity: 1;
    z-index: 100;
}