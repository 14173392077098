.openButton {
    position: fixed;
    z-index: 20000;
    bottom: 20px;
    right: 80px;
    width: 48px;
    height: 48px;
    background: #FC932A;
    border-radius: 48px;
    color: #fff;
    text-align: center;
    line-height: 20px;
	font-size: 20px;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
	opacity: .2;
	transition: all 200ms;
}
.openButton:hover,
.openButton:focus {
	opacity: 1;
}

.feature_item{
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
  border-bottom: solid 1px var(--main-border-color);
}
.feature_item::before{
  content: "";
  flex: 0 0 auto;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: var(--turquoise);
  margin-right: 10px;
}
.feature_item_notonpage::before{
	background: var(--grey-4);
}
.feature_item_inner{
  flex: 1 1 auto;
}
.feature_id{
  font-size: 12px;
  font-weight: 500;
  color: var(--grey-3);
}
.feature_disabled{
	color: var(--grey-1);
}
