.wrapper{
	text-align: center;
	padding-left: 16px;
	padding-right: 16px;
	margin-left: auto;
	margin-right: auto;
}
.wrapper_has_border {
	border: solid 1px var(--main-border-color);
	border-radius: 10px;
}

/* Width */
.wrapper_width_full { max-width: none; }
.wrapper_width_xl { max-width: 560px; }
.wrapper_width_l { max-width: 500px; }
.wrapper_width_m { max-width: 400px; }
.wrapper_width_s { max-width: 300px; }

/* Vertical size */
.wrapper_size_xs{
	padding-top: 16px;
	padding-bottom: 16px;
}
.wrapper_size_s{
	padding-top: 30px;
	padding-bottom: 30px;
}
.wrapper_size_m{
	padding-top: 60px;
	padding-bottom: 60px;
}
.wrapper_size_l{
	padding-top: 90px;
	padding-bottom: 90px;
}
.wrapper_size_xl{
	padding-top: 120px;
	padding-bottom: 120px;
}

/* Layout */
.wrapper_absolute{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Content */
.img{
	margin-bottom: 24px;
	max-width: 400px;
}
.icon{
	font-size: 56px;
	color: #E2E4ED;
	margin-bottom: 14px;
}
.icon i{
	width: auto;
}

/* Title & text */
.title{
	font-size: 18px;
	color: var(--grey-0);
}
.title_medium {
	font-weight: 500;
}
.text{
	margin-top: 4px;
	font-size: 14px;
	color: var(--grey-3);
}

/* Btn */
.btn{
	margin-top: 16px;
}

/* Text size XL */
.wrapper_text_size_xl .title{
	font-size: 26px;
}
.wrapper_text_size_xl .text{
	font-size: 18px;
}
.wrapper_text_size_xl .icon{
	font-size: 72px;
}
.wrapper_text_size_xl .btn{
	margin-top: 26px;
}
.wrapper_text_size_xl .img{
	margin-bottom: 32px;
}

/* Text size L */
.wrapper_text_size_l .title{
	font-size: 22px;
}
.wrapper_text_size_l .text{
	font-size: 16px;
}
.wrapper_text_size_l .icon{
	font-size: 64px;
}
.wrapper_text_size_l .btn{
	margin-top: 22px;
}
.wrapper_text_size_l .img{
	margin-bottom: 28px;
}

/* Text size S */
.wrapper_text_size_s .title{
	font-size: 15px;
}
.wrapper_text_size_s .text{
	font-size: 13px;
}
.wrapper_text_size_s .icon{
	font-size: 42px;
	margin-bottom: 6px;
}
.wrapper_text_size_s .btn{
	margin-top: 16px;
}

/* Card */
.card {
	border: solid 1px var(--main-border-color);
	padding: 0 20px;
	border-radius: 6px;
	line-height: 1.5;
	text-align: left;
	color: var(--grey-0);
	max-width: 580px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 28px;
}
.card_width_s { max-width: 480px; }
.card_width_m { max-width: 580px; }
.card_text_size_m { font-size: 14px;}
.card_text_size_l { font-size: 15px;}
.card::before,
.card::after {
	content: "";
	display: block;
	margin-top: 20px;
}
.card p {
	margin: .7em 0;
}
.card h2,
.card h3,
.card h4 {
	margin: 1.4em 0 .7em;
}
.card ul {
	padding: 0 1em;
	margin: .7em 0;
}
.card li {
	list-style-position: outside;
	list-style-type: disc;
}

