.containerIP {
    margin-top: 3px;
}

.containerIP button {
    background: #cfcccc;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    padding: 5px 5px 5px 10px;
    transition: all .2s ease-in-out;
}

.containerIP button:hover {
    background: #006fff;
    color: #FFF;
}

.messageInfos {
    position: relative;
    margin-bottom: 16px;
    background-color: #f6f7f8;
    padding: 10px 16px;
    border-radius: 6px;
    display: flex;
    align-items: baseline;
    color: #757b96;
    text-align: left;
}

.infoLabel {
    font-weight: 600;
    flex: 0 0 auto;
    margin-right: 10px;
}