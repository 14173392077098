.simplify_select_trigger {
    outline: none;
    padding: 4px;
    border-radius: 2px;
    border-color: #d6d6d6;
    cursor: pointer;
}

.put_trigger {
    cursor: pointer;
}

.put_trigger.disabled {
    cursor: text;
    color: #656a6e;
}

.put_trigger.disabled:hover {
    color: #656a6e;
}

.put_trigger:hover {
    color: #006fff
}

.simplify_item_on_list {
    font-size: 17px;
}