.wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: var(--main-bg-color);
    border: solid 1px var(--main-border-color);
    border-width: 1px 0 0 1px;
    border-top-left-radius: 8px; */

    display: flex;
    align-items: stretch;
}

.pane_menu {
    flex: 0 0 auto;
    width: 240px;
    display: flex;
    flex-direction: column;
}
.pane_menu_top {
    flex: 1 1 auto;
    padding: 0 2px 0 14px;
}
@media (max-width: 1367px) {
    .pane_menu {
        width: 210px;
    }
    .pane_menu_top {
        padding-left: 10px;
    }
}
.pane_menu_before {
    margin: 20px 0 10px 10px;
}
.pane_menu_bottom {
    flex: 0 0 auto;
    margin-top: 20px;
    padding: var(--main-padding);
}

.pane_content {
    flex: 1 1 auto;
    position: relative;
    overflow: auto;
}
.pane_menu_title {
    padding: 28px 0 10px 0;
    margin-left: 10px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    color: var(--black);
    border-bottom: solid 1px var(--main-border-color);
}