.content {
    background: #FFFFFF;
    padding: 23px 30px;
    font-size: 13px;
    box-shadow: 0px 6px 49px 0px rgba(220, 220, 220, 0.9);
    border-radius: 15px;
    margin-top: 40px;
}
.title {
    font-size: 20px;
    margin-top: 0;
}
.icons {
    color: #ACACAC;
}
