.setup-report-container {
    text-align: center;
    padding: 50px 20px;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    align-items: center;
    width: 300px;

}


.setup-report-container  a{
        font-size: 15px;
     
}
.setup-report-container  a:hover{
        color: #006fff 
}

.setup-report-container {

}