.articleWrapper {
    margin-top: 12px;
}
.filterList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 12px;
}
.funnel {
    background-color: #FFF;
}
.row {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 25px;
}
.col {
    
}
.colMain,
.colIO {
    composes: col;
}
.colMain {
    --width-ratio: 1;
    flex: 0 0 auto;
    min-width: 15px;
    width: calc(var(--width-ratio) * calc(100% - 530px));
}
.colIO {
    flex: 0 0 auto;
    width: 150px;
    align-self: flex-end;
}
.colTransition {
    flex: 0 0 auto;
    width: 115px;
    position: relative;
}

.stackLabel {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    background-color: rgba(255,255,255,.6);
    border-radius: 3px;
    padding: 2px 6px;
    transform: translateX(-50%);
    left: 50%;
    font-size: 18px;
    margin-bottom: 3px;
}
.stackLabel_value {
    font-weight: 500;
}
.stackLabel_subValue {
    font-size: 14px;
}

.stack {
    position: relative;
    background-color: var(--grey-8);
}
.stackItem + .stackItem {
    border-top: solid 1px var(--white);
}
.stackItem {
    background-color: #6C85F0;
}

.side {
    padding: 6px 16px;
}
.sideExit {
    composes: side;
    text-align: left;
}
.sideEntrance {
    composes: side;
    text-align: right;
}
.sideLabel {
    font-size: 14px;
}
.sideLabel_value {
    font-weight: 500;
}

.transitionSVG {
    width: 100%;
    height: 100%;
}
.transitionValue {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 4px);
    transform: translateX(-50%);
    background-color: currentColor;
    white-space: nowrap;
    padding-left: 6px;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px 0 0 3px;
}
.transitionValue::after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    height : 0;
    width : 0;
    border-top : 11px solid transparent;
    border-bottom : 11px solid transparent;
    border-left : 12px solid currentColor;
}
.transitionValueEntrance {
    composes: transitionValue;
    color: var(--color-success);
}
.transitionValueExit {
    composes: transitionValue;
    color: var(--color-alert);
}
.transitionValueText {
    color: var(--white);
}

/* Head */
.header {
    border-bottom: solid 1px var(--main-border-color);
    margin-bottom: 30px;
    padding-bottom: 10px;
}
.headerSplitLabel {
    color: var(--grey-3);
    font-weight: normal;
    margin-left: 12px;
    margin-right: 4px;
}

/* Legend */
.legendList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px 20px;
}
.legendItem {
    display: flex;
    align-items: center;
}
.legendItemColor {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: aqua;
    margin-right: 8px;
}
.legendItemLabel {
    font-size: 13px;
}

/* Colors */
.color_1 {	background-color: #6C85F0;}
.color_2 {	background-color: #73C0F8;}
.color_3 {	background-color: #CA95EB;}
.color_4 {	background-color: #C16AB7;}
.color_5 {	background-color: #FFE762;}
.color_6 {	background-color: #FC932A;}
.color_7 {	background-color: #EB8067;}
.color_8 {	background-color: #66D59D;}
.color_9 {	background-color: #93D57C;}

/* stackTooltip */
.stackTooltip {
    color: var(--black);
    border: solid 1px var(--main-border-color) !important;
    padding: 10px;
    border-radius: 6px;
    box-shadow: rgba(0,0,0,.1) 0 0 10px;
}
.stackTooltip:global(.show) {
    opacity: 1;
}
.stackTooltip::after,
.stackTooltip::before {
    display: none !important;
}
.stackTooltipItem + .stackTooltipItem {
    margin-top: 6px;
}