.chip {
  border-radius: 5px;
  background: var(--turquoise); 
  color: #fff;
  padding: 5px 12px;
  position: relative;
  cursor: pointer;
  margin-right: 12px;
}
.chip_type{
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
}
.chip_value{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}