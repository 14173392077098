.target_rules_outer {
	min-height: 100%;
	padding: 40px;
	background-color: var(--main-bg-color);
}
.target_rules_inner {
	max-width: 580px;
    margin: 0 auto;
}


/* Segment section */
.segment_section_group {
	margin-bottom: 24px;
}
.segment_section_group.l {
	margin-bottom: 36px;
}
.segment_section_label {
	font-weight: 500;
	font-size: 17px;
	margin-bottom: 8px;
}
.segment_section_sub_label {
	font-weight: 500;
	font-size: 15px;
	color: var(--grey-3);
	margin-bottom: 8px;
}
.segment_section {
	display: block;
	position: relative;
	background-color: #fff;
	border: solid 1px var(--main-border-color);
	border-radius: 6px;
}
.segment_section_inner {
	padding: 4px 18px;
}
.segment_section_inner_l {
	padding: 22px;
}

/* Segment section OR */
.segment_section_or {
	position: relative;
	padding: 7px;
	text-align: center;
}
.segment_section_or::before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	border: solid 1px var(--main-border-color);
	border-width: 0 1px;
}
.segment_section_or_inner {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 2px 10px;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 500;
	color: var(--grey-2);
	text-align: center;
	background: #fff;
	border: solid 1px var(--main-border-color);
}

/* Section card */
.segment_card_outer {
	position: relative;
}
.segment_card {
	position: relative;
	color: var(--grey-0);
	overflow: hidden;
}
.segment_card + .segment_card {
	margin-top: 10px;
}
.segment_card_header {
	padding: 8px 16px;
	min-height: 42px;
	transition: all 200ms;
	position: relative;
}
.segment_card_header.clickable{
	cursor: pointer;
}
.segment_card_close .segment_card_header {
	border-bottom-width: 0;
}
.segment_card_header::after {
	content: "";
	position: absolute;
	left: 16px;
	right: 16px;
	bottom: 0;
	border-top: solid 1px #F0F1F5;
	transition: all 200ms;
}
.segment_card_close .segment_card_header::after {
	opacity: 0;
}
.segment_card_header.clickable:hover,
.segment_card_header.clickable:focus{
	background-color: var(--main-bg-color);
}
.segment_card_name {
	font-size: 16px;
}
.segment_card_header.s .segment_card_name{
	font-size: 15px;
}
.segment_card_badge {
	font-size: 11px;
	font-weight: 600;
	text-transform: uppercase;
	margin-left: 8px;
	padding-left: 4px;
	padding-right: 4px;
}

/* Card toggle arrow */
.segment_card_arrow {
	margin-left: 10px;
	margin-right: -2px;
	font-size: 14px;
	color: var(--grey-3);
}
.segment_card_arrow i{
	display: block;
	font-size: 13px;
}
.segment_card_toggle_arrow {	
	transition: all 200ms;
}
.segment_card_close .segment_card_toggle_arrow {
	transform: rotateZ(180deg);
}

/* Card icon */
.segment_card_icon {
	margin-left: -2px;
	margin-right: 8px;
	font-size: 13px;
	color: #43B55E;
}
.segment_card_icon_star {
	color: #FFC400;
}
.segment_card_icon_magic {
	color: #DD860B;
}

/* Card body */
.segment_card_body {
	padding: 12px 16px;
	transition: all 200ms;
}
.segment_card_close .segment_card_body {
    height: 0;
    margin-top: 0;
	padding-top: 0;
	padding-bottom: 0;
    overflow: hidden;
}
.segment_card_item {
	padding: 4px 0;
}
.segment_card_item_info {
	position: relative;
	font-size: 15px;
	margin-left: 10px;
	white-space: pre-line;
	line-height: 1.4em;
	max-height: calc(4 * 1.4em);
	overflow: hidden;
}
.segment_card_item_info::after {
	content: "";
	position: absolute;
	top: calc(3 * 1.4em);
	left: 0;
	right: 0;
	height: 1.4em;
	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.segment_card_item_info_label {
	font-weight: 500;
}
.segment_card_item_info_operand {
	color: var(--grey-3);
}

/* Card footer */
.segment_card_footer {
	border-top: solid 1px #F0F1F5;
	padding-top: 12px;
	padding-bottom: 4px;
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.segment_card_footer_col_left {
	margin-left: -6px;
}

/* Segment section del */
.segment_card_del {
	position: absolute;
	width: 28px;
	height: 28px;
	border-radius: 28px;
	line-height: 28px;
	right: -40px;
	top: 10px;
	background: var(--grey-4);
	color: #fff;
	font-size: 13px;
	text-align: center;
	padding: 0;
	transition: all 200ms;
}
.segment_card_del:hover,
.segment_card_del:focus {
	background: var(--grey-3);
}

/* New */
.segment_new_btn {
	display: block;
	width: 100%;
	padding: 14px;
	border-radius: 6px;
	background-color: #fcfcfe;
    border: dashed 2px var(--main-border-color);
    color: var(--grey-3);
    text-align: center;
    font-weight: 500;
	transition: all 200ms;
	cursor: pointer;
}
.segment_new_btn:hover,
.segment_new_btn:focus {
	color: var(--turquoise);
	border-color: currentColor;
}

/* Segment Panel */
.segment_panel_subtitle {
	font-size: 15px;
	font-weight: 500;
	color: var(--grey-3);
	margin-bottom: 6px;
}
.segment_panel_title_action {
	font-size: 12px;
	font-weight: 500;
	margin-left: 3px;
}


/* Segment context */
.segment_context_item {
	display: flex;
	align-items: center;
	padding: 14px 0;
	position: relative;
}
.segment_context_item:not(:first-child) {
	margin-top: 1px;
}
.segment_context_item:not(:first-child)::before {
	content: "";
	position: absolute;
	top: -1px;
	border-top: solid 1px var(--main-border-color);
	left: 46px;
	right: 0;
}
.segment_context_item_picto {
	flex: 0 0 auto;
	margin-right: 12px;
}
.segment_context_item_info {
	flex: 1 1 auto;
}
.segment_context_item_field {
	flex: 0 0 auto;
	margin-left: 20px;
}
.segment_context_item_info_title {
	font-weight: 500;
	font-size: 15px;
	color: var(--grey-0);
}
.segment_context_item_info_desc {
	font-size: 13px;
	color: var(--grey-3);
	margin-top: 1px;
}

/* Segment condition */
.segment_condition_list {
	position: relative;
	margin: 2px 0;
}
.segment_condition_list::before {
	content: "";
	position: absolute;
	top: 28px;
	bottom: 0;
	left: 25px;
	border-right: dashed 1px #E9EAF0;
}
.segment_condition_item {
	position: relative;
	padding: 14px 0;
}
.segment_condition_item:last-child::before {
	content: "";
	position: absolute;
	top: 50%;
	bottom: 0;
	left: 23px;
	width: 4px;
	background: #fff;
}
.segment_condition_item_and {
	margin-right: 18px;
}
.segment_condition_item:not(:last-child) .segment_condition_item_and {
	align-self: flex-start;
    padding-top: 10px;
}
.segment_condition_item_and_label {
	position: relative;
	display: block;
	width: 50px;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
	color: var(--turquoise);
	background: var(--turquoise-light);
	border-radius: 4px;
	padding: 3px 4px;
}
.segment_condition_item_name {
	color: var(--grey-0);
	font-size: 15px;
	font-weight: 500;
}
.segment_condition_item_desc{
	font-size: 13px;
	color: var(--grey-3);
	margin-top: 1px;
}
.segment_condition_item_operand{
	margin-left: 20px;
}
.segment_condition_item_operand + .segment_condition_item_value{
	margin-left: 10px;
}
.segment_condition_item_value.w_full{
	width: 100%;
}
.segment_condition_item_value_line{
	margin-top: 8px;
	margin-left: 68px;
	margin-right: 42px;
}
