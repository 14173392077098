.table_product_feed th {
    font-weight: bold;
}

.container_image_product_feed {
    height: 200px;
    display: flex;
    align-items: center;
}

.container_image_product_feed img {
    width: auto;
    max-height: 90px;
    margin: auto;
}

.categories_product_feed {
    overflow-y: auto;
}

.product_variante_product_feed {
    overflow-y: auto;
}

.categories_product_feed ul li {
    margin-bottom: 2px;
}

.url_product_feed {
    text-align: center;
}

.url_product_feed a:hover {
    color: #006fff;
    cursor: pointer;
}

.product_tag_product_feed {
    width: 180px;
}

.product_tag_product_feed ul li {
    margin-bottom: 2px;
}

.product_format_style p {
    word-wrap: break-word !important;
}