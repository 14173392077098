.builder_body {
    flex: 1 1 auto;
    position: relative;
    background-color: var(--main-bg-color);
}

/* Frame */
.builder_body .gjs-frames {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
    transition: all 300ms;
	display: flex;
    flex-direction: column;
    align-items: center;
}
.builder_body_mobile .gjs-frames {
	padding: 16px;
}
.builder_body .gjs-frame-wrapper {
	position: relative;
	flex: 0 0 auto;
    width: 100%;
    height: 100%;
	transition: all 300ms;
}
.builder_body_mobile .gjs-frame-wrapper {
    width: 375px;
    height: 700px;
    border-radius: 34px;
    padding: 24px 6px;
    box-sizing: content-box;
    background: #D6D7E0;
}
.builder_body_mobile .gjs-frame-wrapper .gjs-frame {
	background: #fff;
	border-radius: 24px;
	top: 24px;
	bottom: 24px;
	height: calc(100% - 24px - 24px);
	left: 6px;
	right: 6px;
	width: calc(100% - 6px - 6px);
}

/* Badges */
.builder_body .gjs-badge {

}
.builder_body .gjs-badge .gjs-badge__icon {
	flex: 0 0 auto;
	width: 16px;
	height: 16px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 2px;
}
.builder_body .gjs-badge .gjs-badge__icon svg{
	max-width: 100%;
}

/* Toolbar */
.builder_side_toolbar {
    flex: 0 0 auto;
    border-right: solid 1px var(--main-border-color);
    width: 76px;
    padding: 5px;
	display: flex;
	flex-direction: column;
	background-color: var(--white);
}
.builder_side_toolbar_top {
	flex: 1 1 auto;
}
.builder_side_toolbar_bottom {
	flex: 0 0 auto;
}
.builder_side_toolbar_item {
    display: block;
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 10px 3px 6px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: var(--grey-1);
    transition: all 200ms;
    user-select: none;
}
.builder_side_toolbar_item:hover,
.builder_side_toolbar_item:focus {
    background: var(--main-bg-color);
    color: var(--grey-0);
}
.builder_side_toolbar_item.selected {
    background: var(--turquoise-light);
    color: var(--turquoise);
}

.builder_side_toolbar_item_icon {
    font-size: 16px;
}
.builder_side_toolbar_item_text {
    display: block;
    line-height: 18px;
    margin-top: 1px;
}

/* Sidebar */
.builder_sidebar {
	position: relative;
    flex: 0 0 auto;
    width: 300px;
    transition: all 300ms;
	color: var(--grey-0);
	background-color: var(--white);
}
.builder_sidebar_left {
    border-right: solid 1px var(--main-border-color);
}
.builder_sidebar_right {
    border-left: solid 1px var(--main-border-color);
}
.builder_sidebar_styles {
    width: 370px;
}
.builder_sidebar_export {
    width: 370px;
}
.builder_sidebar_closed {
    width: 0;
    overflow: hidden;
    border-width: 0;
}
.builder_sidebar_section {
	padding: 20px 22px;
}
.builder_sidebar_section_styles {
	padding: 20px 22px 16px;
}
.builder_sidebar_section_layers {
	padding: 0 12px 20px;
}
.builder_sidebar_title {
	font-size: 16px;
	font-weight: 500;
}
.builder_sidebar_icon {
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
    height: 26px;
    border-radius: 3px;
}
.builder_sidebar_icon svg{
	max-width: 100%;
}
.builder_sidebar_tabs.tabs {
	padding-left: 22px;
	padding-right: 22px;
	margin-left: 0;
	padding-top: 2px;
}
.builder_sidebar_tabs.tabs::before {
	border-bottom-width: 2px;
	left: 0;
}
.builder_sidebar_tabs.tabs a {
	text-transform: capitalize;
}
.builder_sidebar_tabs.tabs a::after {
	height: 2px;
	border-radius: 0;
	left: 0;
	right: 0;
}
.builder_sidebar_sep {
	border-bottom: solid 2px var(--main-border-color);
}

/* Default panels */
.builder_body .gjs-pn-panels .gjs-pn-panel {
	padding: 0;
}

/* Blocks manager */
.builder_sidebar .gjs-block-category {
	border: none;
}
.builder_sidebar .gjs-block-category .gjs-title {
	border: none;
	padding: 0 22px;
	font-size: 14px;
	font-weight: 500;
	color: var(--grey-0);
	background-color: transparent;
	letter-spacing: normal;
}
/* Remove collapsible */
.builder_sidebar .gjs-block-category .gjs-title {
	cursor: default;
	pointer-events: none;
}
.builder_sidebar .gjs-block-category .gjs-caret-icon {
	display: none;
}

.builder_sidebar .gjs-blocks-c {
	padding: 6px 18px 18px;
}
.builder_sidebar .gjs-blocks-c:empty {
	padding: 0;
}
.builder_sidebar .gjs-block {
	width: calc(33.33% - 8px);
	min-width: auto;
	min-height: auto;
	margin: 4px;
	padding: 14px 6px 5px;
	border: solid 1px var(--main-border-color);
	background-color: var(--main-bg-color);
	border-radius: 3px;
	box-shadow: none;
	color: var(--grey-0);
	transition: all 200ms;
}
.builder_sidebar .gjs-block:hover,
.builder_sidebar .gjs-block:focus {
	color: var(--black);
	background-color: var(--white);
	border-color: var(--grey-5);
	box-shadow: none;
}
.builder_sidebar .gjs-block__media{
	background-color: transparent;
	height: 30px;
	margin-bottom: 3px;
}
.builder_sidebar .gjs-block-label {
	height: 26px;
	line-height: 13px;
    font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Fix color picker position */
.builder_body .sp-container{
	position: fixed !important;
}


/* TODO : clean or remove */
.gjs-one-bg {
    background-color: white;
}
.gjs-editor {
    background: none !important;
}
.gjs-cv-canvas {
    top: 0;
    width: 100%;
    height: 100%;
    background: none;
}
.gjs-pn-buttons {
    display: flex;
}
.gjs-category-title,
.gjs-layer-title,
.gjs-block-category .gjs-title,
.gjs-sm-sector-title {
    color: black;
}
.gjs-two-color {
    color: black;
}
.gjs-block__media {
    background-color: white;
}
