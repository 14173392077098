.root {
    height: 100%;
    display: flex;
    border-radius: inherit;
    overflow: hidden;
}
.closeBtn {
    position: absolute;
    z-index: 20;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
}

.sidebar {
    flex: 0 0 auto;
    background-color: var(--main-bg-color);
    border-right: solid 1px var(--main-border-color);
    width: 260px;
    padding: 12px;
}
.main {
    flex: 1 1 auto;
    position: relative;
}
.view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
}
.view.view_anim_show {
    animation: view_show 300ms ease-out;
}
.view.view_anim_hide {
    animation: view_hide 300ms ease-out;
}
@keyframes view_show {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes view_hide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
}
.overlay.overlay_anim_show {
    animation: overlay_show 300ms ease-out;
}
.overlay.overlay_anim_hide {
    animation: overlay_hide 300ms ease-out;
}
@keyframes overlay_show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes overlay_hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.viewHeader {
    padding: 22px 42px 11px;
}
.viewHeaderLarge {
    composes: viewHeader;
    padding-top: 32px;
    padding-bottom: 22px;
}
.viewHeaderSticky {
    composes: viewHeader;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: all 500ms;
    border-bottom: solid 1px transparent;
}
.viewHeaderScroll {
    background-color: rgba(255,255,255,.6);
    border-bottom-color: var(--main-border-color);
    backdrop-filter: blur(20px);
}
.viewBody {
    /* flex: 1 1 auto; */
    position: relative;
    padding: 11px 42px 22px;
}

/* View header */
.viewHeaderTitle {
    font-size: 25px;
    font-weight: 600;
}
.viewHeaderFilters {
    margin-top: 12px;
}
.viewHeaderTitle2 {
    font-size: 17px;
    font-weight: 500;
    transition: all 500ms;
}
.viewHeaderTitle2Hidden {
    composes: viewHeaderTitle2;
    opacity: 0;
    visibility: hidden;
}
.viewHeaderTitle2Visible {
    composes: viewHeaderTitle2;
    opacity: 1;
    visibility: visible;
}

.grid_center {
    margin-left: auto;
    margin-right: auto;
    max-width: 1346px;
    --columnWidth: 320;
}
.grid_group {
    margin-bottom: 24px;
}
.grid_group_title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 14px;
}
.grid {
    display: grid;
    gap: var(--main-padding);
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media (max-width: 1720px) {
    .grid_center {
        max-width: 1146px;
        --columnWidth: 270;
    }
    .catalogueItemThumb__desktop .catalogueItemThumbIframe {
        transform: scale(.411);
    }
}
@media (max-width: 1580px) {
    .grid_center {
        max-width: 1064px;
        --columnWidth: 340;
    }
    .grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (max-width: 1480px) {
    .grid_center {
        max-width: 914px;
        --columnWidth: 290;
    }
}
@media (max-width: 1330px) {
    .grid_center {
        max-width: 794px;
        --columnWidth: 250;
    }
}

/* Catalogue */
.catalogueItem {
    background-color: var(--white);
    cursor: pointer;
    transition: all 500ms;
    border-color: transparent;
}
.catalogueItemSelected {
    composes: catalogueItem;
}
.catalogueItem:hover,
.catalogueItem:focus {
    
}
.catalogueItemThumb {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    border: solid 1px var(--main-border-color);
    background-color: var(--main-bg-color);
    width: 100%;
}
.catalogueItemSelected .catalogueItemThumb {
    box-shadow: var(--turquoise) 0 0 0 2px;
}
.catalogueItemThumbIframe {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left top;
    pointer-events: none;
}
.catalogueItemThumb__mobile {
    composes: catalogueItemThumb;
    padding-top: 130%;
}
.catalogueItemThumb__mobile .catalogueItemThumbIframe {
    width: 430px;
    height: 559px;
    transform: scale(calc(var(--columnWidth) / 430));
}
.catalogueItemThumb__desktop {
    composes: catalogueItemThumb;
    padding-top: 70.58%;
}
.catalogueItemThumb__desktop .catalogueItemThumbIframe {
    width: 850px;
    height: 600px;
    transform: scale(calc(var(--columnWidth) / 850));
}


.catalogueItemInfos {
    margin-top: 10px;
}
.catalogueItemTitle {
    font-weight: 500;
    font-size: 14px;
}
.catalogueItemSelected .catalogueItemTitle {
    color: var(--turquoise);
}
.catalogueItemTagList {
    margin-top: 4px;
}
.catalogueItemTag {
    font-size: 12px;
    color: var(--grey-2);
}

/* Tag */
.tagList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px 10px;
}
.tagItem {
    color: var(--grey-2);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
    padding: 4px 8px;
    border: solid 1px var(--main-border-color);
    border-radius: 4px;
}
.tagItemIcon {
    margin-left: -2px;
    margin-right: 2px;
}

/* Template */
.templatePage {
    width: 800px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.templatePageHead {
    margin-bottom: 20px;
}
.templatePageTitle {
    font-size: 25px;
    font-weight: 600;
}
.templatePageTagList {
    margin-top: 6px;
}
.templatePageSection {
    margin-bottom: 26px;
}
.templatePageSectionTitle {
    margin-bottom: 10px;
}
.templatePagePreview {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}
.templatePagePreviewMobile {
    flex: 0 0 auto;
    width: 258px;
}
.templatePagePreviewDesktop {
    flex: 0 0 auto;
    width: 530px;
}

.templatePageThumb {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--main-bg-color);
    border: solid 1px var(--main-border-color);
    box-shadow: rgba(0,0,0,.06) 0 3px 6px;
    height: 440px;
}
.templatePageThumbIframe {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left top;
    pointer-events: none;
}
.templatePageThumb__mobile,
.templatePageThumb__desktop {
    composes: templatePageThumb;
}
.templatePageThumb__mobile .templatePageThumbIframe {
    width: 390px;
    height: calc(390 / 256 * 438px);
    transform: scale(calc(256 / 390));
}
.templatePageThumb__desktop .templatePageThumbIframe {
    width: 828px;
    height: calc(828 / 528 * 438px);
    transform: scale(calc(528 / 828));
}

/* Gallery */
.gallery {
    position: relative;
    border: solid 1px var(--main-border-color);
    border-radius: 8px;
    padding: 10px;
    box-shadow: rgba(0,0,0,.06) 0 3px 6px;
}
.gallery_has_before_shadow::before,
.gallery_has_after_shadow::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 10px;
    bottom: 10px;
    width: 16px;
    pointer-events: none;
}
.gallery_has_before_shadow::before {
    left: 10px;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.gallery_has_after_shadow::after {
    right: 10px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.gallery_track {
    display: flex;
    overflow: auto;
    gap: 8px;

    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.gallery_track::-webkit-scrollbar {
	display: none;
}
.gallery_track .catalogueItem {
    --columnWidth: 206;
    width: 210px;
    flex: 0 0 auto;
    padding: 2px;
    scroll-snap-align: start;
}

.galleryNav {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: solid 1px var(--main-border-color);
    background-color: var(--white);
    box-shadow: rgba(0,0,0,.06) 0 3px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.galleryPrev {
    composes: galleryNav;
    left: -20px;
}
.galleryNext {
    composes: galleryNav;
    right: -20px;
}