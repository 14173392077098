.content {
    background: #FFFFFF;
    padding: 23px 30px;
    font-size: 13px;
    box-shadow: 0px 6px 49px 0px rgba(220, 220, 220, 0.9);
    border-radius: 15px;
}
.title {
    font-size: 20px;
    margin-top: 0;
}
.description {
    color: #ACACAC;
    margin-bottom: 15px;
}
.signalList {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
}
.signalList div button {
    padding: 0px;
}
.signalList div button .fa-star {
    margin-right: 5px;
}
.signalListItem {
    color: #cecece;
}
.signalListItemUsed {
    color: #707070;
    font-weight: 700;
}
.signalListItem.isPredefined {
    cursor: default;
}
.signalListItem i:nth-of-type(1) {
    color: #D5D5D5;
}
.signalListItem i:nth-of-type(2) {
    visibility: hidden;
}
.signalListItem:hover i:nth-of-type(2) {
    visibility: visible;
}
.signalListItemUsed i:nth-of-type(2) {
    visibility: hidden;
}
.signalListItemUsed:hover i:nth-of-type(2) {
    visibility: visible;
}
.signalList .addSignal button {
    display: inline;
    color: #C0C0C0;
    background: #FFFFFF;
    border: 1px dashed #C0C0C0;
    transition: all .3s ease-in-out;
    padding: 5px;
}
.addSignal button:hover {
    color: #006fff;
    border: 1px dashed #006fff;
}