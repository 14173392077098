.trigger_outer{
	padding: 40px;
}
.trigger_wrapper_old .trigger_outer{
	padding: 20px;
}

.trigger_old_title{
	padding: 0 20px;
}
.trigger_old_title_inner{
	width: 1000px;
	max-width: 100%;
	margin: auto;
}

.trigger_header{
	width: 1000px;
	max-width: 100%;
	margin: auto;
	padding-bottom: 20px;
	border-bottom: solid 1px var(--main-border-color);	
}
.trigger_body{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.trigger_side{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	order: 2;

	width: 280px;
	margin-left: 40px;
	margin-top: 30px;
}
.trigger_middle{
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;

	width: 680px;
}

/* Persona menu */
.trigger_persona_menu{
	margin-bottom: 20px;
}
.trigger_persona_menu_item{
	display: block;
	padding: 12px;
	font-size: 14px;
	font-weight: 500;
	border-radius: 6px;
	cursor: pointer;
	transition: background 200ms;
	margin-bottom: 2px;
	color: var(--grey-0);
}
.trigger_persona_menu_item:hover,
.trigger_persona_menu_item:focus{
	background: #F5F6F8;
}
.trigger_persona_menu_item.active{
	background: var(--turquoise-light);
	color: var(--turquoise);
}
.trigger_persona_menu_item .fas{
	margin-right: 8px;
}

.trigger_persona_empty{
	background: #F5F6F8;
	border-radius: 6px;
	padding: 30px 10px;
}
.trigger_persona_empty .empty_state_text{
	max-width: 160px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 14px;
}


/* Persona header */
.trigger_persona_header{
	padding: 20px 0;
	border-bottom: solid 1px var(--main-border-color);
	margin-bottom: 36px;
}
.trigger_persona_title{
	font-size: 25px;
	font-weight: 500;
}
.trigger_persona_desc{
	font-size: 14px;
	color: var(--grey-2);
}


/* Questions */
.trigger_block_title{
	font-size: 17px;
	font-weight: 500;
}
.trigger_block_desc{
	margin-top: 2px;
	font-size: 14px;
	color: var(--grey-2);	
}
.trigger_block{
	margin-top: 14px;
	margin-bottom: 36px;
}

.btnUi.trigger_btn{
	justify-content: flex-start;
	align-items: flex-start;
}
.btnUi.trigger_btn .trigger_btn_text{
	font-size: 15px;
	font-weight: 500;
	text-align: left;
}
.btnUi.trigger_btn:not(.active):not(:hover):not(:focus){
	background-color: #fff;
}
.btnUi.trigger_btn:not(.active) .trigger_btn_text{
	color: var(--grey-0);
}
.btnUi.trigger_btn .trigger_btn_desc{
	font-size: 13px;
	font-weight: 400;
	text-align: left;
	line-height: 1.4;
	margin-top: 2px;
}
.btnUi.trigger_btn:not(.active) .trigger_btn_desc{
	color: var(--grey-2);
}

/* Store */
.trigger_store_ext_wrapper {
	max-width: 500px;
	margin: auto;
}
.trigger_store_ext_wrapper .trigger_store {
	padding: 22px;
	border: solid 1px var(--main-border-color);
	background-color: var(--white);
	border-radius: 8px;
}
.trigger_store_page_title {
	margin-bottom: 20px;
}
.trigger_store_page_title_txt {
	font-weight: 500;
	font-size: 18px;
}

.trigger_store_category{
	margin-bottom: 20px;
}
.trigger_store_category_name{
	position: sticky;
	top: 0;
	font-weight: 500;
	font-size: 16px;
	background: #fff;
	z-index: 2;
	padding-bottom: 2px;
}
.trigger_store_category_info {
	font-size: 12px;
	font-weight: normal;
	color: var(--grey-3);
}
.trigger_store_category_label{
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--grey-2);
	margin-bottom: 6px;
}
.trigger_store_item{
	padding: 12px 8px;
	position: relative;
	margin: 0 -8px;
	cursor: pointer;
	transition: background 200ms;
	border-radius: 6px;
	color: var(--grey-0);
}
.trigger_store_item:not(.disabled):hover,
.trigger_store_item:not(.disabled):focus{
	background: #f5f6f8;
}
.trigger_store_item.dark:not(.disabled):hover,
.trigger_store_item.dark:not(.disabled):focus{
	background: var(--main-border-color);
}
.trigger_store_item.selected {
	background-color: var(--turquoise);
	color: #fff;
}
.trigger_store_item.selected:hover,
.trigger_store_item.selected:focus {
	background-color: var(--turquoise-dark) !important;
	color: #fff !important;
}
.trigger_store_item.disabled {
	cursor: default;
}
.trigger_store_item.disabled > div {
	opacity: .4;
}
.trigger_store_item.s {
	padding: 6px 8px;
}
.trigger_store_item .trigger_icon{
	margin-right: 12px;
}
.trigger_store_item_name{
	font-weight: 500;
	font-size: 14px;
}
.trigger_store_item_desc{
	color: var(--grey-3);
	font-size: 13px;
	margin-top: 2px;
}
.trigger_store_item + .trigger_store_item:not(.selected)::before{
	content: "";
	position: absolute;
	left: 54px;
	right: 8px;
	top: 0;
	border-top: solid 1px var(--main-border-color);
}
.trigger_store_item_type{
	font-size: 13px;
	color: var(--grey-3);
}

/* Cards */
.trigger_card_list{
	margin-top: 18px;
}
.trigger_card_empty{
	border: solid 1px var(--main-border-color);
	border-radius: 6px;
	margin-bottom: 16px;
	padding: 30px 14px;
}
.trigger_card{
	border: solid 1px var(--main-border-color);
	border-radius: 6px;
	margin-bottom: 16px;
	padding: 14px;
}
.trigger_card .trigger_icon{
	margin-right: 11px;
}
.trigger_card_name{
	color: var(--grey-0);
	font-size: 14px;
	font-weight: 500;
}
.trigger_card_desc{
	font-size: 13px;
	color: var(--grey-3);
	margin-top: 2px;
}
.trigger_card_operand{
	margin-left: 20px;
}
.trigger_card_operand + .trigger_card_value{
	margin-left: 10px;
}
.trigger_card_value.w_full{
	width: 100%;
}
.trigger_card_value_line{
	margin-top: 8px;
	margin-left: 45px;
	margin-right: 34px;
}
.trigger_card_delete {
	line-height: 30px;
	width: 34px;
	height: 30px;
	margin-left: 6px;
	margin-right: -6px;
	padding: 0;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	color: var(--grey-3);
}
.trigger_card_delete:hover {
	color: #EB6262;
}

/* Saved persona */
.persona_item{
	padding: 10px 8px;
	position: relative;
	margin: 0 -8px;
	transition: background 200ms;
	border-radius: 6px;
}
.persona_item:not(.disabled){
	cursor: pointer;
}
.persona_item:not(.disabled):hover,
.persona_item:not(.disabled):focus{
	background: #f5f6f8;
}
.persona_item .persona_item_icon{
	margin-right: 12px;
}
.persona_item_name{
	color: var(--grey-0);
	font-weight: 500;
	font-size: 14px;
}
.persona_item_desc{
	color: var(--grey-3);
	font-size: 13px;
	margin-top: 2px;
}
.persona_item.disabled .persona_item_name,
.persona_item.disabled .persona_item_desc{
	color: var(--grey-4);
}
.persona_item + .persona_item::before{
	content: "";
	position: absolute;
	left: 52px;
	right: 8px;
	top: 0;
	border-top: solid 1px var(--main-border-color);
}
.persona_item_icon{
	display: inline-block;
	vertical-align: top;
	width: 32px;
	height: 32px;
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FFF6D6;
	color: #FFC400;
	font-size: 13px;
}
.persona_item_del{
	width: 40px;
	height: 40px;
	border-radius: 40px;
	margin-left: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--grey-3);
	transition: all 200ms;
	cursor: pointer;
}
.persona_item_del:hover,
.persona_item_del:focus{
	background: #E9EAF0;
	color: var(--red);
}
