.ob_CheckBoxCard {
    border: 1px solid #E2E4EA;
    border-radius: 6px;
    padding: 8px 16px;
}
.ob_CheckBoxCard.disabled{
    border-color: #F0F1F4;
}
.ob_CheckBoxCard .ob_CheckBoxCard_title {
    margin-bottom: 3px;
    font-size: 17px;
}
.ob_CheckBoxCard .ob_CheckBoxCard_desc {
    color: var(--grey-0);
    font-size: 14px;
}
.ob_CheckBoxCard_image {
   margin-left: 10px;
   margin-top: -20px;
   margin-bottom: -20px;
}
.ob_CheckBoxCard_badge{
    display: inline-block;
    padding: 2px 6px;
    background: #4257F0;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
    margin-left: 6px;
}