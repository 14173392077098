@value ellips from "../../../Components/utils.module.css";

/* Wrapper */
.wrapper_outer {
	min-height: 100%;
    padding: 40px;
    background-color: var(--main-bg-color);
}
.wrapper_inner {
	max-width: 500px;
	margin: 0 auto;
}

/* Header */
.header {
	margin: 40px 0 26px;
}
.header_title {
	font-size: 25px;
	font-weight: 600;
}
.header_desc {
	font-size: 16px;
	color: var(--grey-2);
	margin-top: 2px;
}

/* Card */
.card {
	position: relative;
	border: solid 1px var(--main-border-color);
	background-color: var(--white);
	border-radius: 6px;
	transition: margin 200ms;
}
.card_s {
	composes: card;
}
.card_body {
	padding: 22px;
}
.card_body_l {
	padding: 30px 38px;
}
.card_header_l {
	padding: 30px 38px 0;
}
.card_footer {
	border-top: solid 1px var(--main-border-color);
	padding: 9px 22px;
}

.card_picto {
	margin-right: 16px;
}

.card_title {
	font-size: 22px;
	font-weight: 600;
}
.card_subtitle {
	font-size: 12px;
	font-weight: 500;
	color: var(--grey-1);

	text-overflow: ellipsis;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}
.card_subtitle_light {
	composes: card_subtitle;
	font-weight: normal;
	color: var(--grey-2);
}

.card_feed_type {
	font-size: 18px;
	font-weight: 600;
}
.card_feed_url {
	font-size: 13px;
	color: var(--grey-2);
	margin-top: 2px;

	text-overflow: ellipsis;
	display: block;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}

/*  Card group */
.card_group {
	margin: 16px 0;
}
.card_group .card:not(:first-child){
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin-top: -1px;
}
.card_group .card:not(:last-child){
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.card_group .card.card_closed {
	
}
.card_group .card.card_opened {
	margin: 16px 0;
	border-radius: 6px;
}
.card_group .card.card_opened + .card {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}
.card_group .card.card_before_open {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

/* Mapping Header */
.card_map_header {
	padding: 20px 22px;
	cursor: pointer;
}
.card_s .card_map_header {
	padding-top: 16px;
	padding-bottom: 16px;
}
.card_map_header_set {
	composes: card_map_header;
	color: var(--grey-0);
}
.card_map_header_empty {
	composes: card_map_header;
	color: var(--grey-3);
}
.card_map_header_error {
	composes: card_map_header;
	color: var(--grey-0);
}
.card_map_status_icon {
	font-size: 20px;
	margin-right: 14px;
	margin-left: -2px;
}
.card_map_header_set .card_map_status_icon{
	color: var(--color-success);
}
.card_map_header_error .card_map_status_icon{
	color: var(--color-warning);
}
.card_map_header_checkbox {
	margin-right: 6px;
}
.card_map_name {
	font-size: 17px;
	font-weight: 500;
}
.card_map_desc {
	font-size: 13px;
	color: var(--color-alert);
}
.card_map_value {
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
	font-size: 17px;
	line-height: normal;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	word-wrap: normal;
}
.card_map_header_empty .card_map_value::after {
	content: "";
	display: block;
	margin-top: 1px;
	border-top: dashed 1px currentColor;
	opacity: .7;
}

/* Mapping arrow */
.card_map_status_arrow {
	margin: -4px -6px -4px 12px;
	font-size: 15px;
	width: 32px;
	height: 32px;
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}
button.card_map_status_arrow {
	cursor: pointer;
	transition: background 200ms;
}
button.card_map_status_arrow:hover,
button.card_map_status_arrow:focus {
	background-color: var(--grey-7);
}

/* Mapping body */
.card_map_body {
	padding: 0 22px;
	margin: 22px 0;
	color: var(--grey-0);
}
.card_map_intro {
	margin-bottom: 16px;
}
.card_map_title {
	font-size: 17px;
}
.card_map_title strong{
	font-weight: 500;
}
.card_map_sub_title {
	font-size: 14px;
	color: var(--grey-2);
	margin-top: 6px;
}

/* Mapping Footer */
.card_map_footer {
	color: var(--grey-0);
	padding: 22px;
	padding-top: 0;
}
.card_map_footer_with_border {
	composes: card_map_footer;
	border-top: solid 1px var(--main-border-color);
	padding-top: 20px;
}
.card_map_footer_reset_margin {
	margin-left: -8px;
}

/* Mapping reset */
.card_map_reset {
	position: absolute;
	width: 28px;
	height: 28px;
	border-radius: 28px;
	line-height: 28px;
	right: -40px;
	top: 10px;
	background: var(--grey-4);
	color: #fff;
	font-size: 13px;
	text-align: center;
	padding: 0;
	transition: all 200ms;
}
.card_map_reset:hover,
.card_map_reset:focus {
	background: var(--grey-3);
}

/* Price array */
.price_row {
	display: flex;
	align-items: center;
}
.price_row + .price_row {
	margin-top: 16px;
}
.price_col_label {
	flex: 0 0 auto;
	margin-right: 16px;
	font-size: 15px;
}
.price_col_value {
	flex: 1 1 auto;
}

/* Choices */
.choice_card_group {

}
.choice_card {
	border: solid 1px var(--main-border-color);
	border-bottom-width: 0;
	padding: 15px 20px;
	cursor: pointer;
	transition: all 200ms;
}
.choice_card:first-child {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}
.choice_card:last-child {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	border-bottom-width: 1px;
}
.choice_card:hover,
.choice_card:focus {
	background-color: var(--main-bg-color);
}
.choice_card_title {
	font-size: 17px;
	font-weight: 500;
	color: var(--turquoise);
}
.choice_card_desc {
	font-size: 14px;
	color: var(--grey-2);
}

/* Separator */
.separator {
	margin: 36px 0;
}
.separator::before {
	content: "";
	display: block;
	width: 110px;
	max-width: 100%;
	margin: auto;
	border-top: solid 1px var(--main-border-color);
}

/* Form group label */
.form_group_label {
	font-weight: 500;
	color: var(--grey-3);
	margin: 30px 0 10px;
	text-transform: uppercase;
}

/* Table */
.table_label {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 6px;
}
.table_sep {
	display: block;
	border-top: solid 1px var(--main-border-color);
}
.table_sep_l {
	display: block;
	height: 8px;
	background-color: var(--main-bg-color);
    border: solid 1px var(--main-border-color);
    border-width: 1px 0;
}
.table {
	border: solid 1px var(--main-border-color);
	border-radius: 6px;
	overflow: hidden;
}
.table_row {
	display: grid;
	grid-template-columns: 1fr 130px;
	align-items: center;
}
.table_2 .table_row {
	grid-template-columns: 1fr 1fr 100px;
}
.table_3 .table_row {
	grid-template-columns: 1fr 1fr 130px;
}
.table_namespace .table_row {
	grid-template-columns: 1fr 2.3fr 100px;
}

.table_row:not(:first-child) {
	border-top: solid 1px var(--main-border-color);
}
.table_head_row {
	composes: table_row;
	background-color: var(--main-bg-color);
	font-size: 14px;
	font-weight: 500;
	color: var(--grey-1);
}
.table_body_row {
	composes: table_row;
	background-color: var(--white);
}
.table_col {
	padding: 9px 8px;
	text-overflow: ellipsis;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}
.table_col:first-child {
	padding-left: 14px;
}
.table_col:last-child {
	text-align: right;
	padding-right: 14px;
}
.table_sub_label {
	--sub_label: 1;
	display: block;
	padding-left: calc(14px * calc(var(--sub_label) - 1));
}
.table_sub_label::before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	width: 8px;
	height: 8px;
	margin-right: 6px;
	border: dotted 1px var(--grey-3);
	border-width: 0 0 1px 1px;
}

/* Back title */
.card_back_title {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	padding-bottom: 8px;
	border-bottom: solid 1px var(--main-border-color);
	cursor: pointer;
	color: var(--grey-1);
	transition: all 200ms;
}
.card_back_title:hover,
.card_back_title:focus {
	color: var(--turquoise);
}
.card_back_title_icon {
	flex: 0 0 auto;
	font-size: 13px;
	color: inherit;
	margin-right: 10px;
}
.card_back_title_txt {
	flex: 1 1 auto;
	color: inherit;
	font-size: 13px;
	composes: ellips;
}

/* Select exemple values */
.exemple_values_label {
	font-weight: 500;
}
.exemple_values_list {
	margin-top: 10px;
}
.exemple_values_item {
	composes: ellips;
	margin: 5px 0;
	display: list-item !important;
	list-style-type: disc;
	list-style-position: inside;
}


/* Tags */

.title_tags_container {
	display: flex;
	flex-direction: column;
	padding: 40px;
	align-items: center;
}

.settings_container_tag_mapping_modal {
	margin-bottom: 29px;
}


/* Categories */
.choose_categories_type_container {
	border: solid 1px var(--main-border-color);
	display: flex;
	flex-direction: column;
	padding: 16px;
	margin: 3px;
}

.edit_message_categories_type {
	font-size: 13px;
	color: #8a8ea5;
	font-weight: 400;
	margin-bottom: 13px;
}

.edit_message_categories_type a {
	color: #006FFF;
}

.edit_message_categories_type a:hover {
	color: #006FFF;
	text-decoration: underline;
}

/* Transformations */
.transformations_wrapper {
	margin-top: 20px;
}
