.centerList {
    display: flex;
    align-items: flex-start;
    margin-bottom: 14px;
}
.centerListItems {
    flex: 0 1 auto;
    display: grid;
    gap: 8px 14px;
}
.centerListItems_1_item {
    width: 33%;
    grid-template-columns: 1fr;
}
.centerListItems_2_item {
    width: 66%;
    grid-template-columns: 1fr 1fr;
}
.centerListItems_3_item {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 1450px) {
    .centerListItems_1_item {
        width: 50%;
    }
    .centerListItems_2_item {
        width: 100%;
    }
    .centerListItems_3_item {
        grid-template-columns: 1fr 1fr;
    }   
}
.centerItem {
    min-width: 0;
    background-color: var(--white);
    border: solid 1px var(--main-border-color);
    border-radius: 6px;
    padding: 2px;
}
.centerItemOpen,
.centerItemClosed {
    composes: centerItem;
}

.centerItemHeader {
    cursor: pointer;
    border-radius: 4px;
    padding: 12px 18px;
    transition: background 200ms;
}
.centerItemHeader:hover,
.centerItemHeader:focus {
    background-color: var(--main-bg-color);
}

.centerItemBody {
    padding: 6px 18px 18px;
}

.centerItemPicto {
    margin-right: 16px;
}
.centerItemName {
    font-weight: 500;
}
.centerItemStatus {
    margin-left: 6px;
}
.centerItemFold {
    margin-right: -4px;
    color: var(--grey-4);
}
.centerItemFold i{
    display: block;
}

.centerItemDate{
    font-size: 14px;
    font-weight: 500;
    margin-top: 3px;
    color: var(--grey-1);
}
.centerItemDateArrow{
    color: var(--grey-1);
    font-size: 10px;
    margin: 0 2px;
    vertical-align: 1px;
}

.centerItemMainActions {
    margin-bottom: 18px;
}
.centerItemInfo {
    margin-bottom: 18px;
}
.centerItemInfoLine {
    font-size: 12px;
    color: var(--grey-2);
    margin: 6px 0;
}
.centerItemInfoLineLabel {
    font-weight: 500;
}


.centerAddWrapper {
    flex: 0 0 auto;
    min-width: 0;
    display: flex;
    align-items: stretch;
}
.centerListItems + .centerAddWrapper {
    margin-left: 14px;
}

.centerAdd {
    flex: 0 1 auto;
    min-height: calc(32px + 14px + 14px + 2px);
    border: dashed 2px var(--main-border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 14px 14px;
    background-color: var(--main-bg-color);
    color: var(--grey-4);
    transition: color 200ms, border 200ms;
    white-space: nowrap;
}
.centerAdd:hover,
.centerAdd:focus {
    color: var(--grey-3);
    border-color: var(--grey-5);
}
.centerAddIcon {
    margin-right: 8px;
}
.centerAddLabel {
    font-weight: 500;
}

/* Checklist (TODO : put in a component */
.checklist__header {
    margin-bottom: 22px;
    font-size: 15px;
}
.checklist__progress {
    margin-top: 8px;
}
.checklist__progress progress {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 8px;
}
.checklist__progress progress::-webkit-progress-bar {
    background-color: var(--main-bg-color);
    border-radius: 8px;
    box-shadow: var(--main-border-color) inset 0 0 0 1px;
}
.checklist__progress progress::-webkit-progress-value {
    background-color: var(--color-success);
    border-radius: 8px;
}

.checklist__list {
    font-size: 15px;
    margin: -11px;
}
.checklist__list > li {
    list-style-type: none;
}
.checklist__item {
    padding: 11px;
}
.checklist__link {
    composes: checklist__item;
    border-radius: 8px;
    transition: all 200ms;
}
.checklist__link:hover,
.checklist__link:focus {
    background-color: var(--turquoise-light);
}

.checklist__checkCommon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    margin-right: 16px;
}
.checklist__check {
    composes: checklist__checkCommon;
    border: solid 1px var(--grey-5);
}
.checklist__checkChecked {
    composes: checklist__checkCommon;
    background-color: var(--color-success);
    color: var(--white);
}
