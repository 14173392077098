.react-datepicker{
	display: flex;
}
.react-datepicker__month-container,
.react-datepicker__time-container{
	flex: none;
	clear: both;
}
.react-datepicker__header{
	background: #F6F7F8;
	transition: all .2s ease-in-out;
}
.react-datepicker-popper {
	z-index: 2;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
	border-bottom-color: #F6F7F8;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
	transition: all .2s ease-in-out;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
	background: #F6F7F8;
}
.react-datepicker__navigation--next{
	border-left-color: #9E9E9E;
	transition: all .2s ease-in-out;
}
.react-datepicker__navigation--previous{
	border-right-color: #9E9E9E;
	transition: all .2s ease-in-out;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow{
	border-top-color: #9E9E9E;
	transition: all .2s ease-in-out;
}
.react-datepicker__navigation--next:hover{
	border-left-color: #006fff;
}
.react-datepicker__navigation--previous:hover{
	border-right-color: #006fff;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow{
	border-top-color: #006fff;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected{
	background: var(--turquoise);
	transition: all .2s ease-in-out;
	color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover{
	background: var(--turquoise-dark);
}

/* Structure changes */
.react-datepicker__navigation{
	width: 30px;
	height: 30px;
	top: 3px;
	border: none;
	font-size: 0;
	color: transparent;
}
.react-datepicker__navigation--previous{
	left: 3px;
}
.react-datepicker__navigation--next{
	right: 3px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
	right: 88px;
}

.react-datepicker__navigation::before{
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	margin: 8px;
	border: 0.45rem solid transparent;
}
.react-datepicker__navigation--previous::before{
	border-right-color: #9E9E9E;
}
.react-datepicker__navigation--next::before{
	border-left-color: #9E9E9E;
}
