input[type=range].range_slider {
	display: block;
	width: 100%;
	height: 27px;
	-webkit-appearance: none;
	cursor: pointer;
}
input[type=range][disabled].range_slider{
	opacity: 0.5;
}
/*progress support*/
input[type=range].range_slider {
	--range: calc(var(--max) - var(--min));
	--ratio: calc((var(--value) - var(--min)) / var(--range));
	--sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}
input[type=range].range_slider.reverse {
	--sx: calc(100% - calc(0.5 * 2em + var(--ratio) * (100% - 2em)));
}

input[type=range].range_slider:focus {
	outline: none;
}

/* THUMB */
/* webkit */
input[type=range].range_slider::-webkit-slider-thumb {
	box-sizing: border-box;
	width: 19px;
	height: 19px;
	border-radius: 19px;
	background: #fff;
	border: solid 4px var(--turquoise);
	margin-top: -7px;
	-webkit-appearance: none;
	transition: all 200ms;
}
input[type=range].range_slider::-webkit-slider-thumb:active {
	box-shadow: 0 0 10px rgba(0, 111, 255, .3);
	border-width: 3px;
}
/*mozilla*/
input[type=range].range_slider::-moz-range-thumb {
	box-sizing: border-box;
	width: 19px;
	height: 19px;
	border-radius: 19px;
	background: #fff;
	border: solid 4px var(--turquoise);
	transition: all 200ms;
}
input[type=range].range_slider::-moz-range-thumb:hover {
	border-width: 3px;
	box-shadow: 0 0 10px rgba(0, 111, 255, .3);
}


/* TRACK */
/* webkit */
input[type=range].range_slider::-webkit-slider-runnable-track {
	height: 5px;
	border-radius: 5px;
	background: #E9EAF0;
	border: none;
	box-shadow: none;
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
input[type=range].range_slider:hover::-webkit-slider-runnable-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
input[type=range].range_slider:active::-webkit-slider-runnable-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
input[type=range].range_slider.reverse::-webkit-slider-runnable-track {
	background-position: right;
}

/*mozilla*/
input[type=range].range_slider::-moz-range-track {
	height: 5px;
	border-radius: 5px;
	background: #E9EAF0;
	border: none;
	box-shadow: none;
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
input[type=range].range_slider:hover::-moz-range-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
input[type=range].range_slider:active::-moz-range-track {
	background: linear-gradient(var(--turquoise),var(--turquoise)) 0/var(--sx) 100% no-repeat, #E9EAF0;
}
input[type=range].range_slider.reverse::-moz-range-track {
	background-position: right;
}

/* Bottom desc */
.range_slider_bottom_desc {
	color: var(--grey-4);
	font-size: 11px;
	line-height: 18px;
}
.range_slider_bottom_desc div{
	flex: 0 1 100%;
	text-align: center;
}
.range_slider_bottom_desc div:first-child{
	text-align: left;
}
.range_slider_bottom_desc div:last-child{
	text-align: right;
}
