.category_product_feed_category_container {
    border-bottom: 2px solid #EBEBEB;
    border-radius: 2px;
    margin: 0px auto 15px 15px;
    padding-bottom: 20px;
    width: 95%;
}

.category_product_header {
    position: relative;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
}

.category_product_zone_actions {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede66;
}

.category_product_zone_actions_active {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 3px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede66;
}

.category_feed_add_hierarchie_btn {
    position: relative;
}

.chevron:hover {
    color: #006fff;
}

.name-zone-chevron {
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
}

.name-zone-chevron:hover {
    color: #006fff;
}

.container_hierarchie {
    display: none;
}

.display_container_hierarchie {
    display: block;
}

.header_hierarchie {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.header_hierarchie input {
    width: 450px;
}

.header_hierarchie span {
    color: #9e9e9e;
    position: relative;
    right: 20px;
    top: 25px;
    font-size: 17px;
    font-weight: bold;
}

.header_hierarchie span:hover {
    color: #006fff;
    cursor: pointer;
}

.category_hierarchie_container {
    display: flex;
    flex-direction: column;
}

.category_hierarchie_container li {
    display: flex;
    flex-direction: column;
}

.header_list_categorie {
    display: flex;
    align-items: center;
}

.header_list_categorie input {
    width: 320px;
}

.header_list_categorie select {
    height: 55px;
    margin-bottom: 15px;
    border-radius: 5px;
    border-color: #b6b6b6;
}

.custom_input_prestashop {
    display: flex;
}

.custom_input_prestashop input {
    width: 290px;
}

.delete-hierarchie:hover {
    color: red;
}

.delete-category:hover {
    color: red;
}