.Hierarchization_container {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Hierarchization_container ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 17px auto;
}

.Hierarchization_container ul li {
    display: flex;
    justify-content: space-evenly;
    height: 120px;
    width: 100%;
    list-style-type: none;
    border-bottom: 1px solid #9999994a;
    box-shadow: rgba(0, 0, 0, .1) 0 0 20px;
    margin: 5px auto;
}

.Hierarchization_container ul li span {
    width: 60px;
    margin: auto;
    text-align: center;
}

.Hierarchization_container ul li span i {
    font-size: 40px;
}

.Hierarchization_container ul li span i:hover {
    cursor: pointer;
    color: #006fff;
}

.Hierarchization_container ul li div {
    width: 450px;
}

.Hierarchization_container ul li div p {
    width: 100%;
}

.Hierarchization_container ul li div h2 {
    font-weight: bold;
    text-align: center;
}

.Hierarchization_container p {
    padding: 5px;
    font-size: 17px;
    text-align: center;
}

.hierarchization_return_btn {
    position: fixed;
    top: 14px;
    right: 16px;
    width: 36px;
    background-color: white;
}

.hierarchization_return_btn i {
    font-size: 27px;
    color: #9e9e9e;
}

.hierarchization_return_btn i:hover {
    color: #006fff;
    cursor: pointer;
}

.btn_hierarchization_container {
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    position: fixed;
    bottom: 14px;
    right: 50px;
}