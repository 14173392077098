/* Mask */
.mask{
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	min-height: 60px;
	background: rgba(255,255,255,.6);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}
.mask.has_delay {
	-webkit-animation: mask_fadein 400ms ease-in-out;
	animation: mask_fadein 400ms ease-in-out;
}
.mask.has_progress{
	background-image: repeating-linear-gradient(
        -45deg,
		rgba(255,255,255,.06),
        rgba(255,255,255,.06) 6px,
        rgba(76,82,105,.06) 6px,
        rgba(76,82,105,.06) 12px
    );
	background-color: transparent;
	-webkit-animation: mask_progress 600ms linear infinite;
	animation: mask_progress 600ms linear infinite;
}

@-webkit-keyframes mask_fadein{
	from {opacity: 0;}
	50% {opacity: 0;}
	to {opacity: 1;}
}
@keyframes mask_fadein{
	from {opacity: 0;}
	50% {opacity: 0;}
	to {opacity: 1;}
}

@-webkit-keyframes mask_progress{
	from {background-position: 0 0;}
	to {background-position: 17px 0;}
}
@keyframes mask_progress{
	from {background-position: 0 0;}
	to {background-position: 17px 0;}
}
