.variants_section{
	margin-top: 50px;
	margin-bottom: 50px !important;
}

.variants_toolbar{
	float: right;
	padding-top: 10px;
}
.variants_toolbar .variants_import{
	display: inline-block;
}
.variants_toolbar .variants_import .csv-input{
	visibility: hidden;
	position: absolute;
}

.variants_toolbar .variants_import .csv-label,
.variants_toolbar .variants_btn{
	display: inline-block;
	font-weight: 500;
	text-transform: uppercase;
	color: #006fff;
}
.variants_toolbar .variants_import .csv-label,
.variants_toolbar .dropdown{
	margin: 0 0 0 16px;
}

.variants_body{
	padding-top: 16px;
}
.variants_empty{
	padding: 16px;
	border: solid 1px #eee;
	text-align: center;
	color: #777;
}
.variants_message{
	padding-top: 16px;
	font-size: 14px;
}

/* Table */
.variants_body .react-bootstrap-table{
	position: relative;
	overflow: auto;
	max-height: 500px;
	border: solid 1px #eee;
	margin-bottom: 8px;
}
.variants_body .react-bootstrap-table table{
	max-width: none;
	width: auto;
	min-width: 100%;
	margin: -1px;
	border: none;
}
.variants_body .react-bootstrap-table table th,
.variants_body .react-bootstrap-table table td{
	border: solid 1px #eee;
	border-width: 1px 0 0 1px;
	padding: 8px 12px;
}
.variants_body .react-bootstrap-table table th{
	background: #f8f8f8;
	font-weight: 500;
	position: sticky;
	top: 0;
	border-bottom: solid 1px #eee;
}

.variant_overlay{
	position: fixed;
	z-index: 100000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 24px;
	background: rgba(255,255,255,.2);
	cursor: not-allowed;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.variant_overlay_message{
	background: #4B68E5;
	border-radius: 6px;
	padding: 16px 24px;
	color: #fff;
	cursor: pointer;
	margin-bottom: -100px;
	animation: 400ms ease-in-out 100ms variant_preview both;
}
.variant_overlay_message .fas{
	margin-left: 10px;
}
@keyframes variant_preview {
	0%{
		margin-bottom: -100px;
	}
	80%{
		margin-bottom: 20px;
	}
	100%{
		margin-bottom: 0;
	}
}
