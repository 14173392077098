.content.hidden {
    transform: translateX(100%);
}

.content {
    position: fixed;
    z-index: 4;
    right: 0;
    top: 0;
    background-color: #FFF;
    width: 340px;
    height: 100%;
    padding: 70px 25px 0 25px;
    color: #707070;
    transition: transform .3s ease-in-out;
    transform: translateX(0%);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.33);
}

.title {
    font-size: 20px;
}

.description {
    font-size: 13px;
}

.intro {
    top: 99px;
    left: 28px;
    width: 276px;
    height: 150px;
    text-align: left;
    font: 13px;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    text-align: left;
}