.skuSection{
    padding: 10px;
    border: 1px solid #b6b6b6;
    font-weight: 400;
    border-radius: 5px;
    width: 100%;
    resize: none;
    margin-top: 5px;
    height: 250px;
}
.skuSection::-webkit-input-placeholder {
    font-weight: 300;
    font-style: italic;
    color: #9E9E9E;
}