.table {
    overflow: auto;
    margin-bottom: 16px;
}
.table :global(.table_row){
    flex-wrap: nowrap;
    font-size: 14px;
    min-width: 1730px;
}
.table :global(.table_col) {
    flex: 0 0 auto;
}
.table :global(.table_col:nth-child(1)) {
    width: min(30%, 350px);
}
.table :global(.table_col:nth-child(2)),
.table :global(.table_col:nth-child(3)),
.table :global(.table_col:nth-child(4)) {
    width: min(10%, 120px);
}
.table :global(.table_col:nth-child(5)),
.table :global(.table_col:nth-child(6)),
.table :global(.table_col:nth-child(7)) {
    width: min(15%, 240px);
}
.table :global(.table_col:nth-child(8)) {
    width: min(20%, 300px);
}

.table :global(.table_head_row .table_col){
    font-size: 13px;
}
.product_main_data {
    flex: 1 1 auto;
}
.product_name {

}
.product_id {
    font-size: 13px;
    color: var(--grey-2);
    font-weight: 500;
}
.product_category_list {
    min-width: 0;
}
.product_category {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
