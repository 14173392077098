@value ellips from "../../../../Components/utils.module.css";

.title {
    font-weight: 500;
}
.description {
    color: var(--grey-2);
    font-size: 13px;
}
.date {
    border-bottom: solid 1px var(--grey-7);
    padding-bottom: 2px;
}
.date_item {
    font-weight: 500;
}
.date_arrow {
    font-size: 10px;
    margin: 0;
    vertical-align: 1px;
    color: var(--grey-4);
}
.date_sep {
    margin: 0 8px;
}
.list {
    position: relative;
}
.list_has_before_shadow::before,
.list_has_after_shadow::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 16px;
    pointer-events: none;
}
.list_has_before_shadow::before {
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.list_has_after_shadow::after {
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.track {
    display: flex;
    overflow: auto;
    gap: 16px;
    margin-top: 12px;

    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.item_empty {
    flex: 0 0 auto;
    width: 320px;
}
.item {
    scroll-snap-align: start;
    flex: 0 0 auto;
    width: 320px;
    display: flex;
    align-items: center;
    border: solid 1px var(--main-border-color);
    background-color: var(--main-bg-color);
    border-radius: 8px;
    padding: 12px;
    transition: all 200ms;
}
.item_clickable {
    cursor: pointer;
}
.item_clickable:focus,
.item_clickable:hover {
    border-color: var(--grey-5);
}
.item_col_img {
    flex: 0 0 auto;
    position: relative;
}
.item_col_info {
    flex: 1 1 auto;
    min-width: 0;
}
.item_col_nb {
    flex: 0 0 auto;
    margin-left: 12px;
}

.item_format {
    composes: ellips;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--grey-2);
}
.item_name {
    composes: ellips;
    margin-top: 2px;
    font-weight: 500;
    font-size: 14px;
}
.item_score {
    margin-top: 2px;
}
.item_nb {
    font-size: 18px;
    font-weight: 500;
    background-color: var(--white);
    border: solid 1px var(--main-border-color);
    border-radius: 8px;
    padding: 3px 8px;
    min-width: 34px;
    text-align: center;
}

.more_btn {
    margin-top: 10px;
    margin-left: -10px;
}
