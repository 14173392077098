.select_list_tooling_Container {

}

.select_list_tooling li {
    margin: 16px auto;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}

.select_list_tooling li:hover {
    color: darkturquoise;
}
