.link {
	color: inherit;
}

.link:not(.is_disabled) {
	cursor: pointer;
}

.link.is_disabled {
	cursor: not-allowed;
	opacity: .5;
}

.is_underline,
.link:not(.is_disabled):hover,
.link:not(.is_disabled):focus {
	text-decoration: underline;
}
.is_strong {
	font-weight: 500;
}

.color_primary {
	color: var(--turquoise);
}
.color_white {
	color: var(--white);
}
.color_black {
	color: var(--black);
}

/* Icon */
.first_icon {
	margin-right: 6px;
}
.last_icon {
	margin-left: 6px;
}

.icon_on_hover{
	position: relative;
	opacity: 0;
	left: -6px;
	transition: all 100ms;
}
.link:hover .icon_on_hover,
.icon_on_hover.is_hovered {
	opacity: 1;
	left: 0;
}