.avatar{
	border-radius: 100%;
	display: inline-block;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	line-height: 32px;
	background: #ccc;
	color: #777;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	user-select: none;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased !important;
	-moz-font-smoothing: antialiased !important;
	text-rendering: optimizelegibility !important;
}

.avatar.size_xxs {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 9px;
}
.avatar.size_xs {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
}
.avatar.size_s{
	width: 28px;
	height: 28px;
	line-height: 28px;
	font-size: 14px;
}
.avatar.size_m{
	width: 32px;
	height: 32px;
	line-height: 32px;
	font-size: 16px;
}
.avatar.size_l{
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
}
.avatar.size_xl{
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 24px;
}
.avatar.size_xxl{
	width: 64px;
	height: 64px;
	line-height: 64px;
	font-size: 32px;
}

.avatar.color_red{
	color: #D53B16;
	background: #FFC5B7;
}
.avatar.color_orange{
	color: #AF5700;
	background: #FDC893;
}
.avatar.color_yellow{
	color: #A38A00;
	background: #FFF3AF;
}
.avatar.color_green_apple{
	color: #3B891F;
	background: #CCEEC0;
}
.avatar.color_green{
	color: #119050;
	background: #C0EED7;
}
.avatar.color_lightblue{
	color: #025EA0;
	background: #B8DFFB;
}
.avatar.color_blue{
	color: #142FA5;
	background: #B4C1F7;
}
.avatar.color_purple{
	color: #560E81;
	background: #E4C9F5;
}
.avatar.color_pink{
	color: #74246A;
	background: #EDB5E7;
}