.wrapper {
    padding: 14px 16px;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
	position: relative;
}
.wrapper_appearance_background {
    /* */
}
.wrapper_appearance_outline {
    border: solid 1px var(--main-border-color);
}
.wrapper_appearance_ghost {
    padding: 0;
}
.wrapper_appearance_background_outline {
    composes: wrapper_appearance_background;
    border: solid 1px;
}
.wrapper_margin_s {
    margin-bottom: 10px;
}
.wrapper_margin_m {
    margin-bottom: 16px;
}
.wrapper_margin_l {
    margin-bottom: 26px;
}
.wrapper_no_margin {
    margin-bottom: 0;
}

/* Size */
.wrapper_size_s {
	padding-top: 10px;
	padding-bottom: 10px;
}
.wrapper_size_xs {
	padding-top: 8px;
	padding-bottom: 8px;
}

/* ICON */
.col_icon {
    flex: 0 0 auto;
    font-size: 20px;
    margin-right: 16px;
}
.wrapper_icon_size_m .col_icon { font-size: 20px; margin-right: 16px;}
.wrapper_icon_size_l .col_icon { font-size: 28px; margin-right: 18px;}
.wrapper_icon_size_xl .col_icon { font-size: 36px; margin-right: 20px;}

/* CONTENT */
.col_content {
    flex: 1 1 auto;
    align-self: center;
}
.title {
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
}
.text {
    color: var(--grey-0);
    font-size: 14px;
    line-height: 1.4;
}
.text_l {
    composes: text;
	font-size: 15px;
}
.title + .text {
	margin-top: 3px;
}
.text p + p,
.text ul + p,
.text p + ul,
.text ul + ul {
    margin-top: .5em;
}
.text ul {
	padding: 0 1em;
}
.text li {
	list-style-position: outside;
	list-style-type: disc;
}

/* Font size */
.wrapper_font_size_s {}
.wrapper_font_size_s .title{ font-size: 14px;}
.wrapper_font_size_s .text{ font-size: 13px;}
.wrapper_font_size_s .text_l{ font-size: 14px;}

.wrapper_font_size_l {}
.wrapper_font_size_l .title{ font-size: 17px;}
.wrapper_font_size_l .text{ font-size: 15px;}
.wrapper_font_size_l .text_l{ font-size: 16px;}

/* Close */
.col_close {
    margin-right: -10px;
    margin-top: -6px;
}
.wrapper_appearance_ghost .col_close{
    margin-right: 0;
}

/* WRAPPER BACKGROUND COLORS */
.wrapper_appearance_background.wrapper_type_information {
    background-color: #E3F0FF;
}
.wrapper_appearance_background.wrapper_type_success {
    background-color: #DCF8E3;
}
.wrapper_appearance_background.wrapper_type_warning {
    background-color: #FFEFD0;
}
.wrapper_appearance_background.wrapper_type_alert {
    background-color: #FFEAEB;
}
.wrapper_appearance_background.wrapper_color_purple {
    background-color: #f1e0fc;
}

/* WRAPPER BORDER COLORS */
.wrapper_appearance_background.wrapper_type_information {
    border-color: #c2dcff;
}
.wrapper_appearance_background.wrapper_type_success {
    border-color: #A3E5B3;
}
.wrapper_appearance_background.wrapper_type_warning {
    border-color: #FFD993;
}
.wrapper_appearance_background.wrapper_type_alert {
    border-color: #FFBEC2;
}
.wrapper_appearance_background.wrapper_color_purple {
    border-color: #D9B6EF;
}

/* ICON COLOR */
.wrapper_type_information .col_icon,
.wrapper_color_information .col_icon {
    color: var(--turquoise);
}
.wrapper_type_success .col_icon,
.wrapper_color_success .col_icon {
    color: #058B25;
}
.wrapper_type_warning .col_icon,
.wrapper_color_warning .col_icon {
    color: #EF9B00;
}
.wrapper_type_alert .col_icon,
.wrapper_color_alert .col_icon {
    color: #F04F5B;
}
.wrapper_color_purple .col_icon {
    color: #560E81;
}

/* LINKS WRAPPER */
.links_wrapper {
    margin-top: 4px;
}

