.choice {}

.advertAlgo {
	font-size: 15px;
	color: #000000;
	margin-top: 26px;
}

.modalBody.modalBodyCrossSell {
	margin-top: 0;
	display: block;
	min-width: 675px;
	max-height: none;
}

.modalBodyCrossSell input[type=radio] {
	display: none;
	cursor: pointer;
}

.modalBodyCrossSell textarea:focus {
	border: 1px solid #006fff;
}

.cross_sell_label.top_margin_l {
	margin-top: 26px;
}

/* TODO : @Romain ranger ça à un endroit plus centrale */

.form_section {
	margin-bottom: 26px;
}

.form_section_label {
	display: block;
	font-weight: bold;
	font-size: inherit;
	margin: 12px 0 6px;
}

.listBtnRecommendation {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.radio_boxCrossSell {
	background: #FFF;
	border: 1px solid #969696;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	width: 125px;
	position: relative;
	text-align: center;
	line-height: 14px;
	font-weight: normal;
	padding: 10px 15px;
	margin: 0px 20px 20px 0px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.radio_boxCrossSell:before {
	content: "";
	position: absolute;
	top: -1px;
	left: -1px;
	width: -webkit-calc(100% + 2px);
	width: -moz-calc(100% + 2px);
	width: calc(100% + 2px);
	height: -webkit-calc(100% + 2px);
	height: -moz-calc(100% + 2px);
	height: calc(100% + 2px);
	border: 3px solid #006fff;
	opacity: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.radio_boxCrossSell:hover:before, .radio_boxCrossSell.checked:before {
	opacity: 1;
}

.radio_boxCrossSell span {
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
	background: #006fff;
	color: #FFF;
	font-size: 12px;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 2px;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-border-radius: 5px 0 5px 0;
	-moz-border-radius: 5px 0 5px 0;
	border-radius: 5px 0 5px 0;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-o-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.radio_boxCrossSell.checked span {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.radio_boxCrossSell_img {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 50px;
}

.radio_boxCrossSell_img i {
	font-size: 22px;
}

.radio_boxCrossSell_img img {}

.radio_boxCrossSell_labels {
	min-height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.radio_boxCrossSell_labels p {
	overflow: hidden;
	text-overflow: ellipsis;
}

.radio_boxCrossSell_labels .name {}

.radio_boxCrossSell_labels .description {
	font-size: 11px;
}

.containAddAlgo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}