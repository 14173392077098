.dashboard-statistics-container-body,
.dashboard-statistics-container-header {
    display: grid;
    grid-template-columns: 40px 2fr 90px 2fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
}
.dashboard-statistics-container-header {
    font-weight: bold;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #F6F7F8;
}
.dashboard-statistics-container-body {
    font-weight: 400;
    border-bottom: 1px solid #cdcdcd75;
    cursor: pointer;
}

.dashboard-statistics-head,
.dashboard-statistics-body{
    padding: 10px;
    text-align: center;
}
.dashboard-statistics-head-name,
.dashboard-statistics-body-name{
    text-align: left;
}
.dashboard-statistics-head-banner,
.dashboard-statistics-body-banner {
    text-align: left;
}

.dashboard-statistics-body-banner img {
    display: block;
    width: auto;
    height: auto;
    max-height: 110px;
    max-width: 100%;
}

.is-active-stats-banner {
    background-color: #006fff50;
}
