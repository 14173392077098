.product_collection_container {
    height: auto !important;
}
.product_collection_header{
    border-bottom: solid 1px var(--main-border-color);
    padding: 16px 22px;
}
.product_collection_header_back{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--turquoise);
    font-size: 18px;
    margin-left: -10px;
    margin-right: 8px;
}
.product_collection_header_title{
    font-size: 18px;
    font-weight: 500;
}
.product_collection_body{
    overflow: hidden;
}
.product_collection_sidebar {
    width: 380px;
    align-items: stretch;
    border-right: 1px solid var(--main-border-color);
    background-color: #FFFFFF;
    padding: 29px 22px;
    color: var(--grey-0);
}
.product_collection_products {
    background-color: #F5F6F8;
    height: auto !important;
}


/* Sidebar content */
.product_collection_sidebar .numberCircle {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    padding: 7px;
    background: var(--grey-0);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product_collection_sidebar_block {
    margin-bottom: 34px;
}

.product_collection_sidebar_block_title {
    font-weight: 500;
    margin-bottom: 10px;
}
