.email_provider{
    font-weight: 400;
}
.email_provider_name{
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
}
.email_provider_change{
    margin-top: 2px;
}
.email_provider_change a{
    font-size: 12px;
    font-weight: 500;
    color: #006fff;
    cursor: pointer;
}

.email_provider .icon_btn{
    margin-left: 0;
    margin-right: 10px;
}

.tooling_status {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    margin-left: 8px;
}
.tooling_status.ongoing {
    background: #5cb85c;
}
.tooling_status.stopped {
    background: #d76666;
}