.codePromo_Container {
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 200px;
    text-align: center;
    margin: 25px auto;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #0000001A;
}

.codePromo_Container div {
    display: flex;
    margin: auto;
    width: 90%;
}