.inpage_mockup{
	margin-top: 6px;
}
.inpage_mockup_col__before,
.inpage_mockup_col__after,
.inpage_mockup_col__center{
	width: 20%;
}
.inpage_mockup_col__in{
	overflow: hidden;
}
.inpage_mockup_col__before .btnUi,
.inpage_mockup_col__after .btnUi,
.inpage_mockup_col__center .btnUi{
	width: 100%;
}
.inpage_mockup_col_inner {
	padding: 10px;
}
.inpage_mockup_col__before .inpage_mockup_col_inner{
	padding: 11px 11px 11px 0;
}
.inpage_mockup_col__after .inpage_mockup_col_inner{
	padding: 11px 0 11px 11px;
}
.inpage_mockup_col_inner_highlighted{
	padding: 10px;
	border: dashed 1px #F4891E;
	border-radius: 8px;
}

.inpage_mockup_el_name{
	font-weight: 500;
	text-align: center;
	margin-top: 4px;
	color: #F4891E;
}


.inpage_selector_outro{
	margin: 40px 0;
}
.inpage_selector_outro_icon{
	width: 44px;
	height: 44px;
	border-radius: 44px;
	line-height: 44px;
	background: var(--green);
	color: #fff;
	font-size: 18px;
	text-align: center;
	margin-right: 22px;
}
.inpage_selector_outro_icon i{
	vertical-align: middle;
}
.inpage_selector_outro_text {
	color: var(--grey-0);
	font-size: 22px;
	font-style: italic;
}
.inpage_selector_outro_text strong{
	color: var(--green);
	font-weight: 600;
	display: inline-block;
}
