.progress_bar {
	height: 6px;
	border-radius: 6px;
	background-color: var(--grey-7);
	overflow: hidden;
}
.progress_bar_cursor {
	background-color: var(--turquoise);
	width: 0;
	height: 100%;
	border-radius: inherit;
	transition: width 200ms;
}
.value {
	margin-left: 12px;
	font-size: 14px;
}
