.resize_row {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 52px 2fr .8fr 1fr 1fr 30px;
	grid-template-columns: 52px 2fr .8fr 1fr 1fr 30px;
	padding: 10px;
	background: #FFF;
	transition: all .1s ease-in-out;
    border-bottom: 1px solid #eee;
    align-items: center;
}

.resize_row:not(.resize_head):hover {
	background: #F7F8FA;
}

.resize_row.resize_head{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    color: #5D637E;
}

.resize_row .grid-resize.center {
    display: flex;
    justify-content: center;
}

.resize_row .resize-title {
	font-weight: 500;
}

.resize_row .resize-id{
	font-size: 12px;
	margin-top: 2px;
}
.resize_row .grid-resize-status{
	text-transform: capitalize;
}

.resize_table .resize-detail {
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: flex-start;
	-webkit-box-pack: start;
	-moz-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 3px 0;
}

.resize_table .resize-links-label {
	font-size: 14px;
}




.resize_table_onsite .resize_row>div {
	padding: 10px;
}

.resize_table_email {
	-ms-grid-columns: 500px 1fr 1fr 1fr 1fr 90px;
	grid-template-columns: 500px 1fr 1fr 1fr 1fr 90px;
}

.resize_table>div {
	padding: 10px;
}

.resize_table .grid-head {
	cursor: pointer;
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	background: #F6F7F8;
	font-size: 12px;
	font-weight: 700;
	color: #293133;
	text-transform: uppercase;
	transition: all .2s ease-in-out;
}

.resize_table .grid-head:hover {
	color: #006fff;
}

.resize_table .grid-head i {
	color: #006fff;
}

.resize_format + .resize_format{
	margin-top: 16px;
}
.resize_format{
	border-bottom: solid 1px #eee;
	padding-bottom: 16px;
}