.navigation_feed_container {
    height: 60px;
    width: 100%;
    color: #a8a7a7;
    background-color: #F4F4F7;
    border-top: 1px solid #F4F4F7;
    border-bottom: 1px solid #F4F4F7;
    display: flex;
}

.navigation_feed_container ul {
    display: flex;
    justify-content: space-around;
}

.navigation_feed_container ul li {
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding-bottom: 3px;
    height: 58px;
    min-width: 60px;
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    cursor: pointer;
}

.navigation_feed_container ul li p {
    margin: auto;
}

.navigation_feed_container ul li p:hover {
    color: #006fff;
}

.navigation_feed_active {
    color: #006fff;
    border-bottom: 3px solid #006fff;
    font-weight: 500;
}

.last_items_navigation_feed {
    position: fixed;
    right: 16px;
    width: 145px;
    top: 14%;
    display: flex;
    color: #9e9e9e;
}

.last_items_navigation_feed span {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    min-width: 35px;
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    cursor: pointer;
}

.last_items_navigation_feed span i {
    margin-right: 5px;
}

.last_items_navigation_feed span:hover {
    color: #006fff;
}