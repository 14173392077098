.container {
    display: flex;
    flex-direction: column;
    min-width: 167px;
    font-weight: normal;
}
.label {
    text-align: left;
    font-size: 14px;
    color: var(--grey-2);
}

.valueContainer {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}

.value {
    font-size: 26px;
    font-weight: 500;
    color: var(--grey-0);
    margin-right: 9.5px;
}

.tendance {
    font-size: 12px;
}

.loadingBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 83px;
}

.loadingBar {
    border-radius: 5px;
    height: 15px;
    width: 60%;
}

.loadingBarPercent {
    border-radius: 5px;
    height: 7px;
    width: 15%;
    margin-top: 10px;
}

.play .loadingBar {
    background: linear-gradient(
        to right, 
        rgba(238,236,239,1) 0%,
        rgba(238,236,239,1) 45%,
        rgba(238,236,239,0) 50%,
        rgba(238,236,239,1) 55%,
        rgba(238,236,239,1) 100%
    );
    animation-duration: 1s;
    animation-name: load;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    background-size: 400% 100%;
    margin-bottom: .5em;
}

.loadingBar:last-of-type {
    margin-bottom: 10px;
}

@keyframes load {
    from {
        background-position: 0% 50%
    }
    to { 
        background-position: 100% 50%
    }
}