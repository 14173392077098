.operations_timeline_container {
    width: 100%;
}

.marker_day_selected {
    text-align: left;
}

.marker_day_selected span {
    height: 60vh;
    width: 2px;
    background: #006fff;
    position: absolute;
    z-index: 1;
}