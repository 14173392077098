.RecosettingListContainer {
    margin-bottom: 40px;
    padding-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 6px 49px 0px rgba(220, 220, 220, 0.9);
    border-radius: 5px;
}

.selectContainerReco {
    display: flex;
    align-items: flex-start;
}

.selectContainerReco_col_algo {
    flex: 0 1 auto;
    width: 40%;
}
.selectContainerReco_col_nb {
    flex: 0 0 auto;
    margin-left: 24px;
}
.selectContainerReco_col_context {
    flex: 0 1 auto;
    width: 30%;
    margin-left: 24px;
}
.selectContainerReco_col_del {
    flex: 0 0 auto;
    margin-left: 10px;
}

.recoSelectorAlgorithm {
    display: flex;
    flex-direction: row;
}

.selectRecoContext {
    width: 422px;
    height: 40px;
    border: 1px solid #9e9e9e;
    background: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    padding-left: 5px;
}

.selectReco {
    width: 422px;
    height: 40px;
    border: 1px solid #9e9e9e;
    background: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    padding-left: 5px;
}

.selectRecoNbProduct {
    width: 120px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 5px;
    outline: none;
}

.labelRecoNbProduct {
    margin-right: 35%;
}

.reco_actions {
    margin-top: 8px;
    margin-bottom: 20px;
}

.btn_add_algo {
    width: 160px;
    height: 40px;
    border: 1px solid #9E9E9E;
    color: #9E9E9E;
    background: #FFFFFF;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}

.btn_add_algo:hover {
    border-color: #006fff;
    color: #006fff;
}

.recosPlusIcon {}