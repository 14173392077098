.list {
	--positive-offset: 0px;
	--negative-offset: 0px;
}
.list.list_horizontal_area_offset {
	--positive-offset: 10px;
	--negative-offset: -10px;
}

.list_size_l.list_remove_vertical_margin {
	margin-top: -18px;	
	margin-bottom: -18px;
}
.list_size_m.list_remove_vertical_margin {
	margin-top: -14px;	
	margin-bottom: -14px;
}
.list_size_s.list_remove_vertical_margin {
	margin-top: -10px;
	margin-bottom: -10px;
}
.list_size_xs.list_remove_vertical_margin {
	margin-top: -6px;
	margin-bottom: -6px;
}
.list_size_xxs.list_remove_vertical_margin {
	margin-top: -4px;
	margin-bottom: -4px;
}

/* Item */
.list_item {
	display: flex;
	align-items: center;
	position: relative;
	border-radius: 5px;
	margin-left: var(--negative-offset);
	margin-right: var(--negative-offset);
	padding-left: var(--positive-offset);
	padding-right: var(--positive-offset);
}
.list_size_l .list_item {
	padding-top: 18px;
	padding-bottom: 18px;
	min-height: 60px;
}
.list_size_m .list_item {
	padding-top: 14px;
	padding-bottom: 14px;
	min-height: 52px;
}
.list_size_s .list_item {
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 44px;
}
.list_size_xs .list_item {
	padding-top: 6px;
	padding-bottom: 6px;
	min-height: 36px;
}
.list_size_xxs .list_item {
	padding-top: 4px;
	padding-bottom: 4px;
	min-height: 24px;
}
.list_item_clickable {
	cursor: pointer;
	transition: background 200ms;
}
.list_item_clickable:hover,
.list_item_clickable:focus {
	background-color: var(--main-bg-color);
}
.list_item_is_active,
.list_item_is_active:hover,
.list_item_is_active:focus {
	background-color: var(--turquoise-light);
	color: var(--turquoise);
}

/* Dragging */
.list_item_is_dragging {
	background-color: var(--white);
	box-shadow: var(--main-border-color) 0 0 0 2px, rgba(0,0,0,.1) 0 0 10px;
}

/* Item border */
.list_has_border .list_item:not(:first-child) {
	margin-top: 1px;
}
.list_has_border .list_item:not(:first-child)::before,
.list_has_last_border .list_item:last-child::after,
.list_item_force_border::before {
	content: "";
	position: absolute;
	border-top: solid 1px var(--main-border-color);
	left: var(--positive-offset);
	right: var(--positive-offset);
}
.list_has_border .list_item:not(:first-child)::before,
.list_item_force_border::before {
	top: -1px;
}
.list_has_last_border .list_item:last-child::after {
	bottom: -1px;
}
.list_has_last_inner_border .list_item::before,
.list_has_last_inner_border .list_item::after {
	left: 16px;
    right: 16px;
}
.list_has_inner_picto_border .list_item::before,
.list_has_inner_picto_border .list_item::after {
	left: 60px;
    right: 16px;
}

/* Cols */
.col_main {
	flex: 1 1 auto;
	min-width: 0;
}
.col_picto {
	flex: 0 0 auto;
	margin-right: 12px;
}
.list_size_l .col_picto {
	margin-right: 16px;
}
.col_picto_margin {
	composes: col_picto;
	margin-left: 16px;
}
.col_actions {
	flex: 0 0 auto;
	margin-left: 20px;
}
.col_value {
	flex: 0 0 auto;
	margin-left: 16px;
	color: var(--grey-2);

	text-overflow: ellipsis;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}
.col_arrow {
	flex: 0 0 auto;
	margin-left: 16px;
	color: var(--grey-3);
	padding-right: 16px;
}

/* Content */
.text {
	color: var(--grey-0);
}
.text_strong {
	composes: text;
	font-weight: 500;
}
.description {
	color: var(--grey-3);
}
.list_item_italic .text,
.list_item_italic .description {
	font-style: italic;
}

.list_item_is_active .text,
.list_item_is_active .description {
	color: inherit;
}

/* Font size */
.list_font_size_m .text { font-size: 15px; }
.list_font_size_m .description { font-size: 13px; margin-top: 1px; }

.list_font_size_l .text { font-size: 16px; }
.list_font_size_l .description { font-size: 14px; margin-top: 3px; }