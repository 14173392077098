.audit_header {
    margin-bottom: 16px;
    margin-top: 10px;
}
.audit_data {
    font-size: 13px;
    color: var(--grey-2);
}
.audit_title {
    font-size: 17px;
    font-weight: 500;
    margin-top: 2px;
}
.audit_period_label {
    font-size: 13px;
    color: var(--grey-2);
    margin-right: 10px;
}

/* Scenario */
.scenario_header {
    border-bottom: solid 1px var(--main-border-color);
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.scenario_name {
    font-size: 17px;
    font-weight: 500;
}

/* Prediction */
.prediction_line_picto {
    margin-right: 16px;
}
.prediction_line_type {
    font-size: 15px;
    font-weight: 500;
}
.prediction_line_text {
    font-size: 14px;
    color: var(--grey-1);
    margin-top: 3px;
}

/* Graph */
.graph_outer {
    position: relative;
    width: 300px;
    height: 42px;
    margin-left: 40px;
    background-repeat: no-repeat;
    background-size: contain;
}
.graph_outer_max,
.graph_outer_min_max {
    composes: graph_outer;
}
.graph_outer_max {
    background-image: url("/Assets/scenario_reco_graph_max.svg");
}
.graph_outer_min_max {
    background-image: url("/Assets/scenario_reco_graph.svg");
}
.graph_outer::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: 2px;
    background-color: #AAD6BC;
}

.graph_inner {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.graph_outer_max .graph_inner {
    width: 156px;
}
.graph_outer_min_max .graph_inner {
    width: 110px;
    margin: auto;
    right: 0;
}
.graph_inner::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: 2px;
    background-color: #018438;
}

.graph_value {
    position: relative;
    bottom: -4px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #fff;
    border: solid 2px #018438;
}
.graph_value_label {
    position: absolute;
    left: 0;
    bottom: calc(100% + 8px);
    color: #fff;
    background-color: #000;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    transform: translateX(-50%);
    padding: 2px 5px;
    line-height: normal;
}
