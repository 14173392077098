.signalContainer {
    display: flex;
    flex-wrap: nowrap;
}
.signalName {
    margin-left: 8px;
    display: inline-block;
    /*background: #FFFFFF 0% 0% no-repeat padding-box;*/
    /*border: 1px solid #969696;*/
    border-radius: 5px;
    /*opacity: 1;*/
    /*padding: 7px 9px;*/
    font-size: 14px;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    height: 34px;
    display: flex;
    align-items: center;
}
.signalName:first-child {
    margin-left: 0px;
}
.yesNoSelectWrapper {
    display: inline-block;
    position: relative;
}
.yesNoSelectWrapper:before,
.yesNoSelectWrapper:after {
    content: "";
    position: absolute;
    top: 50%;;
    z-index: 11;
    display: block;
    background: #444;
    height: 2px;
    width: 8px;
    border-radius: 2px;
}
.yesNoSelectWrapper:before {
    right: 11px;
    transform: translateY(-50%) rotate(45deg);
}
.yesNoSelectWrapper:after {
    right: 7px;
    transform: translateY(-50%) rotate(-45deg);
}
.yesNoSelect {
    margin-left: 5px;
    height: 34px;
    padding: 0px 27px 0px 10px;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    z-index: 10;
}
.textBox {
    margin: 0px;
    margin-left: 8px;
    padding: 5px;
    font-size: 16px;
    color: #444444;
    border: 1px solid #969696;
    border-radius: 5px;
    opacity: 1;
    height: 40px;
    max-width: 60px;
    vertical-align: top;
}
