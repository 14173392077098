.simplify_trigger-wrapper {
    height: 670px;
}

.simplify_new_triggers_list_inner {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    height: 95%;
    /* max-height: 500px; */
    min-height: 420px;
    width: 250px;
}

.simplify_title_target_cat {
    background: #F6F7F8;
    font-weight: 700;
    padding: 10px 10px;
    font-size: 16px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 9;
    top: 0;
}

.simplify_new_triggers_list_inner ul {
    margin: 0;
}

.simplify_new_triggers_list_inner ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 9px 10px;
    cursor: pointer;
    background: #FFF;
    position: relative;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.simplify_new_triggers_list_inner ul li:hover {
    background: #f7f7f7;
}

.simplify_new_triggers_list_inner ul li:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
}

.simplify_new_trigger_label {
    width: 100%;
    line-height: 17px;
}

.simplify_new_trigger_label p {
    display: inline;
    font-size: 14px;
}

.simplify_btn_trigger_add {
    color: #cacaca;
    margin-left: 10px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.simplify_new_trigger_active {
    background: #006fffc2!important;
    font-weight: bold;
}

.simplify_new_trigger_active .simplify_btn_trigger_add {
    color: #2f2e2e;
    margin-left: 10px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.simplify_disabled_trigger {
    opacity: 0.5;
    background-color: #8d8d8d09 !important;
    cursor: not-allowed !important;
}