.table {
    width: 100%;
}

.table th,
.table td {
    padding: 10px 0;
}

.table th {
    font-weight: bold;
}

.table tbody tr {
    border-top: 1px solid #EDEDED;
}

span.statusLabel {
    display: inline-block;
    padding: 3px 5px;
}