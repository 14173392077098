.onsiteData_create_tag_container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.onsiteData_create_tag_container h3 {
    margin-left: 10px;
    font-weight: bold;
    font-size: 18px;
}

.onsiteData_create_tag_container p {
    font-size: 15px;
    color: #9E9E9E;
    margin: 5px auto;
}

.onsiteData_create_tag_container textarea {
    width: 90%;
    height: 150px;
    margin: 10px auto 0px auto;
    overflow: auto;
}