.tabs_side_btn {
	margin: -8px 0;
}

.badge_tenant {
	display: inline-block;
	vertical-align: middle;
	background-color: var(--grey-4);
	font-size: 11px;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--white);
	border-radius: 4px;
	line-height: 15px;
	padding: 3px 6px 2px;
	margin-left: 10px;
}

.grid{
	color: var(--grey-0);
}
.grid .grid_row{
	grid-template-columns: 6fr 1.4fr 3fr 135px;
}

.grid .feed_name {
	font-weight: 500;
	font-size: 15px;
}
.grid .feed_url {
	font-size: 13px;
	color: var(--grey-2);
	margin-top: 2px;
}
.grid .grid_main_link:hover {
	color: var(--turquoise);
	cursor: pointer;
}
.grid .grid_main_link:hover .feed_url{
	color: inherit;
}

.grid .picto {
	margin-right: 18px;
}