/* List and Item */
.kpi_list {
    margin-top: 10px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 16px;
}

.kpi_item {
    position: relative;
    padding: 14px 16px;
    border: 1px solid var(--main-border-color);
    background-color: var(--white);
    border-radius: 10px;
    text-align: center;
    line-height: 1;
    min-height: 84px;
    flex: 0 0 auto;
    width: calc(25% - 12px);
}

@media (max-width: 1450px) {
    body:not(.hide_nav) .kpi_item {
        width: calc(33.33% - 11px);
    }
}
@media (max-width: 1250px) {
    body.hide_nav .kpi_item {
        width: calc(33.33% - 11px);
    }
}

.kpi_item.empty {
    border-style: dashed;
    cursor: pointer;
}

.kpi_item.empty:hover {
    color: var(--turquoise);
    border-color: currentColor;
}

/* Loading */
.kpi_loading {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -15px;
}

/* Content */
.kpi_add {
    position: absolute;
    top: 50%;
    left: 8px;
    right: 8px;
    height: 18px;
    line-height: 18px;
    margin-top: -9px;
    color: #006fff;
    font-weight: 500;
    text-transform: uppercase;
}

.kpi_add .fas {
    font-size: 13px;
}


.kpi_delete {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: -13px;
    right: -13px;
    padding: 4px;
    transition: all 200ms;
}

.kpi_item:hover .kpi_delete {
    opacity: 1;
}

.kpi_delete:hover {
    color: #d76666;
}

.kpi_delete .fas {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #fff;
}

/* Value */
.kpi_label {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--grey-3);
}

.kpi_value {
    font-size: 22px;
    font-weight: 500;
    margin-top: 9px;
}

.kpi_rate {
    font-size: 14px;
    color: #5D637E;
    margin-top: 4px;
}

.kpi_no_data {
    font-size: 20px;
    margin-top: 16px;
    color: #C5C7D3;
}

.kpi_uplift {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
}

.kpi_uplift.uplift_positive {
    color: #5CB85C;
}

.kpi_uplift.uplift_negative {
    color: #acafc0;
}

/* Table version */
.kpi_title {
    font-size: 13px;
    font-weight: 500;
    color: var(--grey-3);
    text-align: left;
    margin-bottom: 10px;
}
.kpi_row {
    display: flex;
    align-items: baseline;
    margin: 5px 0;
}
.kpi_row .kpi_col:nth-child(1),
.kpi_row .kpi_col:nth-child(3) {
    flex: 0 0 auto;
}
.kpi_row .kpi_col:nth-child(2) {
    flex: 1 1 auto;
}
.kpi_row .kpi_col:nth-child(2)::before {
    content: "";
    display: block;
    border-top: solid 1px #EFF1F5;
    margin: 0 6px;
}

.kpi_main_value {
    font-size: 26px;
    font-weight: 500;
}
.kpi_secondary_value {
    font-size: 14px;
}
.kpi_big_uplift {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
}
.kpi_big_uplift.uplift_positive {
    color: var(--green);
}
.kpi_big_uplift.uplift_negative {
    color: var(--red);
}
