.ab_toolbar{
	border-bottom: solid 1px var(--main-border-color);
	padding: 0 var(--main-h-padding);
	user-select: none;
	display: flex;
}
.ab_toolbar_tabs{
	display: flex;
	position: relative;
}
.ab_toolbar_tabs > li > a{
	display: block;
	font-size: 14px;
	font-weight: 500;
	background: #F5F6F8;
	border: solid 1px var(--main-border-color);
	border-width: 1px 1px 0;
	padding: 11px;
	min-width: 130px;
	text-align: center;
	border-radius: 4px 4px 0 0;
	margin-right: 5px;
	cursor: pointer;
	transition: all 200ms;
}
.ab_toolbar_tabs > li > a:not(.active):hover{
	border-color: #CDCED6;
	background-color: #ECEDEF;
}
.ab_toolbar_tabs > li > a.active{
	background: #fff;
	color: inherit;
	padding-bottom: 12px;
	margin-bottom: -1px;
}
.ab_toolbar_delete_tab {
	z-index: 2;
	cursor: pointer;
	-webkit-transition: opacity 100ms;
	-o-transition: opacity 100ms;
	transition: opacity 100ms;
	color: var(--grey-3);
	font-size: 13px;
	cursor: pointer;
	transition: all 200ms;
	margin-right: -4px;
}
.ab_toolbar_delete_tab:focus,
.ab_toolbar_delete_tab:hover{
	color: var(--color-alert);
}