.container_searchBar_product_feed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    width: 100%;
}

.container_searchBar_product_feed p:hover {
    color: #006fff;
    cursor: pointer;
}

.container_searchBar_product_feed span p {
    margin-top: 20px;
    font-size: 20px;
    color: #9e9e9e;
}

.footer_product_feed_catalogue {
    display: flex;
    justify-content: space-between;
}
.product_feed_search{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
}
.product_feed_tools{
    display : flex;
    align-items: center;
    justify-content: space-evenly;
    color: #9e9e9e;
}
.product_feed_tools p{
   margin: 0px 5px;
}
