/* Modal */
.user_modal_title{
    font-weight: 500;
    font-size: 16px;
}
.user_modal_desc{
    font-size: 13px;
    margin-top: 2px;
}

/* Grid */
.user_grid{

}
.user_grid_row{
    display: grid;
    border-bottom: solid 1px #eee;
    font-size: 15px;
    align-items: stretch;
    grid-template-columns: 1.5fr 1.5fr 1fr 100px;
}
.user_grid_row.has_orga{
    grid-template-columns: 1.5fr 1.3fr .6fr .5fr 1fr 100px;
}
.user_grid_col{
    padding: 12px;
    display: flex;
    align-items: center;
}
.user_grid_col:first-child{
    padding-left: 4px;
}
.user_grid_col:last-child{
    padding-right: 4px;
    justify-content: flex-end;
}
.user_grid_row.has_orga .user_grid_col:nth-child(3),
.user_grid_row.has_orga .user_grid_col:nth-child(4){
    justify-content: center;
}

/* Header */
.user_grid_head_row{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    color: #5D637E;
}
.user_grid_head_row .user_grid_col{
    padding-top: 8px;
    padding-bottom: 8px;
}
.user_grid_sort{
    cursor: pointer;
}
.user_grid_sort:hover{
    color: #006fff;
}
.user_grid_sort .fas{
    font-size: 12px;
    margin-left: 3px;
}

/* Body */
.user_grid_body_row:hover{
    background: #F7F8FA;
    cursor: pointer;
}

/* Grid content */
.user_grid_name{
    font-weight: 500;
}
.user_grid_email{
    font-size: 13px;
    margin-top: 2px;
}

/* Roles */
.user_role_list{
    font-size: 13px;
}
.user_role_list > span{
    display: block;
}
.user_role_list > span::before{
    content: "\2022";
    display: inline-block;
    margin: 0 6px 0 0;
    color: #8a8ea5;
}
