.listbox{
	margin: 0;
	max-width: min(90vw, 700px);
}
.listbox li{
	margin: 0;
	padding: 0;
	position: relative;
	list-style-image: none;
}
.listbox_has_border > li + li{
	border-top: solid 1px #EEEFF4;
}
.listbox_direction_row {
	display: flex;
	flex-wrap: wrap;
}
.listbox_appearance_iconSelector {
	gap: 10px;
	padding-left: 1em;
	padding-right: 1em;
}

/* ITEM */
.listbox .item{
	display: block;
	border: none;
	font-size: 14px;
	line-height: 1.3em;
	margin: 0;
	color: inherit;
	overflow: hidden;
	word-break: normal;
	white-space: nowrap;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
    padding-left: 1em;
    padding-right: 2em;
}
.listbox_direction_row .item {
	padding-right: 1em;
}
.listbox .item_size_m {
    padding-top: .5em;
	padding-bottom: .5em;
}
.listbox .item_size_l {
	padding-top: .7em;
	padding-bottom: .7em;
}
.listbox .item_size_xl {
	padding-top: 1em;
	padding-bottom: 1em;
	padding-left: 1.3em;
}
.listbox_direction_row .item_size_xl {
	padding-right: 1.3em;
}
.listbox .item_enabled{
	cursor: pointer;
}
.listbox .item_enabled:hover{
	background-color: #F7F8FA;
	text-decoration: none;
}
.listbox .item_disabled{
	opacity: .5;
	cursor: default;
}
.listbox .item_selected{
	background-color: #F7F8FA;
}

.listbox .item_right{
	padding-right: 1em;
}

.listbox .item_icon{
	margin-right: 10px;
	color: var(--grey-3);
}

.listbox .listbox_sep{
	margin: 8px 0;
	border-top: solid 1px #E2E4ED;
}

.listbox .item_flex {
	display: flex;
	align-items: center;
}
.listbox .item_left {
	flex: 0 0 auto;
}
.listbox .item_center {
	flex: 1 1 auto;
}

.listbox .item_capitalize .item_center {
	text-transform: capitalize;
}

.listbox .item_title{
	font-size: 15px;
	font-weight: 500;
}
.listbox .item_desc{
	display: block;
	font-size: 13px;
	color: #5d637e;
	margin-top: 2px;
}
.listbox .item_check{
	margin-right: -12px;
	margin-left: 16px;
	color: #757b96;
}
.listbox .item_img {
	display: block;
	max-width: 400px;
	margin-top: 4px;
}

.listbox_appearance_iconSelector .item {
	border: solid 1px var(--main-border-color);
	border-radius: 5px;

}

/* Group label */
.groupLabel {
	font-weight: 500;
	font-size: 13px;
	text-transform: uppercase;
	color: var(--grey-1);
	padding: .5em 1em;
}
.listbox + .groupLabel {
	margin-top: .5em;
}

/* Smart HR */
.listbox li+.hr+li{
	margin-top: .416em;
	padding-top: .416em;
	border-top: solid 1px #eee;
}
.listbox li.hr:first-child+li,
.listbox li.hr:first-child+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr{
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
}
