.operations-container {
	display: flex;
	flex-wrap: wrap;
	margin-left: -20px;
	margin-top: 10px;
}

.operationItems {
	font-size: 14px;
	width: calc(33.3% - 20px);
	margin-bottom: 20px;
	margin-left: 20px;
	border: 1px solid var(--main-border-color);
	background-color: var(--white);
	border-radius: 6px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.dropdown_inner .operationItems {
	border: none;
	margin: 0;
	width: 400px;
}

.operation-inner {
	flex: 1 1 auto;
	display: block;
	padding: 15px;
	cursor: pointer;
	transition: background 200ms;
	border-radius: 5px 5px 0 0;
}

.operation-inner.disabled {
	cursor: text;
}

.operation-inner:hover, .operation-inner:focus {
	background: #FAFAFA;
}

.operation-name {
	font-size: 15px;
	font-weight: 600;
	cursor: pointer;
	max-width: calc(100% - 50px);
}

.operation-devices {
	position: absolute;
	top: 15px;
	right: 15px;
}

.operation-description {
	color: #b3b3b3;
}

.operation-date {
	color: #5d637e;
}

.operation-img {
	margin: 10px 0;
}

.operation-img img {
	max-width: 100%;
	max-height: 100px;
}

.operation-img-empty {
	width: 140px;
	height: 80px;
	line-height: 80px;
	border-radius: 6px;
	background: #F7F7F7;
	color: #ddd;
	font-size: 34px;
	text-align: center;
}

.operation-img-empty .fas {
	width: 70px;
}

.operation-stats {}

.operation-stats-title {
	font-weight: 600;
}

.operation-stats-grid {
	display: inline-grid;
	grid-template-columns: 1fr 1fr;
}

.operation-stats .operation-stat-label {}

.operation-stats .operation-stat {
	font-weight: 600;
	text-align: right;
}

.operation-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	border-top: 1px solid var(--main-border-color);
}

.operation-btns {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
}

.operation-btns>div a {
	transition: all 0.2s ease-in-out;
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.operation-btns>div {
	transition: all 0.2s ease-in-out;
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.operation-btns>div:hover {
	color: #006fff;
}

.operation-btns>a:hover {
	color: #006fff;
}

.operation-status {
	font-size: 11px;
	padding: 3px 0px;
	border-radius: 4px;
	color: #fff;
	width: 60px;
	text-align: center;
	font-weight: 600;
	margin-left: 10px;
}

.operation-status:hover {
	cursor: pointer;
}

.operation-status.disabled:hover {
	cursor: text;
}

.operation-status.ongoing {
	background: #5cb85c;
}

.operation-status.stopped {
	background: #d76666;
}

.operation-status.planned {
	background: #FFC300;
}

.operation-status.testing {
	background: #F2F2F2;
	color: #777;
}

.stopped_by_end_date {
	font-size: 11px;
	padding: 3px 0px;
	border-radius: 4px;
	color: #fff;
	width: 60px;
	text-align: center;
	font-weight: 600;
	margin-left: 10px;
	background: #9e9e9e;
}

.actionButtonOperation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 150px;
}

.select-label .css-2b097c-container .css-yk16xz-control {
	width: 150px!important;
	outline: none!important;
}

.select-label .css-2b097c-container .css-yk16xz-control:focus {
	outline: none!important;
	border-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.select-label .css-2b097c-container .css-1pahdxg-control {
	width: 150px!important;
	outline: none!important;
	box-shadow: 0 0 0 1px #006fff;
	border-color: #006fff;
	outline: none!important;
}

.select-label .css-2b097c-container .css-1pahdxg-control:focus {
	outline: none!important;
}

.select-label .css-2b097c-container .css-1pahdxg-control:hover {
	border-color: #006fff;
	outline: none!important;
}

.select-label .css-2b097c-container .css-26l3qy-menu {
	display: inline;
	opacity: 8;
}

.operation-labels {
	margin-bottom: 5px;
	margin-top: 2px;
}
.operation-label-list {
	margin: 5px 0px;
	padding: 2px 8px;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #aaa;
	border: solid 1px currentColor;
	background: transparent;
	border-radius: 20px;
	user-select: none;
}

.simplify_test_link {
	margin-left: 10px;
	font-size: 11px;
	padding: 3px 0px;
	border-radius: 4px;
	color: #aaa;
	width: 85px;
	text-align: center;
	font-weight: 600;
}

.simplify_test_link:hover {
	color: #006fff;
}