.image {
	position: relative;
}
.image:hover {
	-webkit-box-shadow: rgba(0, 0, 0, .6) 0 0 1px;
	box-shadow: rgba(0, 0, 0, .6) 0 0 1px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.image:hover .deleteImage {
	display: inline;
}
.deleteImage {
	font-size: 15px;
	color: #eb6161;
	position: absolute;
    top: -8px;
    right: -10px;
	display: none;
}
.deleteImage::before {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #fff;
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, .4);
	z-index: 10;
}
.modal img {
	max-width: 90%;
    max-height: 90%;
}