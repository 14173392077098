.section + .section {
    margin-top: 20px;
}

/* Field type */
.type_panel_overlay {
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, .25);
	animation: type_panel_overlay_show 300ms ease-out both;
	opacity: 0;
}
.type_panel_overlay.anim_hide {
	animation: type_panel_overlay_hide 300ms ease-out both;
}
.type_panel {
	position: absolute;
	z-index: 10;
	bottom: 16px;
	left: 16px;
	right: 16px;
	background: #fff;
	box-shadow: rgba(0,0,0,.2) 0 0 110px;
	border-radius: 16px;
	opacity: 0;
    transform: translateY(50%);
	animation: type_panel_show 300ms ease-out both;
	max-height: calc(100% - 32px);
    overflow: auto;
}
.type_panel.anim_hide {
	animation: type_panel_hide 300ms ease-out both;
}
@keyframes type_panel_overlay_show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes type_panel_overlay_hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes type_panel_show {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes type_panel_hide {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(50%);
    }
}

.type_panel_header {
	padding: 22px 10px 0 30px;
}
.type_panel_body {
	padding: 30px;
}


.type_list_outer + .type_list_outer {
	margin-top: 20px;
}
.type_list_label {
	margin-bottom: 6px;
	font-weight: 500;
}
.type_list_inner {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 12px;
}
.type_item {
	position: relative;
	background: var(--white);
	border: 1px solid var(--main-border-color);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	width: 100px;
	position: relative;
	text-align: center;
	line-height: 14px;
	font-weight: normal;
	padding: 16px 4px;
}
.type_item_enabled,
.type_item_disabled {
	composes: type_item;
}
.type_item_disabled {
	cursor: not-allowed;
	opacity: .5;
}
.type_item.s {
	padding: 20px 12px 12px;
}
.type_item_color_1 {
	color: #756ce0;
}
.type_item_color_2 {
	color: #45AA60;
}
.type_item_color_3 {
	color: #E88C30;
}
.type_item_color_4 {
	color: #30B4CC;
}
.type_item_enabled:before {
	content: "";
	position: absolute;
	top: -1px;
	left: -1px;
	width: -webkit-calc(100% + 2px);
	width: -moz-calc(100% + 2px);
	width: calc(100% + 2px);
	height: -webkit-calc(100% + 2px);
	height: -moz-calc(100% + 2px);
	height: calc(100% + 2px);
	border: 3px solid #006fff;
	opacity: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.type_item_enabled:hover:before,
.type_item_enabled:focus:before,
.type_item_enabled.checked:before {
	opacity: 1;
}
.type_item > i {
	font-size: 20px;
	margin-bottom: 14px;
	margin-top: 4px;
}
.type_item_name {
	color: var(--grey-0);
	text-transform: capitalize;
	font-size: 14px;
	text-overflow: ellipsis;
	display: block;
	white-space: nowrap !important;
	overflow: hidden;
	word-wrap: normal;
}
.type_item_soon {
	background-color: currentColor;
	border-radius: 3px;
	position: absolute;
	top: 2px;
	right: 2px;
    padding: 2px 4px;
}
.type_item_soon_text {
	color: var(--white);
	font-size: 11px;
    font-weight: 500;
}

/* Type select */
.type_select {
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	padding: 8px 12px;
	border-radius: 6px;
	font-size: 15px;
	font-weight: 500;
	line-height: 20px;
	background-color: #F0EFFC;
	color: #756CE0;
	cursor: default;
}
.type_select_color_1 {
	background-color: #F0EFFC;
	color: #756CE0;
}
.type_select_color_2 {
	background-color: #EFFCF2;
	color: #45AA60;
}
.type_select_color_3 {
	background-color: #FDF4EA;
	color: #E88C30;
}
.type_select_color_4 {
	background-color: #EAFCFF;
	color: #30B4CC;
}
.type_select i {
	margin-left: -2px;
	margin-right: 8px;
	font-size: 18px;
}
.type_select_label {
	text-transform: capitalize;
}
.type_select_clickable {
	cursor: pointer;
	transition: box-shadow 200ms;
}
.type_select_clickable:hover,
.type_select_clickable:focus {
	box-shadow: inset rgba(0,0,0,.05) 0 0 0 100px;
}
.type_select_clickable::after{
	content: "";
	width: 0;
	height: 0;
	display: inline-block;
	vertical-align: middle;
	margin: 0 0 0 6px;
	white-space: nowrap;
	border: 4px solid;
	border-bottom-width: 0; 
	border-right-color: transparent;
	border-left-color: transparent;
}

/* Field row */
.field_row {
	display: flex;
	align-items: flex-start;
	gap: 16px;
}
.field_col_s {
	flex: 0 1 20%;
}
.field_col_m {
	flex: 0 1 40%;
}
.field_col_l {
	flex: 0 1 40%;
}


.panelHeader {
    border-bottom: 1px solid var(--main-border-color);
    display: flex;
    align-items: center;
    position: relative;
	margin-bottom: 16px;
	padding-bottom: 10px;
}
.panelHeaderLeft {
	flex: 1 1 50%;
    display: flex;
    align-items: center;
}
.panelHeaderCenter {
	margin: 0 20px;
    flex: 0 0 auto;
}
.panelHeaderRight {
	flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
}
.panelHeaderNumber {
	font-size: 13px;
	font-weight: 700;
	color: #fff;
	background-color: var(--grey-5);
	min-width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 20px;
	margin-right: 10px;
	text-align: center;
}
.panelHeaderNumber__1,
.panelHeaderNumber__2,
.panelHeaderNumber__3,
.panelHeaderNumber__4 {
	composes: panelHeaderNumber;
}
.panelHeaderNumber__1 { background-color: #756ce0;}
.panelHeaderNumber__2 { background-color: #45AA60;}
.panelHeaderNumber__3 { background-color: #E88C30;}
.panelHeaderNumber__4 { background-color: #30B4CC;}

.panelHeaderDragHandle {
	opacity: 0;
	transition: opacity 200ms;
	display: inline-block;
	vertical-align: middle;
	width: 27px;
	height: 13px;
	background-image: url("/Assets/drag_handle.svg");
	background-repeat: no-repeat;
}

.panelForm {
	font-size: 14px;
}
.panelFormDragging {}
.panelForm:hover .panelHeaderDragHandle,
.panelFormDragging .panelHeaderDragHandle{
	opacity: 1;
}
