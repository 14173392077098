.simpify_report--campaign--name{
    font-weight: 500;
    color: #006fff;
    cursor: pointer;
    margin-bottom: 6px;
    display: inline-block;
}

.statistics-informations-operation-dates {
    margin-bottom: 3px;
    color: #a5a5a5;
    font-size: 13px;
}

.statistics-information-name-container {
    display: flex;
    align-items: center;
}

.stats-name-operation {
    font-size: 24px;
    font-weight: 600;
}

.stats-operation-status {
    font-size: 11px;
    padding: 3px 10px;
    border-radius: 4px;
    color: #fff;
    width: 60px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 3px;
}

.stats-description {
    margin-bottom: 3px;
    color: #9E9E9E;
}

.stats-label {
    margin: 5px 0px;
}

.stats-operation-status.ongoing {
    background: #5cb85c;
}

.stats-operation-status.stopped {
    background: #d76666;
}

.stats-operation-status.planned {
    background: #FFC300;
}

.return-to-dashboard {
    position: absolute;
    top: 6px;
    left: 13px;
    font-size: 24px;
    color: #9e9e9e;
    cursor: pointer;
}

.header-statistics-container {
    display: flex;
    justify-content: space-between;
    width: 99%;
}

.header-statistics-container input {
    font-size: 15px;
    width: 130px;
    text-align: center;
}

.first-picker-stats input {
    /* margin-right: -12px; */
    /* border-width: 1px 0px 1px 1px; */
}

.second-picker-stats input {
    border-radius: 5px 0 0 5px;
    border: 1px solid #C0C2D3;
    /* border-width: 1px 1px 1px 0px; */
}

.btn-refresh-data {
    background: #f6f7f8;
    color: #9e9e9e;
    margin-left: -11px;
    border: 1px solid #C0C2D3;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    min-width: 44px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.btn-refresh-data:hover {
    background: #006fff;
    color: #FFF;
}

.statistics-results {
    display: flex;
    align-items: stretch;
    margin-top: 10px;
    margin-left: -30px;
    margin-bottom: 20px;
}

.statistics-kpi-container {
    flex: 1 1 100%;
    margin-left: 30px;
    color: #FFF;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    line-height: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statistics-kpi-container .card_title {
    font-size: 15px;
}

.statistics-kpi-container .data {
    font-size: 35px;
    font-weight: 500;
    margin: 10px 0 6px;
}

.statistics-kpi-container .percent {
    font-size: 15px;
}

.labelKpi {
    font-weight: 400;
}

.tooltypeKpi {
    cursor: pointer;
    text-align: right;
    position: relative;
    left: 40%;
    top: -10%;
}

.simplify-stats-container-chart {
    width: 80%;
    margin: 10px auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header-statistics-container .DateRangePickerInput__withBorder {
    border: none;
}

.header-statistics-container .DateRangePickerInput__withBorder input {
    height: 45px;
    cursor: pointer;
}

.header-statistics-container .DateRangePicker {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 300px;
}

.header-statistics-container .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    margin-left: 5px;
}

.header-statistics-container .DateInput_fang {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 2px;
    z-index: 2;
}

.header-statistics-container .DateInput_fangStroke {
    stroke: #006fff;
    fill: #006fff;
}

.btn-refresh-stats {
    background: #f6f7f8;
    position: relative;
    /* top: 4px; */
    color: #9e9e9e;
    margin-left: -5px;
    border: 1px solid #9e9e9ea3;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    min-width: 44px;
    height: 45px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding-bottom: 6px;
}

.btn-refresh-stats:hover {
    background: #006fff;
    color: #FFF;
}

.btn-refresh-stats i {
    position: relative;
    top: 4px;
}