.sub_nav {
    position: relative;
}

.sub_menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.sub_nav:hover .sub_menu {
    display: block;
}

.sub_menu li {
    padding: 8px 16px;
}

.sub_menu li a {
    text-decoration: none;
    color: #333;
}

.sub_menu li a:hover {
    background-color: #f0f0f0;
}