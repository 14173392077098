.container {
    text-align: center;
    padding: 15px;
    margin: 10px auto;
  }
  
  .csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
  }
  
  .container p {
    padding-top: 10px;
    color: #666;
  }
  