.filter-search-product-feed-container {
    display: flex;
    max-height: 80%;
    align-items: center;
    width: 105%;
    overflow-y: auto;
    overflow-x: hidden;
}

.filter-search-product-operator-add-condition {
    color: #006fff;
    background: none;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    line-height: 20px;
    min-height: 20px;
    padding: 8px 10px;
    border: 1px solid #006fff;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 100ms ease-in-out;
    -moz-transition: all 100ms ease-in-out;
    -ms-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
    font-weight: 500;
    width: 140px;
    margin: 10px 30px 0px 30px;
}

.filter-search-product-operator-add-condition:hover {
    background-color: #006fff11;
}

.filter-search-product-feed-container ul {
    display: flex;
    flex-direction: column;
    width: 155px;
    margin: 2px auto 9px 35px;
    min-height: 20px;
    padding: 8px 10px;
    border: 1px solid #80808036;
    font-size: 15px;
    border-radius: 6px;
    box-shadow: 0px 2px 6px #00000066;
}

.filter-search-product-feed-container li {
    color: gray;
    text-align: left;
    margin: 3px 10px;
}

.filter-search-product-feed-container li:hover {
    color: #006fff;
    cursor: pointer;
}

.field_already_use {
    color: #8080803b !important;
}

.field_already_use:hover {
    color: #8080803b !important;
    cursor: initial !important;
}

.container-operator-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin: auto;
}

.selector-operator-fields {
    display: flex;
    align-items: center;
    width: 95%;
}

.selector-operator-fields div:first-of-type .input_select_container .select-component {
    width: 250px;
}

.selector-operator-fields div:nth-child(2) .input_select_container .select-component {
    width: 202px;
}

.selector-operator-fields div:nth-child(3) {
    margin-left: 15px;
}

.container-operator-value {
    width: 80%;
}

.container-operator-value .input-custom-goupe {
    margin-left: 10px;
}

.selector-operator-fields select {
    border-radius: 6px;
    background-color: #F7F8FA;
    border-color: #D5DBE6;
}

.container-operator-value input {
    border-radius: 6px;
    background-color: #F7F8FA !important;
    border-color: #D5DBE6 !important;
    width: 462px;
    height: 40px;
    margin-top: 10px;
}

.trash-field-operator {
    cursor: pointer;
    color: #64718D;
    font-size: 19px;
}

.trash-field-operator:hover {
    color: red;
}

.container-operator-selector {
    border-left: 3px solid #D5DBE6;
    border-radius: 15px;
    padding: 5px;
    max-height: 100%;
    overflow-x: hidden;
    width: 89%;
    overflow-y: auto;
}

.operator-selector-value {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    left: 24px;
    height: 40px;
    width: 50px;
    border: 2px solid #D5DBE6;
    border-radius: 6px;
    background-color: white;
}

.operator-selector-value p {
    margin: auto;
    font-size: 18px;
    color: #64718D;
    text-transform: uppercase;
}

.operator-main {
    cursor: pointer;
}

.secondary-operators {
    /* width: 85%;
    margin: auto; */
}

.container-add-field {
    margin-left: 40px;
    margin-bottom: 15px;
}

.footer-btn-search-filter {
    position: absolute;
    bottom: 8px;
    display: flex;
    justify-content: flex-end;
    width: 95%;
}

.container-search-tag-feed {
    display: flex;
}

.container-search-tag-feed .input_select_container .select-component {
    width: 195px;
}

.container-search-tag-feed select {
    border-radius: 6px;
    background-color: #F7F8FA;
    border-color: #D5DBE6;
}

.container-search-tag-feed input {
    border-radius: 6px;
    background-color: #F7F8FA !important;
    border-color: #D5DBE6 !important;
    width: 250px;
    height: 43px;
}

.container-search-tag-feed .input-custom-goupe {
    height: 55px;
    margin-left: 11px !important;
}

.container-search-tag-feed :nth-child(2) {
    height: 45px !important;
}

.container-search-tag-feed :nth-child(2) .input_select_container select {
    margin-top: 10px;
    width: 200px;
    height: 45px;
}