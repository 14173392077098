.group{
    margin-bottom: 22px;
    color: var(--grey-0);
}
.group_simple {
    composes: group;
    border: solid 1px var(--main-border-color);
    border-radius: 5px;
}
.group_multiple {
    composes: group;
    border: solid 3px var(--main-border-color);
    background-color: var(--main-bg-color);
    border-radius: 8px;
}

.group_header {
    display: flex;
    align-items: center;
    padding: 8px 14px;
    min-height: 42px;
}
.group_header_left {
    flex: 1 1 50%;
}
.group_header_center {
    flex: 0 0 auto;
    margin: 0 10px;
}
.group_header_right {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
}
.group_header_title {
    font-weight: 500;
    font-size: 14px;
}

.group_header_handle {
    display: inline-block;
    vertical-align: middle;
    color: var(--grey-4);
    padding: 8px;
    border-radius: 3px;
    transition: background-color 200ms;
}
.group_header_handle:focus,
.group_header_handle:hover {
    background-color: var(--grey-7);
}
.group_header_handle_svg {
    display: block;
}

.group_body {
    padding: 0 14px;
    background-color: var(--white);
    border-radius: 5px;
    font-size: 14px;
    overflow: hidden;
}
.group_body::after,
.group_body::before {
    content: "";
    display: block;
    margin-top: 14px;
}

.group_body_head {
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: solid 1px var(--main-border-color);
}
.group_body_left {
    flex: 1 1 auto;
}
.group_body_right {
    width: 140px;
    flex: 0 0 auto;
}
.group_body_total {
    margin-top: 14px;
    padding-top: 14px;
    border-top: solid 1px var(--main-border-color);
}
.group_body_add {
    margin-top: 14px;
    padding-top: 14px;
    border-top: solid 1px var(--main-border-color);
}

.rule {
    margin: 14px 0;
}
.element_composition {
    display: flex;
    flex: 50%;
    border: 1px solid #0068EF;
    height: 120px;
	justify-content: center;
}
.element_composition :hover {
    cursor: pointer;
    color: var(--blue);
}