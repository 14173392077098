.item {
    display: inline-block;
    vertical-align: baseline;
    font-size: 13px;
    font-weight: 500;
    background-color: var(--grey-4);
    color: var(--white);
    border-radius: 5px;
    line-height: 1.23;
    padding: 4px 6px;
    cursor: pointer;
    transition: background 200ms;
    margin-right: 8px;
}
.item:hover,
.item:focus {
    background-color: var(--grey-3);
}
