.ob_page_center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 20px 120px;
}
.ob_page_center_inner{
  text-align: center;
}

.ob_wrapper{
  text-align: center;
  font-weight: normal;
}

.ob_slide_form {
  background-color: #FFFFFF;
  border: 1px solid #E2E4EA;
  border-radius: 6px;
  width: 500px;
  padding: 44px 40px 40px;
  margin: auto;
  position: relative;
  height: auto;
  box-shadow: 0 3px 6px rgba(0,0,0,.04);
  overflow: hidden;
  font-weight: normal;
}
.ob_slide_form.ob_large{
  width: 800px;
}

.ob_logo {
  margin: 0 auto 34px;
  width: 120px;
}
.ob_logo > svg{
  display: block;
  width: 100%;
  height: auto;
}

.ob_title_1 {
  font-size: 35px;
  color: var(--black);
  font-weight: bold;
  border-bottom: none;
  margin-bottom: 12px;
}
.ob_title_2 {
  font-size: 25px;  
  color: var(--black);
  font-weight: bold;
  border-bottom: none;
  margin-bottom: 12px;
}
.ob_title_3 {
  font-size: 20px;
  color: var(--grey-0);
  margin-bottom: 12px;
}

.ob_btn_wrapper{
  margin-top: 40px;
}

.ob_error_message{
  display: inline-block;
  padding: 10px 16px;
  color: var(--red);
  border-radius: 5px;
  border: solid 1px currentColor;
  font-weight: normal;
  margin-top: 20px;
}

.ob_error_form {
  color: #FF0000;
  font-size: 15px;
}
.ob_buttons{
  margin-top: 40px;
}
.ob_text{
  color: var(--grey-0);
}

.ob_calendlySlide{
  margin: 0 -40px 0 -40px;
  border-top: solid 1px var(--main-border-color);
}
