.collection_container {
    height: auto !important;
}
.collection_header{
    border-bottom: solid 1px var(--main-border-color);
    padding: 16px 22px;
}
.collection_header_back{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--turquoise);
    font-size: 18px;
    margin-left: -10px;
    margin-right: 8px;
}
.collection_header_title{
    font-size: 18px;
    font-weight: 500;
}
.collection_body{
    overflow: hidden;
}
.collection_body_grey {
    background-color: var(--main-bg-color);
}

/* Sidebar content */
.collection_sidebar {
    width: 420px;
    align-items: stretch;
    border-right: 1px solid var(--main-border-color);
    background-color: #FFFFFF;
    padding: 0 22px;
    color: var(--grey-0);
}
.collection_sidebar_l {
    width: 560px;
}
.collection_sidebar::before,
.collection_sidebar::after {
    content: "";
    display: block;
    margin-bottom: 26px;
}
.collection_sidebar::before,
.collection_sidebar::after {
    content: "";
    display: block;
    margin-bottom: 26px;
}
.collection_sidebar .numberCircle {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    padding: 7px;
    background: var(--grey-0);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.collection_sidebar_block {
    margin-bottom: 34px;
}

.collection_sidebar_block_title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.collection_sidebar_row {
    display: flex;
}
.collection_sidebar_col {
    flex: 1 1 50%;
    width: 50%;
}
.collection_sidebar_col_s {
    flex-basis: 33%;
    width: 33%;
}
.collection_sidebar_col:nth-child(n+2) {
    margin-left: 10px;
}

/* Add desc */
.collection_sidebar_add_desc {
    margin-top: -4px;
}

/* Context */
.collection_sidebar_context {
    border: solid 1px var(--main-border-color);
    border-radius: 5px;
    padding: 3px 14px;
}
.collection_sidebar_context_row {
    display: flex;
    align-items: center;
    padding: 2px 0;
}
.collection_sidebar_context_row + .collection_sidebar_context_row {
    border-top: solid 1px var(--main-border-color);
}
.collection_sidebar_context_label {
    flex: 1 1 auto;
    font-size: 14px;
    font-weight: 500;
}
.collection_sidebar_context_value {
    flex: 0 0 auto;
    margin-left: 20px;
    font-size: 14px;
}

/* Item */
.collection_sidebar_item {
    position: relative;
    border: solid 1px var(--main-border-color);
    border-radius: 5px;
    padding: 15px 14px;
    margin-bottom: 14px;
}
.collection_sidebar_item.s {
    margin-bottom: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.collection_sidebar_item.clickable {
    cursor: pointer;
    transition: all 200ms;
}
.collection_sidebar_item.clickable:hover,
.collection_sidebar_item.clickable:focus {
    background: var(--main-bg-color);
}
.collection_sidebar_item_range {
    margin-top: 10px;
}
.collection_sidebar_item_del {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
    margin-right: -4px !important;
}
.collection_sidebar_item_nb {
    display: inline-block;
    margin-right: 9px;
    margin-top: -4px;
    margin-bottom: -4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    min-width: 24px;
    text-align: center;
    padding: 2px 7px;
    border-radius: 24px;
    color: var(--turquoise);
    background-color: var(--turquoise-light);
    border: solid 1px currentColor;
}
.collection_sidebar_item_operator {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    margin-left: 10px;
    padding: 1px 4px;
    border-radius: 3px;
    background-color: var(--grey-4);
}

/* Filter */
.collection_sidebar_filter_line {
    font-weight: 500;
    line-height: 18px;
}
.collection_sidebar_filter_line + .collection_sidebar_filter_line {
    margin-top: 10px;
}
.collection_sidebar_filter_name {

}
.collection_sidebar_filter_op {
    font-weight: 400;
    color: var(--grey-3);
}
.collection_sidebar_filter_val {
}

/* Preview */
.collection_preview {
    position: relative;
    background-color: var(--main-bg-color);
    height: auto !important;
    display: flex;
    flex-direction: column;
    color: var(--grey-0);
}
.collection_preview_header {
    display: flex;
    flex: 0 0 auto;
    padding: 26px;
    padding-bottom: 16px;
}
.collection_preview_body {
    position: relative;
    flex: 1 1 auto;
    padding: 26px;
    padding-top: 0;
}

/* List */
.collection_card_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
}
.collection_card {
	position: relative;
    cursor: default;
}

.collection_card_image_wrapper {
    background-color: #FFFFFF;
    background-image: url(/Assets/product_collection_no_photo.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 220px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,.02);
    border: solid 1px var(--main-border-color);
    overflow: hidden;
    margin-bottom: 14px;
}
.collection_card_image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.collection_card_info {
    font-size: 14px;
    line-height: 1.3;
    color: var(--grey-0);
}
.collection_card_name {
    font-weight: 500;
}
.collection_card_price {
    margin-top: 3px;
}
.collection_card_old_price {
    color: var(--grey-3);
    font-size: 12px;
    margin-left: 6px;
}
.collection_card_id {
    margin-top: 3px;
    font-size: 12px;
    color: var(--grey-3);
}
.collection_card_hover {
    opacity: 0;
    transition: opacity 200ms;
}
.collection_card:hover .collection_card_hover,
.collection_card:focus .collection_card_hover {
    opacity: 1;
}

.collection_card_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 8px;
}

.collection_card_flag,
.collection_card_action {
    display: inline-flex;
    font-size: 14px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    align-items: center;
    justify-content: center;
    transition: all 200ms;
}
.collection_card_flag {
    background: var(--turquoise-light);
    color: var(--turquoise);
}
.collection_card_action {
    background: rgba(255,255,255,.5);
    color: var(--grey-1);
}
.collection_card_action:hover,
.collection_card_action:focus {
    background-color: #EEEFF1;
}

/* Pagin */
.collection_pagin {
    margin-top: 30px;
    margin-bottom: 20px;
}


/* Modal */
.collection_modal_sidebar {
    width: 280px;
    background-color: var(--main-bg-color);
    border-right: solid 1px var(--main-border-color);
    border-bottom-left-radius: 5px;
}

.collection_modal_filter_header{
    border-bottom: solid 1px var(--main-border-color);
    padding-bottom: 10px;
}
.collection_modal_filter_title{
    font-size: 17px;
    font-weight: 600;
}
.collection_modal_filter_body{
    margin-top: 20px;
}
