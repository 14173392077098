.container {
  composes: section from "../../WebAnalyticsContainer.module.css";

  padding: 14px 15px;
}
.containerMedium{
  composes: container;
  min-height: 398px;
}
.containerLarge{
  composes: container;
  min-height: 498px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.label {
  text-align: left;
  height: 43px;
  margin: 0 42px 0 0;
  font-size: 16px;
  color: #4C5269;
}

.labelContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.indicator {
  height: 10px;
  background-color: var(--turquoise);
}

.activeLabel {
  color: var(--turquoise);
}
