.title {
	font-size: 14px;
	font-weight: 500;
	color: var(--grey-3);
	margin-bottom: 10px;
}

.pie {
	width: 100px;
	height: 100px;
	position: relative;
	display: block;
	font-weight: bold;
	font-family: sans-serif;
}
.piece{
	position: absolute;
	inset: 0;
	border-radius: 50%;
	background: conic-gradient(#0000 0 calc(var(--start)*1%), var(--c) calc(var(--start)*1%) calc(var(--end)*1%),#0000 calc(var(--end)*1%) 100%);
	-webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--thickness)),#000 calc(100% - var(--thickness)));
			mask:radial-gradient(farthest-side,#0000 calc(99% - var(--thickness)),#000 calc(100% - var(--thickness)));
}

.table {
	margin-left: 30px;
}
.table_row {
	display: flex;
	align-items: center;
	font-size: 14px;
}
.table_row + .table_row {
	margin-top: 6px;
}
.table_col_color {
	flex: 0 0 auto;
	margin-right: 7px;
	width: 12px;
	height: 12px;
}
.table_col_label {
	flex: 0 0 auto;
	text-transform: capitalize;
}
.table_col_sep {
	flex: 1 1 auto;
	min-width: 10px;
	border-top: dashed 1px var(--main-border-color);
	margin-left: 7px;
	margin-right: 7px;
}
.table_col_value {
	flex: 0 0 auto;
}
