/* Tabs */
.tabs_border {
    border-bottom: solid 1px var(--main-border-color);
    margin-bottom: 26px;
}
.tabs {
    display: flex;
    gap: 50px;
}
.tab_common {
    padding-bottom: 18px;
    cursor: pointer;
    position: relative;
    min-width: 80px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.tab_common::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 3px;
    border-radius: 3px 3px 0 0;
    transition: background 200ms;
}
.tabs .item_value {
    font-size: 26px;
}
@media (max-width: 1439px) {
    .tabs {
        gap: 40px;
    }
    .tabs .item_title {
        font-size: 13px;
    }
    .tab_common {
        padding-bottom: 14px;
    }
}
@media (max-width: 1367px) {
    .tabs {
        gap: 35px;
    }
    .tabs .item_value {
        font-size: 24px;
    }
}

.tab {
    composes: tab_common;
}
.tab:hover::after,
.tab:focus::after {
    background-color: var(--main-border-color);
}

.tab_active {
    composes: tab_common;
}
.tab_active::after {
    background-color: var(--turquoise);
}
