.outer {
    display: inline-flex;
    gap: 3px;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 14px;
}
.outer_color_green {
    color: #018438;
    background-color: #DCF8E3;
}
.outer_color_success_reverse {
    color: var(--white);
    background-color: #65B268;
}
.outer_color_purple_reverse {
    color: var(--white);
    background-color: #4E1A7C;
}
.outer_color_electric_green {
    color: #018438;
    background-color: #6CFFAF;
}
.outer_color_green.outer_has_border {
    border: solid 1px #ACE2C2;
}
.icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
}
.icon :global(svg){
    display: block;
}
.text {
    flex: 0 0 auto;
    display: inline-block;
    font-weight: 500;
}