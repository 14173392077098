.campaign_editor{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.campaign_editor_body{
    height: auto !important;
    position: relative;
    overflow: hidden;
}
.campaign_editor_body_grey{
	background-color: var(--main-bg-color);
}

.content_container {
    display: flex;
}