.picto {
	--width: 32px;
	--svg-width: 20px;
	border-radius: 100%;
	display: inline-block;
	vertical-align: middle;
	width: var(--width);
	height: var(--width);
	line-height: var(--width);
	background: #ccc;
	color: #777;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	user-select: none;
}

/* Color */
.color_dark_blue {		background-color: #B4C1F7;	color: #142FA5;}
.color_blue {			background-color: #B8DFFB;	color: #025EA0;}
.color_green {			background-color: #C0EED7;	color: #119050;}
.color_apple_green {	background-color: #CCEEC0;	color: #3B891F;}
.color_yellow {			background-color: #FFF3AF;	color: #A38A00;}
.color_orange {			background-color: #FDC893;	color: #AF5700;}
.color_red {			background-color: #FFC5B7;	color: #D53B16;}
.color_pink {			background-color: #EDB5E7;	color: #74246A;}
.color_purple {			background-color: #E4C9F5;	color: #560E81;}
.color_pastel_blue {	background-color: #EDF4FD;	color: #4D98FA;}
.color_pastel_green {	background-color: #d1f3db;	color: #3bb15e;}

.color_success {		background-color: var(--color-success-light); color: var(--color-success);}
.color_alert {			background-color: var(--color-alert-light); color: var(--color-alert);}


/* Size */
.size_xxs {	--width: 16px; --svg-width: 10px; font-size: 9px;}
.size_xs {	--width: 24px; --svg-width: 14px; font-size: 13px;}
.size_s{	--width: 28px; --svg-width: 17px; font-size: 14px;}
.size_m{	--width: 32px; --svg-width: 20px; font-size: 16px;}
.size_l{	--width: 38px; --svg-width: 22px; font-size: 17px;}
.size_xl{	--width: 48px; --svg-width: 26px; font-size: 24px;}
.size_xxl{	--width: 64px; --svg-width: 34px; font-size: 32px;}

/* Icon custom */
.icon_custom {
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
	font-size: var(--svg-width);
}
