/* Outer */
.outer_has_margin {
    margin-bottom: 24px;
}
.outer_has_margin:not(:first-child) {
    margin-top: 24px;
}
.outer_has_margin.outer_size_s {
    margin-bottom: 10px;
}
.outer_has_margin.outer_size_s:not(:first-child) {
    margin-top: 10px;
}
.outer_has_mask {
    pointer-events: none;
    user-select: none;
    opacity: .5;
}
.outer_no_margin {
    margin-top: 0;
    margin-bottom: 0;
}

/* Title */
.title {
    font-weight: 500;
    line-height: 1.2;
}
.title_size_xs {
	font-size: 15px;
    margin-bottom: 8px;
}
.title_size_s {
	font-size: 16px;
    margin-bottom: 8px;
}
.title_size_m {
	font-size: 17px;
	margin-bottom: 8px;
}

/* Prepend_message */
.prepend_message {
    margin-bottom: 8px;
}

/* Inner */
.inner {
    background: var(--white);
    border: solid 1px var(--main-border-color);
    border-radius: 6px;
    position: relative;
}
.inner_has_shadow {
    box-shadow: rgba(0,0,0,.04) 0 2px 4px;
}
.inner::before,
.inner::after {
	content: "";
	display: block;
}

.inner_no_padding {
	padding: 0;
}
.inner_no_padding::before,
.inner_no_padding::after {
    margin-top: 0;
}

.inner_size_xs {
	padding: 0 4px;
}
.inner_size_xs::before,
.inner_size_xs::after {
    margin-top: 4px;
}

.inner_size_s {
	padding: 0 12px;
}
.inner_size_s::before,
.inner_size_s::after {
    margin-top: 12px;
}

.inner_size_m {
	padding: 0 16px;
}
.inner_size_m::before,
.inner_size_m::after {
    margin-top: 16px;
}

.inner_size_l {
	padding: 0 22px;
}
.inner_size_l::before,
.inner_size_l::after {
    margin-top: 22px;
}

.inner_size_xl {
    padding: 0 28px;
}
.inner_size_xl::before,
.inner_size_xl::after {
    margin-top: 28px;
}

/* Mode full height */
.outer_full_height {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.outer_full_height .title {
    flex: 0 0 auto;
}
.outer_full_height .inner {
    flex: 1 1 auto;
    position: relative;
}
