.header {
    min-height: 54px;
    padding: 0 22px;
    background-color: var(--white);
    display: flex;
    align-items: center;
}
.header_left {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
}
.header_right {
    flex: 0 0 auto;
    margin-left: 20px;
    margin-right: -10px;
    display: flex;
    align-items: center;
}
.header_logo {
    flex: 0 0 auto;
    padding: 12px 8px;
    margin-left: -8px;
}
.header_logo_img {
    display: block;
}
.header_sep {
    flex: 0 0 auto;
    border-right: solid 1px var(--main-border-color);
    height: 17px;
    margin: 0 12px 0 14px;
}
.header_title {
    flex: 0 0 auto;
    padding: 5px 8px;
    font-size: 16px;
}
.header_link {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 5px 8px;
    border-radius: 6px;
    margin: 0 2px;
    transition: background 200ms;
}
.header_link_grey {
    composes: header_link;
    color: var(--grey-4);
}
.header_link:hover,
.header_link:focus {
    background-color: #F7F7FA;
}
.header_link_text {
    flex: 0 0 auto;
    font-size: 15px;
    color: var(--black);
}
.header_link_grey .header_link_text {
    color: inherit;
}
.header_link_icon {
    margin-right: 6px;
}
.header_link_arrow {
    width: 16px;
    height: 16px;
    margin-left: 1px;
    margin-right: -2px;
    opacity: .5;
    background-image: url('/Assets/common.svg');
}
.header_breadcrumb_arrow {
    font-size: 12px;
    color: var(--grey-5);
}
.header_breadcrumb_arrow i {
    display: block;
}
.header_avatar {
    margin-left: -6px;
    margin-right: 10px;
}
.header_notifications {
    margin-right: -4px;
}