
.formNodata {
	color: #b4b4b4;
	font-size: 15px;
	display: inline;
	margin-left: 10px;
}

.tableContainer {
    margin: 25px 0;
}
