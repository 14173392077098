.enabledFocus:focus {
	outline: none;
}

.designSection {
	margin-bottom: 40px;
}

.designSectionTitle {
	font-size: 16px;
	margin-bottom: 8px;
}

.designSectionDesc {
	color: #9E9E9E;
	font-size: 12px;
	margin-bottom: 8px;
}

.designSectionBody {
	margin-top: 14px;
}

/* Upload */

.uploadBanner {
	text-align: center;
	border: dashed 1px currentColor;
	border-radius: 4px;
	color: #9E9E9E;
	padding: 0 20px;
	position: relative;
}

.uploadBanner::after, .uploadBanner::before {
	content: "";
	display: block;
	margin-top: 25px;
}

.uploadBannerLine {
	margin: 12px 0;
}

.uploadBannerTxt {
	font-size: 16px;
}

.uploadBannerOr {
	font-size: 14px;
}

.uploadBannerBtn {
	margin-top: 14px;
}

.uploadBannerOverlay {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: dashed 1px #006fff;
	border-radius: 4px;
	padding: 0 20px;
	background: #fff;
	display: flex;
	align-items: center;
}

.uploadProgress {
	flex: 1 1 auto;
	text-align: center;
	color: #006fff;
	line-height: normal;
}

.uploadProgressPercent {
	font-weight: 700;
	font-size: 30px;
}

.uploadProgressPercentUnit {
	font-size: 14px;
	font-weight: normal;
}

.uploadProgressTitle {
	font-size: 18px;
}

.uploadProgressBar {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 6px;
}

.uploadProgressBarInner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 0;
	background: #006fff;
}

.designDeclination {
	margin: auto;
	text-align: center;
}

.designDeclination_cancel {
	margin-top: 16px;
}

.titleUploadSuccess {
	font-size: 32px;
	text-align: center;
	color: #006fff;
	font-weight: bold;
}

.timerUpload {
	text-align: center;
	color: #9E9E9E;
	font-weight: bold;
	font-size: 35px;
}

.messageDeclination {
	text-align: center;
	color: #293133;
	font-weight: 400;
	font-size: 21px;
}

.messageDeclinationSmall {
	text-align: center;
	color: #9E9E9E;
	font-weight: 400;
	font-size: 13px;
}

.btnCancelRequest {
	width: 140px;
	margin: auto;
	background: #ffffff;
	border: 1px solid #9e9e9e;
	margin-top: 20px;
	padding: 3px;
	border-radius: 5px;
	cursor: pointer;
}

.messageProcessingTransformations {
	position: relative;
	margin-bottom: 16px;
	background-color: #f6f7f8;
	padding: 16px 16px;
	border-radius: 6px;
	font-weight: 500;
	font-size: 16px;
	color: #3f3f3f;
	text-align: center;
}