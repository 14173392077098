.content {
    background: #FFFFFF;
    padding: 23px 30px;
    font-size: 13px;
}
.title {
    font-size: 20px;
}
.description {
    color: #ACACAC;
    margin-bottom: 15px;
}
.profilesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.profilesListItem {
    margin-bottom: 20px;
    background: #f9f9f9;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    width: 32%;
}
.profilesListItem:hover > div > div:last-child {
    bottom: -31px;
}
.newProfileButton {
    width: 32%;
    min-height: 200px;
    border: 1px dashed #C0C0C0;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #C0C0C0;
    transition: all .3s ease-in-out;
}
.newProfileButton:hover {
    border: 1px dashed #006fff;
    color: #006fff;
}
