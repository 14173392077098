.dropdown_outer {
    position: absolute;
    z-index: 10201;
    top: 0;
    left: 0;
    background: #fff;
    box-shadow: rgba(0, 0, 0, .3) 0 1px 10px, rgba(0, 0, 0, .06) 0 2px 16px 1px, rgba(0, 0, 0, .06) 0 0 0 1px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 100ms;
    outline: none;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
}
.dropdown_outer.is_visible {
    opacity: 1;
    visibility: visible;
    -webkit-animation: dropdown 100ms ease-out;
    animation: dropdown 100ms ease-out;
}
.dropdown_outer.has_scroll{
    overflow: auto;
    max-height: calc(50vh - 40px);
}

#campaign_card_index{
    z-index: 100;
}
.dropdown_outer.is_hiden{
    visibility: hidden;
    /* opacity: 1;
    z-index: 1; */
}

.dropdown_inner {
    padding: 8px 0;
}

.dropdown_inner .sketch-picker {
    border: none;
    margin: -8px 0;
    background: none;
}

.dropdown_inner .react-colorful {
    margin: 2px 10px;
}

.dropdown_header{
    border-bottom: solid 1px var(--main-border-color);
    padding: .5em 2em .5em 1em;
}
.dropdown_header_desc{
    display: block;
    font-size: 13px;
    color: #5d637e;
}
.dropdown_section{
    padding: .5em 1em .5em 1em;
}
.dropdown_footer{
	border-top: solid 1px var(--main-border-color);
    padding: 1em 1em .5em 1em;
}

.dropdown_column:not(:first-child){
    margin-top: -8px;
    margin-bottom: -8px;
    border-left: solid 1px var(--main-border-color);
}

.dropdown_hover_sidebar {
    position: relative;
    margin-top: -8px;
    margin-bottom: -8px;
    border-left: solid 1px var(--main-border-color);
    background-color: var(--main-bg-color);
    width: 400px;
    height: calc(50vh - 40px);
    padding: 16px;
    border-radius: 0 5px 5px 0;
    box-sizing: content-box;
}

.dropdown_close{
    position: absolute;
    right: 3px;
    top: 6px;
    border-radius: 0 !important;
}

.dropdown_max_height{
    height: auto !important;
    max-height: calc(50vh - 40px);
}

@-webkit-keyframes dropdown {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dropdown {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}