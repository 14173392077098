.productSettingContainer .productSettingsElement {
    margin-top: 22px;
    margin-bottom: 62px;
}

.table_grid_emerch .table_row{
    color: var(--grey-2);
    font-size: 14px;
}
.table_grid_emerch .table_row_name{
    font-weight: 500;
    font-size: 15px;
    color: var(--black);
    line-height: 1.2;
}
.table_grid_emerch .table_row_desc{
    font-size: 13px;
    color: var(--grey-2);
    line-height: 1.2;
    margin-top: 3px;
}

.table_grid_ranking .table_row{
    grid-template-columns: 6fr 2fr 70px;
}

.table_grid_reco .table_row{
    grid-template-columns: 6fr 2.5fr 2.5fr 2.5fr 70px;
}

.table_grid_reco_with_tenant .table_row{
    grid-template-columns: 6fr 2.5fr 2.5fr 2.5fr 2.5fr 70px;
}

.emerch_context_tag_wrapper {
    margin-top: 2px;
}
.emerch_context_tag {
    padding: 1px 4px;
    font-size: 11px;
    color: var(--grey-2);
    background-color: #E5E6ED;
    border-radius: 3px;
    display: inline-block;
    vertical-align: top;
}


.emerch_avatar{
    display: block;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    background: #EDF4FD;
    color: #4D98FA;
    box-shadow: #fff 0 0 0 1px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
}
.emerch_avatar_margin {
    margin-right: 16px;
}
.table_grid_asso .table_row{
    grid-template-columns: 1fr 1.6fr 1.6fr;
}


.emerch_asso_thumb{
    display: inline-block;
    margin: 5px;
    font-size: 12px;
    text-align: center;
    background: #fff;
    padding-bottom: 2px;
    border-radius: 4px;
    border: solid 1px var(--main-border-color);
    overflow: hidden;
    transition: transform 200ms;
}
.emerch_asso_thumb:hover{
    transform: scale(1.5);
}
