.addBtn {
    border: 2px dashed;
    color: #C0C0C0;
    font-weight: bold;
    text-align: center;
    padding: 15px;
    width: 100%;
    transition: all .2s ease-in-out;
}

.addBtn:hover,
.addBtn:focus {
    color: #006fff;
}

.addBtn:disabled{
    opacity: .3;
    cursor: not-allowed;
    background: none;
}

.addBtn:disabled:hover{
    background: none;
    color: #C0C0C0;
}

.addBtn i {
    margin: 0 10px 0 0;
}

.sectionTitle {
    display: flex;
    align-items: flex-end;
    margin: 0 0 20px;
    font-weight: bold;
    font-size: 15px;
}

.additionalSection {
    margin-top: 40px;
}

.additionalSection>.sectionTitle:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid;
    margin: 0 0 0 10px;
}