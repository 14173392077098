.form-creation-tenant {
    height: 500px;
    width: 80%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
}

.btn-container-creation-tenant {
    width: 30%;
    display: flex;
    margin: 30px auto;
}