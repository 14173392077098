.edit_source_product_feed_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 75px;
    margin: 0 auto;
    border-bottom: 2px solid #EBEBEB;
    border-radius: 2px;
}

.edit_source_product_feed_menu {
    display: flex;
    width: 90px;
    font-size: 18px;
    justify-content: space-between;
    color: #9E9E9E;
}

.edit_source_product_feed_menu i:hover {
    color: #006fff;
    cursor: pointer;
}