.picto {
	width: 42px;
	height: 42px;
	/*background-image: url('/Assets/feed_picto.svg');*/
	background-image: url('/Assets/feed_picto.png');
	background-size: 44px 308px;
}

.picto.picto_generic {
	background-position: -1px -1px;
}
.picto.picto_prestashop {
	background-position: -1px -45px;
}
.picto.picto_shopify {
	background-position: -1px -89px;
}
.picto.picto_onsite {
	background-position: -1px -133px;
}
.picto.picto_custom {
	background-position: -1px -177px;
}
.picto.picto_mazeberry {
	background-position: -1px -221px;
}
.picto.picto_http {
	background-position: -1px -265px;
}
.picto.picto_none {
	background-image: none;
}