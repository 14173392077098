.image_wrapper {
	
}
.image {
	display: block;
	max-width: 300px;
	height: auto;

	background-color: #CACCD8;
    background-image: linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%), linear-gradient(45deg, #E9EAF0 25%, transparent 25%, transparent 75%, #E9EAF0 75%);
    background-size: 16px 16px;
    background-position: 0 0, 8px 8px;
}

.dropzone_wrapper {
	
}
